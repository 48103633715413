import React, { useEffect, useState } from 'react';
import add from '../../../images/svg/add.svg';
import GroupingCard from './GroupingCard';
import api from '../../../services/api';
import { toast } from 'react-toastify';

const GroupingContainer = ({setGroupingModal, setListStructures, groupingModal}) => {

  const [items, setItems] = useState([]);
  const [newItems, setNewItems] = useState([]);

  /* CONTROLLING RENDERED OPTIONS */
  const [allProcess, setAllProcess] = useState([
    'Armazenagem',
    'Inventário',
  ]);

  const [allGroups, setAllGroups] = useState([
    'Blocados',
    'Camara fria',
    'Drive-in',
    'Drive-thru',
    'Porta paletes',
    'Prateleiras',
    'Silos',
    'Veiculo',
  ]);

  const getAllGroups = async () => {
    try {
      const cnpj = localStorage.getItem('@XPM_CLIENT_COMPANY:');
      const response = await api.get(`/grouping-structures/all/${cnpj}`);
      if (response.data && response.data.length > 0) {
        setItems(response.data);
      };
    } catch (error) {
      toast.error("Não foi possível carregar os grupos", {
        autoClose: 2000,
      });
      console.log(error);
    }
  };

  const postGroup = async () => {
    try {
      const cnpj = localStorage.getItem('@XPM_CLIENT_COMPANY:');
      const response = await api.post('/grouping-structures', {process: '', name: '', client_cnpj: cnpj});
      return response.data;
    } catch (error) {
      toast.error("Desculpe, não foi possível criar um novo grupo", {
        autoClose: 2000,
      });
      console.log(error);
    }
  };

  const updateGroup = async (group) => {
    try {
      const { id, process, name, client_cnpj } = group;
      await api.patch(`/grouping-structures/${id}`, {process, name, client_cnpj});
    } catch (error) {
      toast.error("Não foi possível atualizar o grupo, verifique os dados", { autoClose: 2000 });
    }
  };

  const createNewGroup = async () => {
    const newArray = [...items];
    const response = await postGroup();
    newArray.push({...response});
    setItems(newArray);
  };

  const deleteGroup = async (id) => {
    try {
      console.log('deleting group ' + id)
      const response = await api.delete(`/grouping-structures/${id}`)
      if (response) {
        toast.success("Grupo deletado com sucesso!", { autoClose: 2000 });
      };
    } catch (error) {
      toast.error("Não foi possível deletar o grupo!", {
        autoClose: 2000,
      });
    }
  };

  const handleDelete = (id) => {
    const newArray = items.filter((e, i) => e.id !== id);
    setItems(newArray);
    deleteGroup(id);
  };

  const handleChange = ({target}, index) => {
    const {name, value} = target;

    const newArray = [...items];
    if (newArray[index][name] !== value) {
      newArray[index][name] = value;
      setItems(newArray);
      updateGroup(newArray[index]);
    };
  };

  useEffect(() => {
    getAllGroups();
  }, []);

  return (
    <main style={{display: 'flex', flexDirection: 'column', gap: 20}}>
      {
        items && items.length > 0 ? (
          items.map((e, i) => (
            <GroupingCard
              key={`${e.process}_${e.name}_${i}`}
              index={i}
              handleDelete={handleDelete}
              handleChange={handleChange}
              item={e}
              setGroupingModal={setGroupingModal}
              groupingModal={groupingModal}
              setListStructures={setListStructures}
              allItems={[...items, ...newItems]}
              allGroups={allGroups}
              setAllGroups={setAllGroups}
            />
          ))
        ) : null
      }
      {
        !items.length > 0 ? (<p>Nenhum grupo cadastrado para esse cliente</p>) : null
      }
      <div
        style={{display: 'flex', gap: 10, color: '#9966CC', cursor: 'pointer', alignSelf: 'flex-start', marginTop: 15}}
        onClick={createNewGroup}
      >
        <img src={add} alt="adicionar checklist" />
        <p>Adicionar outro parâmetro</p>
      </div>
    </main>
  )
};

export default GroupingContainer;
