import React, { useContext } from "react";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";

const BoxDayScheduling = () => {
    const { scheduledTimes, setSelectedSchedules } =
        useContext(SchedulesContext);

    const stylesContainer = {
        borderRadius: "10px",
        border: "1px solid #D7D7D7",
        display: "flex",
        flexDirection: "column",
        alingItems: "center",
        textAlign: "center",
        height: 600,
        width: "30%",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: "#fff",
        marginTop: 20,
    };

    const stylesScheduleContainer = {
        color: "#96C",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
    };

    return (
        <div style={stylesContainer}>
            <p
                className="textTitleDayScheduling"
                style={{
                    borderBottom: "1px solid #D7D7D7",
                    marginBottom: 10,
                    paddingBottom: 10,
                }}
            >
                Horários de agendamento
            </p>

            <div style={stylesScheduleContainer} className="scrollLower">
                {scheduledTimes.length > 0 &&
                    scheduledTimes.map((item) => {
                        const time = item.time;
                        const schedules = item.schedules;

                        return (
                            <div
                                key={time}
                                style={{
                                    display: "flex",
                                    gap: 30,
                                    alingItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    setSelectedSchedules(item.schedules)
                                }
                            >
                                <p>
                                    {time.substr(0, 2) + ":" + time.substr(2)} -{" "}
                                    {schedules.length === 1
                                        ? `${schedules.length} agendamento`
                                        : `${schedules.length} agendamentos`}
                                </p>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default BoxDayScheduling;
