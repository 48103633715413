import { useNavigate } from "react-router-dom";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import HeaderDashboard from "../components/HeaderDashboard";
import Sidebar from "../../../components/Sidebar";
import ContainerInputSearchAndButton from "../components/ContainerInputSearchAndButton";
import CardDetails from "../components/CardDetails";
import CardDetailsGeneralitiesTitle from "../components/CardDetailsGeneralitiesTitle";
import { useContext, useEffect, useState } from "react";
import { DashContext } from "../../../context/Dashboard/DashContext";
import CardDetailsGeneralitiesContent from "../components/CardDetailsGeneralitiesContent";
import Loading from "../components/Loading";
import createExcelFile from "../../../utils/craeteExcelFile";
import { toast } from "react-toastify";
import api from "../../../services/api";

const DetailsGeneralities = () => {
  const { getDetailsGeneralities, listDetailsGeneralities, setListDetailsGeneralities } = useContext(DashContext);
  
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
  const month = localStorage.getItem("@XPM_CLIENT_MONTH:");

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    marginBottom: "50px",
  };

  const boxCard = {
    display: "flex",
    width: "100%",
    gap: "16px",
    flexDirection: "column",
    marginTop: "16px",
  };

  const deleteGeneralties = async (id) => {
    try {
      const response = await api.delete(`/generalities/${id}`);

      if (response.status === 200) {
        const newList = listDetailsGeneralities.filter((e) => e.id !== id)
        setListDetailsGeneralities(newList);
        toast.success("Generalidade deletada com sucesso", {autoClose: 2000});
      }
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  useEffect(() => {
    getDetailsGeneralities();
  }, []);

  useEffect(() => {
    if (listDetailsGeneralities && listDetailsGeneralities.length > 1) {
      setLoading(false);
    }
  }, [listDetailsGeneralities]);

  return (
    <ThowColumLayout
      page={"DashboardAdm"}
      colum1={<Sidebar page="Dashboard" />}
      colum2={
        <div style={container}>
          <HeaderDashboard
            back={true}
            name="Fatura generalidades"
            adm={true}
            onClick={() => navigate("/dashboard/company")}
          />
          <div style={{ margin: "42px auto 0" }} className="containerBox">
            <ContainerInputSearchAndButton onClick={() => createExcelFile(listDetailsGeneralities.filter((e) => e.id), `Generalidades ${year}-${month}`, `Generalidades ${year}-${month}.xlsx`)} />

            <div
              style={{
                display: "flex",
                marginTop: "33px",
                marginBottom: "170px",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <CardDetailsGeneralitiesTitle
                marginLeft="140px"
                paddingLeft={"150px"}
                content={listDetailsGeneralities[0]?.description}
                quantity={listDetailsGeneralities[0]?.quantity}
                UOM={listDetailsGeneralities[0]?.unit}
                priceUnit={listDetailsGeneralities[0]?.price}
                total={listDetailsGeneralities[0]?.total}
              />
            </div>
            {
              loading ? (
                <Loading />
              ) : (
                <div style={boxCard}>
                  {listDetailsGeneralities?.map((item, i) => {
                    if (i > 0) {
                      return <CardDetailsGeneralitiesContent item={item} deleteItem={deleteGeneralties} />;
                    }
                  })}
                </div>
              )
            }
          </div>
        </div>
      }
    />
  );
};

export default DetailsGeneralities;
