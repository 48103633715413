import { useContext, useEffect, useState } from "react";
import ClientsSelect from "./ClientsSelect";
import CardContractualLine from "./CardContractualLine";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";
import Hollidays from "./Hollidays";
import ModalContainer from "./ModalContainer";
import ModalHolidays from "./ModalHolidays";

const CardsContractualParameters = () => {
  const {
    parameters,
    getParameters,
    setParameters,
    deleteParameters,
    update,
    setUpdate,
  } = useContext(GraphsAndParametersContext);
  const [parameterArry, setParameterArry] = useState([1]);
  const [isActive, setIsActive] = useState(false);

  const deleteLine = (index, id) => {
    if (id) {
      deleteParameters(id);
    } else {
      const filter = parameters.filter((item, i) => i !== index);
      setParameters(filter);
    }
  };

  const addLine = () => {
    const data = {
      process: "default",
      description: "",
      time_type: "",
      time: "",
      metric_type: "",
      metrics: "",
      constancy: "",
      month_and_year: null,
    };
    setParameters([...parameters, data]);
  };

  useEffect(() => {
    getParameters();
  }, [update]);

  return (
    <>
      <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
        <ClientsSelect limit={false} update={() => setUpdate(!update)} />
        <Hollidays openHollidays={() => setIsActive(true)} />
      </div>
      <div
        className="animate__animated animate__fadeIn"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          position: "relative",
          backgroundColor: "#fff",
          marginTop: "35px",
          border: "1px solid #d7d7d7",
          borderRadius: "10px",
          padding: "23px",
        }}
      >
        {parameters.map((item, i) => {
          return (
            <CardContractualLine
              data={item}
              i={i}
              destroy={() => deleteLine(i, item?.id)}
            />
          );
        })}
        <div
          style={{
            marginTop: "20px",
            color: "#9966CC",
            cursor: "pointer",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <p onClick={addLine}>+ Adicionar outro parâmetro contratual</p>
        </div>
      </div>

      <ModalContainer isActive={isActive} closeModal={() => setIsActive(false)}>
        <ModalHolidays closeModal={() => setIsActive(false)} />
      </ModalContainer>
    </>
  );
};

export default CardsContractualParameters;
