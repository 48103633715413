import React, { useRef, useState } from 'react';
import arrowUpIcon from "../../../images/svg/arrowCircleUpAction.svg";
import arrowDownIcon from "../../../images/svg/arrowCircleDownAction.svg";
import uploadIcon from "../../../images/svg/upload.svg";

const AttachmentBoxForModal = ({ children }) => {
    const [isArrowUp, setIsArrowUp] = useState(true);

    const fileInputRef = useRef(null);

    const toggleArrowIcon = () => {
        setIsArrowUp((prevValue) => !prevValue);
    };

    const handleFileInputChange = (e) => {
        const files = e.target.files;
        console.log(files);
    };

    const handleUploadBoxClick = () => {
        fileInputRef.current.click();
    };

    const container = {
        maxWidth: "100%",
        borderBottom: "1px solid var(--grey-0)",
        backgroundColor: "var(--white)",
        padding: "20px 0 0 0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    };

    const styleTitle = {
        margin: "0",
        fontSize: "17px",
        color: "var(--black)",
        fontWeight: 500,
    };

    const styleText = {
        margin: "0",
        fontSize: "13px",
        color: "var(--black)",
        fontWeight: 500,
    };

    const styleUploadBox = {
        border: "3px dotted var(--grey-0)",
        borderRadius: "60px",
        width: "143px",
        height: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        cursor: "pointer"
    };

    return (
        <div style={container}>
            <div style={{ overflowX: "auto", paddingBottom: "20px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }} onClick={toggleArrowIcon}>
                    <img src={isArrowUp ? arrowUpIcon : arrowDownIcon} alt="Arrow Icon" />
                    <h3 style={styleTitle}>Anexos</h3>
                </div>
                {!isArrowUp && (
                    <div style={{ display: "flex", alignItems: "baseline", gap: "20px" }}>
                        <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                            <div onClick={handleUploadBoxClick} style={styleUploadBox}>
                                <img src={uploadIcon} alt="Upload" />
                                <p style={styleText}>Incluir anexos</p>
                            </div>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileInputChange}
                            />
                        </div>
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AttachmentBoxForModal;
