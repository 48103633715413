import React, { useState } from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../../../components/Sidebar";
import HeaderManager from "../components/HeaderManager";
import SelectSessionButtons from "../components/SelectSessionButtons";
import ContainerInputSearchAndButton from "../components/ContainerInputSearchAndButton";
import TableList from "../components/TableList";
import ModalContainer from "../components/ModalContainer";

const InitialDashboard = () => {
  const [btnSelect, setBtnSelect] = useState("Acessos admin");
  const [searchingUser, setSearchingUser] = useState("");

  const [nameButtom, setNameButtom] = useState("Novo usuário");
  const [colorButtom, setColorButtom] = useState("var(--color-primary)");

  const [listCardSelected, setListCardSelected] = useState([]);

  const [modalFormAdmin, setModalFormAdmin] = useState(false);
  const [optionModalFormAdmin, setOptionModalFormAdmin] = useState("create");
  const [modalFormClient, setModalFormClient] = useState(false);
  const [optionModalFormClient, setOptionModalFormClient] = useState("create");

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    paddingBottom: "50px",
  };

  const containerSearch = {
    display: "flex",
    alignItems: "center",
    gap: "26px",
    marginTop: "45px",
  };

  return (
    <ThowColumLayout
      page={"Company"}
      colum1={<Sidebar page="Management" />}
      colum2={
        <div style={container}>
          <HeaderManager name="Faturamento" adm={true} />
          <div style={{ margin: "0 auto" }} className="containerBox">
            <div style={containerSearch}>
              <SelectSessionButtons
                setBtnSelect={setBtnSelect}
                btnSelect={btnSelect}
              />
              <ContainerInputSearchAndButton
                value={searchingUser}
                onChange={setSearchingUser}
                nameButton={nameButtom}
                colorButtom={colorButtom}
                listCardSelected={listCardSelected}
                setModalFormAdmin={setModalFormAdmin}
                setOptionModalFormAdmin={setOptionModalFormAdmin}
                setModalFormClient={setModalFormClient}
                setOptionModalFormClient={setOptionModalFormClient}
              />
            </div>
            <TableList
              filterOption={btnSelect}
              searchingUser={searchingUser}
              setNameButtom={setNameButtom}
              setColorButtom={setColorButtom}
              listCardSelected={listCardSelected}
              setListCardSelected={setListCardSelected}
              modalFormAdmin={modalFormAdmin}
              setModalFormAdmin={setModalFormAdmin}
              modalFormClient={modalFormClient}
              setModalFormClient={setModalFormClient}
              optionModalFormAdmin={optionModalFormAdmin}
              setOptionModalFormAdmin={setOptionModalFormAdmin}
              optionModalFormClient={optionModalFormClient}
              setOptionModalFormClient={setOptionModalFormClient}
            />
          </div>
        </div>
      }
    ></ThowColumLayout>
  );
};

export default InitialDashboard;
