import React from 'react'

const FiltroDropDown = ({filteredItem, itemsArray, onClick, placeholder}) => {

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px",
    width: "280px",
    background: "white",
    border: "1px solid #d8d8d8",
    borderRadius: "60px",
    paddingLeft: "20px",
    height: "40px",
    position: "relative",
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div class="dropdown">
        <button
          style={containerStyle}
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div style={{ display: "flex", gap: "20px" }}>
            <p className="textClientInfoHeader">
              {
                filteredItem ? filteredItem : (placeholder || "Selecione")
              }
              </p>
          </div>

          <div style={{ transform: "translateX(-10px)" }}>
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.182668 1.78582C0.0688405 1.57763 0.0688405 1.32582 0.182668 1.11763C0.446809 0.634515 1.14065 0.634515 1.40479 1.11763L2.41937 2.97328C3.12207 4.25852 4.96793 4.25852 5.67064 2.97327L6.68521 1.11763C6.94935 0.634515 7.64319 0.634515 7.90733 1.11763C8.02116 1.32582 8.02116 1.57763 7.90733 1.78582L6.67725 4.03563C5.53942 6.11672 2.55058 6.11672 1.41275 4.03563L0.182668 1.78582Z"
                fill="black"
              />
            </svg>
          </div>
        </button>

        <div
          class="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
          style={{
            width: "280px",
            height: "250px",
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          <div
            key={"Todas as unidades"}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              className="dropdown-item textSelectOptions"
              onClick={() => {
                onClick("");
              }}
            >
              {placeholder || "Selecione"}
            </p>
          </div>
          {
            itemsArray.map((item) => {
              return (
                <div
                  key={item}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="dropdown-item textSelectOptions"
                    onClick={() => {
                      onClick(item);
                    }}
                  >
                    {item?.trim("")}
                  </p>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  )
}

export default FiltroDropDown