import { useContext, useEffect, useState } from "react";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";
import CardContractualParameter from "./CardContractualParameter";
import ContainerAveragingMonth from "./ContainerAveragingMonth";
import moment, { months } from "moment";
import CardYear from "./CardYear";
import InventoryAccuracy from "./InventoryAccuracy";
import InventoryConsolidation from "./InventoryConsolidation";
import CommentYearDashboard from "./CommentYearDashboard";
import InventoryConciliation from "./InventoryConciliation";

const ContainerContractualParameter = () => {
  const { getKPIGeneral, generalKpis, loading, update, getKPIGeneralYear } =
    useContext(GraphsAndParametersContext);
  const [dateMoment, setDateMoment] = useState(moment().format("YYYY"));

  useEffect(() => {
    getKPIGeneral(dateMoment);
    getKPIGeneralYear();
  }, [dateMoment, update]);

  return (
    <div>
      <div style={{ marginTop: "30px" }}>
        <CardYear dateMoment={dateMoment} setDateMoment={setDateMoment} />
      </div>
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "20px",
        }}
      >
        {!loading ? (
          generalKpis?.length > 0 ? (
            generalKpis.map((elem) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "30px",
                    width: "100%",
                  }}
                >
                  <ContainerAveragingMonth
                    listMonths={elem.months.map((month) => month.averageOnTime)}
                    title={`${elem.parametrs.description}`}
                    metrics={Number(elem.parametrs.metrics)}
                  />
                  <CardContractualParameter
                    max={100}
                    title={`${elem.parametrs.process}: ${elem.parametrs.description}`}
                    nameOne={"No Prazo"}
                    nameTwo={"Fora do Prazo"}
                    metrics={elem.parametrs.metrics}
                    listMonths={elem.months.map((month) => month.averageOnTime)}
                    listMonthsTwo={elem.months.map(
                      (month) => month.averageOffTime
                    )}
                  />
                </div>
              );
            })
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        {!loading ? <CommentYearDashboard /> : <></>}

        {!loading ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <InventoryAccuracy update={dateMoment} />
              <InventoryConsolidation update={dateMoment} />
            </div>
            <InventoryConciliation update={dateMoment} />
          </>
        ) : (
          <></>
        )}

        {/* <CardContractualParameter
          max={100}
          title={"Entrada"}
          nameOne={"No Prazo"}
          nameTwo={"Fora do Prazo"}
          listMonths={[100, 98, 105, 85, 90, 95, 60, 98, 85, 77, 100, 15]}
          listMonthsTwo={[35, 92, 67, 85, 72, 100, 85, 95, 77, 90, 82, 100]}
        /> */}
        {/* <CardContractualParameter
          max={100}
          nameOne={"No Prazo"}
          nameTwo={"Fora do Prazo"}
          title={"Saída"}
          listMonths={[80, 73, 105, 10, 40, 98, 70, 73, 88, 77, 85, 95]}
          listMonthsTwo={[35, 92, 67, 85, 72, 100, 85, 95, 77, 90, 82, 100]}
        />
        <CardContractualParameter
          max={100}
          title={"IQS - Indice Qualidade do Serviço"}
          nameOne={"% de eficiência"}
          nameTwo={"% de desvio"}
          listMonths={[83, 65, 78, 92, 100, 72, 45, 60, 85, 97, 88, 100]}
          listMonthsTwo={[75, 92, 67, 85, 72, 100, 85, 95, 77, 90, 82, 100]}
        />
        <CardContractualParameter
          max={100}
          title={"Urgência"}
          nameOne={"No Prazo"}
          nameTwo={"Fora do Prazo"}
          listMonths={[95, 82, 70, 55, 68, 79, 88, 90, 75, 67, 91, 100]}
          listMonthsTwo={[75, 92, 67, 85, 72, 100, 85, 95, 77, 90, 82, 100]}
        />

        <CardContractualParameter
          max={100}
          title={"Acuracidade de Inventário"}
          nameOne={"% de eficiência"}
          nameTwo={"% de desvio"}
          listMonths={[75, 92, 67, 85, 72, 100, 85, 95, 77, 90, 82, 100]}
          listMonthsTwo={[75, 92, 67, 85, 72, 100, 85, 95, 77, 90, 82, 100]}
        />
        <CardContractualParameter
          max={100}
          title={"Análise de Divengências"}
          nameOne={"Desvios"}
          listMonths={[90, 80, 75, 60, 72, 78, 85, 90, 70, 65, 92, 100]}
        />
        <CardContractualParameter
          max={100}
          title={"Consolidação de Produtos"}
          nameOne={"Sku's consolidados"}
          nameTwo={"Sku's pendentes"}
          listMonths={[55, 88, 60, 92, 100, 65, 70, 80, 95, 85, 75, 100]}
          listMonthsTwo={[75, 92, 67, 85, 72, 100, 85, 95, 77, 90, 82, 100]}
        />
        <CardContractualParameter
          max={100}
          title={"Conciliação de Estoque (WMS x ERP)"}
          nameOne={"% de eficiência"}
          nameTwo={"% de desvio"}
          listMonths={[100, 68, 91, 75, 88, 70, 92, 80, 90, 100, 60, 100]}
          listMonthsTwo={[75, 92, 67, 85, 72, 100, 85, 95, 77, 90, 82, 10]}
        /> */}
      </div>
    </div>
  );
};

export default ContainerContractualParameter;
