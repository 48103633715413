import { useEffect, useState } from "react";
import Button from "./Button";
import closeIcon from "../../../images/svg/closeIcon.svg";
import add from '../../../images/svg/add.svg';
import TaxCard from "./TaxCard";

const ModalTaxes = ({ closeModal, onOpen, itemsArray, setItemsArray, deleteItem, createItem, updateItem }) => {
  const [updatedItems, setUpdatedItems] = useState([]);
  const [newItems, setNewItems] = useState([]);

  /* STYLES */
  const container = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    width: "690px",
    boxShadow: "0px 0px 13px -3px #00000033",
    border: "1px solid transparent",
    borderRadius: "20px",
  };

  const headerOption = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #D7D7D7",
    padding: "15px 18px 18px",
  };

  const content = {
    width: "100%",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    overflowY: "scroll",
    paddingRight: 10,
    maxHeight: 400,
  };

  const boxTextDelete = {
    display: "flex",
    alignItems: "center",
    padding: "18px",
    flexDirection: "column",
  };

  const handleNewItem = () => {
    const newArray = [...newItems];
    newArray.push({ name: '', porcentage: '' });
    setNewItems(newArray);
  };

  const handleDeleteItem = async (id, index) => {
    if (!id) {
      const newArray = [...newItems];
      const filtered = newArray.filter((e, i) => i !== index);
      setNewItems(filtered);
    } else {
      const newArray = [...itemsArray];
      const filtered = newArray.filter((e) => e.id !== id);
      setItemsArray(filtered);
      await deleteItem(id);
    }
  };

  const handleChange = ({target}, id, index) => {
    const {name, value} = target;

    if (!id) {
      let newArray = [...newItems];
      switch (name) {
        case 'name':
          newArray[index].name = value;
          setNewItems(newArray);
          break;
        case 'porcentage':
          newArray = [...newItems];
          newArray[index].porcentage = value;
          setNewItems(newArray);
          break;
        default:
          break;
      }
    } else {
      let newArray = [...itemsArray];
      const updatedId = newArray[index].id;
      const alreadyUpdated = updatedItems.findIndex(e => e.id === updatedId);
      switch (name) {
        case 'name':
          newArray[index].name = value;
          setItemsArray(newArray)
          if (alreadyUpdated >= 0) {
            const newUpdatedArray = [...updatedItems];
            newUpdatedArray[alreadyUpdated].name = value;
            setUpdatedItems(newUpdatedArray);
          } else {
            const newUpdatedArray = [...updatedItems] || [];
            newUpdatedArray.push(newArray[index])
            setUpdatedItems(newUpdatedArray);
          };
          break;
        case 'porcentage':
          newArray = [...itemsArray];
          newArray[index].porcentage = value;
          setItemsArray(newArray);
          if (alreadyUpdated >= 0) {
            const newUpdatedArray = [...updatedItems];
            newUpdatedArray[alreadyUpdated].porcentage = value;
            setUpdatedItems(newUpdatedArray);
          } else {
            const newUpdatedArray = [...updatedItems] || [];
            newUpdatedArray.push(newArray[index])
            setUpdatedItems(newUpdatedArray);
          };
          break;
        default:
          break;
      }      
    }
  };

  const handleSave = async () => {
    await Promise.all(newItems.map(async (newItem) => await createItem(newItem)));

    await Promise.all(updatedItems.map(async (updatedItem) => await updateItem(updatedItem)));
    await onOpen();
    closeModal();
  };

  useEffect(() => {
    onOpen();
  }, [])

  return (
    <div className="modalEdit" style={container}>
      <div style={headerOption}>
        <div
          style={{
            display: "flex",
            gap: "27px",
            alignItems: "center",
          }}
        >
          <div
            onClick={closeModal}
            style={{
              cursor: "pointer",
            }}
          >
            <img src={closeIcon} />
          </div>
          <p className="textModalTitleOption">Imposto mensal</p>
        </div>
        <Button
          width="100px"
          height="33px"
          background="var(--color-primary)"
          borderRadius="60px"
          marginTop="0"
          name="Salvar"
          onClick={handleSave}
          border="none"
        />
      </div>
      <div style={boxTextDelete}>
        <div style={content} className="scrollLower">
          <h3 className="textTitleInfo">Composição do imposto</h3>          
          {
            itemsArray.map((item, index) => (
              <TaxCard
                key={item.id}
                item={item}
                index={index}
                deleteItem={handleDeleteItem}
                onChange={handleChange}
              />
            ))
          }
          {
            newItems.map((item, index) => (
              <TaxCard
                key={`new-${index}`}
                item={item}
                index={index}
                deleteItem={handleDeleteItem}
                onChange={handleChange}
              />
            ))
          }
        </div>
          <div
            style={{display: 'flex', gap: 10, color: '#9966CC', cursor: 'pointer', alignSelf: 'flex-start', marginTop: 15}}
            onClick={handleNewItem}
          >
            <img src={add} alt="adicionar imposto" />
            <p>Adicionar outro imposto</p>
          </div>
        </div>
    </div>
  );
};

export default ModalTaxes;
