import React from 'react';


const InputCounter = ({number, width, height, color, background, borderRadius, border}) => {

  const stylesBtn = {
    width,
    height,
    color,
    background,
    borderRadius,
    border,
  };

  return (
    <button style={stylesBtn}>{number}</button>
  )
}

export default InputCounter;
