import { useContext, useEffect } from "react";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";
import icon from "../../../images/svg/shedulesHeaderIcon.svg";
import filterIcon from "../../../images/svg/filter-icon.svg";
import { Link } from "react-router-dom";

const SearchBranch = ({ setOpenFilter }) => {
    const {
        getBrachs,
        branchs,
        selectedBranch,
        setSelectedBranch,
        locationsDetails,
        setSelectedLocation,
        selectedLocation,
    } = useContext(SchedulesContext);

    useEffect(() => {
        getBrachs();
    }, []);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    backgroundColor: "#fff",
                    border: "1px solid #d7d7d7",
                    borderRadius: "60px",
                    width: "241px",
                    padding: "10px 17px",
                }}
            >
                <p className="titleSelectShedules">Filial:</p>
                <select
                    style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                    }}
                    className="textOptionsSchedules"
                    onChange={(e) => setSelectedBranch(e.target.value)}
                    value={selectedBranch}
                >
                    {branchs?.length > 0 ? (
                        <>
                            <option key={"default"} value={"default"}>
                                Selecione
                            </option>
                            {branchs.map((e) => (
                                <option key={e.id} value={e.id}>
                                    {e.branch_name}
                                </option>
                            ))}
                        </>
                    ) : (
                        []
                    )}
                </select>
            </div>

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    backgroundColor: "#fff",
                    border: "1px solid #d7d7d7",
                    borderRadius: "60px",
                    width: "241px",
                    padding: "10px 17px",
                }}
            >
                <p className="titleSelectShedules">Local:</p>
                <select
                    style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                    }}
                    className="textOptionsSchedules"
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    value={selectedLocation}
                >
                    {locationsDetails?.length > 0 ? (
                        <>
                            <option key={"default"} value={undefined}>
                                Selecione
                            </option>

                            {locationsDetails.map((e) => (
                                <option key={e.id} value={e.local.id}>
                                    {e.local.local_name}
                                </option>
                            ))}
                        </>
                    ) : (
                        <option key={"default"} value={"default"}>
                            Não há
                        </option>
                    )}
                </select>
            </div>

            <Link
                to={{
                    pathname: `/dashboard/scheduling/details`,
                }}
            >
                <img src={icon} />
            </Link>

            <img
                src={filterIcon}
                style={{
                    cursor: "pointer",
                }}
                onClick={() => setOpenFilter(true)}
            />
        </div>
    );
};

export default SearchBranch;
