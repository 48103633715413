import { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CardComments from "./CardComments";
import DeleteIcon from "../../../images/svg/deleteIcon.svg";
import { toast } from "react-toastify";

const CardDetailsGeneralitiesContent = ({ item, deleteItem }) => {

  const [commentsVisible, setCommentsVisible] = useState(false);

  /* STYLES */
  const content2 = {
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    padding: "17px 23px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    width: "100%",
    position: "relative",
    justifyContent: "space-between",
  };

  const contentValueCard = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
  };

  const transformDate = (date) => {
    const parts = date.split("-");

    // Check if the date has three parts (year, month, and day)
    if (parts.length === 3) {
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];

      // Format the date in "DD/MM/YYYY" style
      const formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
    } else {
      return "Invalid date";
    }
  };

  const letterLimit = (text, limit, threePoints) => {
    let description = [];

    if (threePoints) {
      text?.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 1) {
          description = `${description.join("")}...`;
        }
      });
    } else {
      text?.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 2) {
          description = `${description.join("")}...`;
        }
      });
    }

    return description;
  };

  return (
    <div style={content2}>
      <div style={contentValueCard}>
        <p className="titleNameCompanyCard">{transformDate(item?.date)}</p>
        <p className="textTitleValueCard">Data</p>
      </div>

      <div style={contentValueCard}>
        <p
          data-tooltip-place="top"
          data-tooltip-position-strategy="fixed"
          data-tooltip-id="description"
          data-tooltip-content={item?.description}
          className="titleNameCompanyCard"
        >
          {letterLimit(item?.description, 18, true)}
          <ReactTooltip id="description" />
        </p>
        <p className="textTitleValueCard">Descrição da atividade</p>
      </div>

      <div style={contentValueCard}>
        <p
          data-tooltip-place="top"
          data-tooltip-position-strategy="fixed"
          data-tooltip-id="document"
          data-tooltip-content={item?.document}
          className="titleNameCompanyCard"
        >
          {letterLimit(item?.document, 20, true)}
          <ReactTooltip id="document" />
        </p>
        <p className="textTitleValueCard">Documento (DI, Processo e NF)</p>
      </div>

      <div style={contentValueCard}>
        <p className="titleNameCompanyCard">{item?.quantity}</p>
        <p className="textTitleValueCard">Quantidade</p>
      </div>

      <div style={contentValueCard}>
        <p className="titleNameCompanyCard">{item?.price}</p>
        <p className="textTitleValueCard">Valor</p>
      </div>

      <div style={{ ...contentValueCard, width: "111px" }}>
        <p
          data-tooltip-place="top"
          data-tooltip-position-strategy="fixed"
          data-tooltip-id="justification"
          data-tooltip-content={item?.justification}
          className="titleNameCompanyCard"
        >
          {letterLimit(item?.justification, 15, true)}
          <ReactTooltip id="justification" />
        </p>
        <p className="textTitleValueCard">Justificativa</p>
      </div>

      <div style={{ ...contentValueCard, position: "relative" }}>
        {commentsVisible && (
          <>
            <div
              onClick={() => setCommentsVisible(false)}
              style={{
                position: "fixed",
                width: "100vw",
                height: "100vh",
                top: 0,
                left: 0,
                zIndex: 2,
              }}
            ></div>
            <CardComments
              id={item.id}
              closeCard={() => setCommentsVisible(!commentsVisible)}
            />
          </>
        )}
        <p
          onClick={() => setCommentsVisible(!commentsVisible)}
          className="titleNameCompanyCardComments"
        >
          Comentarios
        </p>
        <p className="textTitleValueCard">Comentario</p>
      </div>
      
      <div>
        <buttom style={{cursor: "pointer"}} onClick={() => deleteItem(item.id)}>
          <img src={DeleteIcon} alt="Delete" />
        </buttom>
      </div>

      <div
        style={{
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
      ></div>
    </div>
  );
};

export default CardDetailsGeneralitiesContent;
