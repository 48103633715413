import React, { useContext, useRef } from "react";
import Button from "./Button";
import trashIcon from "../../../images/svg/trashWithBackground.svg"
import pencilIcon from "../../../images/svg/pencilIcon.svg"
import uploadIcon from "../../../images/svg/uploadWithBackground.svg"
import downloadIcon from "../../../images/svg/downloadWithBackground.svg"
import printIcon from "../../../images/svg/printWithBackground.svg"
import simpleUploadIcon from "../../../images/svg/upload.svg"
import { InternalDocumentsContext } from "../../../context/Quality/InternalDocumentsContext";
import AttachedDocument from "./AttachedDocument";
import ResponsibleBoxDocumentDetailModal from "./ResponsibleBoxDocumentDetailModal";
import PermissionsBoxDocumentDetailModal from "./PermissionsBoxDocumentDetailModal";
import ReviewsBoxDocumentDetailModal from "./ReviewsBoxDocumentDetailModal";
import ViewsBoxDocumentDetailModal from "./ViewsBoxDocumentDetailModal";
import DocumentBox from "./DocumentBox";

const ModalDocumentDetail = ({
  item,
  closeModal,
  closeIcon
}) => {

  const { setModalDeleteOpen, setModalDocumentDetailOpen, setModalEditDocumentOpen } = useContext(InternalDocumentsContext);

  const fileInputRef = useRef(null);

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    console.log(files);
  };

  const handleUploadBoxClick = () => {
    fileInputRef.current.click();
  };

  const container = {
    display: "flex",
    flexDirection: "column",
    background: "var(--white)",
    border: "1px solid var(--grey-0)",
    borderRadius: "20px",
    width: "881px",
    height: "704px",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.18)",
  };

  const stylesHeaderMenu = {
    alignItems: "center",
    boxSizing: "border-box",
    borderBottom: "1px solid var(--grey-0)",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    width: "100%",
  };

  const stylesCloseBtn = {
    background: "none",
    border: "none",
    marginRight: "16px",
  };

  const styleTitle = {
    color: "var(--black)",
    fontSize: "18px",
    fontWeight: 500,
  };

  const styleContentBox = {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px"
  };

  const styleInfoDocumentsBox = {
    borderBottom: "1px solid var(--grey-0)",
    display: "flex",
    alignItems: "center",
    paddingBottom: "20px",
    gap: "10px"
  };

  const styleMoreInfosDocumentsBox = {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    overflowY: "auto",
    padding: "20px"
  };

  const styleDocumentTitle = {
    color: "#6A6A6A",
    fontSize: "16px",
    fontWeight: 500,
    marginLeft: "10px"
  };

  const styleDocumentinfosKey = {
    color: "(--black)",
    fontSize: "14px",
    fontWeight: 500,
    marginBottom: "10px"
  };

  const styleDocumentinfosValue = {
    color: "#6A6A6A",
    fontSize: "14px",
    fontWeight: 400,
  };

  const styleUploadBox = {
    border: "3px dotted var(--grey-0)",
    borderRadius: "60px",
    width: "143px",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    cursor: "pointer"
  };

  const styleText = {
    margin: "0",
    fontSize: "13px",
    color: "var(--black)",
    fontWeight: 500,
  };

  const styleDocumentBox = {
    padding: "20px"
  };

  return (
    <div style={container}>
      <header style={stylesHeaderMenu}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            name="closeDeleteMenu"
            style={stylesCloseBtn}
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} alt="close.png" />
          </button>
          <p style={styleTitle}>Detalhes do documento interno</p>
        </div>
        <div>
          <img src={trashIcon} onClick={() => { setModalDeleteOpen(true); setModalDocumentDetailOpen(false) }} />
          <img src={pencilIcon} onClick={() => { setModalEditDocumentOpen(true); setModalDocumentDetailOpen(false) }} />
          <img src={uploadIcon} />
          <img src={downloadIcon} />
          <img src={printIcon} style={{ marginRight: "10px" }} />
          <Button
            width="207px"
            height="33px"
            background="var(--color-primary)"
            color="var(--white)"
            borderRadius="60px"
            marginTop="0"
            name="Iniciar nova revisão"
            border="none"
          />
        </div>
      </header>
      <div style={{ overflowY: "auto" }}>
        <div style={styleContentBox}>
          <div style={styleInfoDocumentsBox}>
            <p style={styleTitle}>{item.code}</p>
            <p style={styleDocumentTitle}>{item.title}</p>
          </div>

          <div style={styleMoreInfosDocumentsBox}>
            <div style={{ display: "flex", alignItems: "flex-start", gap: "15px" }}>
              <div>
                <p style={styleDocumentinfosKey}>Unidade(s)</p>
                <p style={styleDocumentinfosValue}>{"XPM - Matriz"}</p>
              </div>
              <div>
                <p style={styleDocumentinfosKey}>Processo</p>
                <p style={styleDocumentinfosValue}>{"Qualidade"}</p>
              </div>
              <div>
                <p style={styleDocumentinfosKey}>Validade</p>
                <p style={styleDocumentinfosValue}>{"Sem validade"}</p>
              </div>
            </div>
            <div style={{ borderLeft: "1px solid var(--grey-0)", height: "47px" }} />
            <div>
              <div onClick={handleUploadBoxClick} style={styleUploadBox}>
                {/* Nessa div está incluída a função para abrir a galeria e anexar arquivos do computador.  */}
                <img src={simpleUploadIcon} alt="Upload" />
                <p style={styleText}>Incluir anexos</p>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
              />
            </div>
            {item.subDocuments && item.subDocuments.length > 0 && (
              <ul style={{ margin: "0", padding: "0", display: "flex", alignItems: "center", gap: "20px" }}>
                {item.subDocuments.map((subDocument) => <AttachedDocument subDocuments={subDocument} key={subDocument.id} />)}
              </ul>
            )}
          </div>
        </div>
        <ResponsibleBoxDocumentDetailModal />
        <PermissionsBoxDocumentDetailModal />
        <ReviewsBoxDocumentDetailModal />
        <ViewsBoxDocumentDetailModal />
        <div style={styleDocumentBox}>
          <DocumentBox />
        </div>
      </div>
    </div>
  );
};

export default ModalDocumentDetail;
