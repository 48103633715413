import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import jwtDecode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";

export const DashContext = createContext({});

export const DashValidation = ({ children }) => {
  const [comments, setComments] = useState([]);
  const [update, setUpdate] = useState(false);

  const navigate = useNavigate();
	const location = useLocation();

    /* Access Manager - Client - STATE */
    const [listClients, setListClients] = useState([]);
    const [listAdmins, setListAdmins] = useState([]);
    const [idEditOrDelete, setIdEditOrDelete] = useState("");
    const [updatedGeneralities, setUpdatedGeneralities] = useState(false);

    /* Generalities */
    const [listGeneralities, setListGeneralities] = useState([]);
    const [listDetailsGeneralities, setListDetailsGeneralities] = useState([]);
    const [listClientGeneralities, setListClientGeneralities] = useState([]);
    const [generalitieId, setGeneralitieId] = useState("");

    /* Billing */
    const [billing, setBilling] = useState(0);
    const [waitingConference, setWaitingConference] = useState(0);
    const [awaitingPayment, setAwaitingPayment] = useState(0);
    const [awaitingPaymentVirtual, setAwaitingPaymentVirtual] = useState(0);
    const [paymentConcluid, setPaymentConcluid] = useState(0);
    const [discount, setDiscount] = useState();
    const [minimumBilling, setMinimumBilling] = useState(0);
    const [billingType, setBillingType] = useState("");
    const [billingTaxed, setBillingTaxed] = useState(0);
    const [minBillingTaxed, setMinBillingTaxed] = useState(0);
    const [tax, setTax] = useState();
    const [minTax, setMinTax] = useState();
    const [variables, setVariables] = useState([]);
    const [porcent, setPorcent] = useState();
    const [manualBilling, setManualBilling] = useState(0);
    const [fixedMonthlyValue, setFixedMonthlyValue] = useState();
    const [overload, setOverload] = useState(0);
    const [taxesArray, setTaxesArray] = useState([]);

    const [paymentConfirmed, setPaymentConfirmed] = useState(false);

    /* PARAMS STATES */
    const [allParamsCategory, setAllParamsCategory] = useState([]);
    const [arrayOfParams, setArrayOfParams] = useState([]);

    /* PERMISSION */
    const [permission, setPermission] = useState("");

    useEffect(() => {
        const token = localStorage.getItem("@XPM_ADM_TOKEN:");
        if (token) setPermission(jwtDecode(token).permission);
    }, []);

    /* Comments */
    const createComments = async (data, parameter, id) => {
        try {
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

            const monthAndYear = `${localStorage.getItem(
                "@XPM_CLIENT_MONTH:"
            )}-${localStorage.getItem("@XPM_CLIENT_YEAR:")}`;

            data.date = monthAndYear;

            await api.post("/comments", data);

            const response = await api.get(
                `/comments/sub/all/${clientCnpj}/${monthAndYear}/${parameter}/${id}`
            );

            setComments(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const createSubComments = async (data, parameter, id) => {
        try {
            await api.post("/comments/sub", data);

            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

            const monthAndYear = `${localStorage.getItem(
                "@XPM_CLIENT_MONTH:"
            )}-${localStorage.getItem("@XPM_CLIENT_YEAR:")}`;

            const response = await api.get(
                `/comments/sub/all/${clientCnpj}/${monthAndYear}/${parameter}/${id}`
            );

            setComments(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getComments = async (parameter, id) => {
        console.log("parameter", parameter);
        console.log("id", id);
        try {
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

            const monthAndYear = `${localStorage.getItem(
                "@XPM_CLIENT_MONTH:"
            )}-${localStorage.getItem("@XPM_CLIENT_YEAR:")}`;

            const url = `/comments/sub/all/${clientCnpj}/${monthAndYear}/${parameter}/${id}`;

            const response = await api.get(url);

            setComments(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    /* Generalities */

    /* Parameters */
    const getParametersGeneralities = async () => {
        try {
            const cnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
            const date = `${localStorage.getItem(
                "@XPM_CLIENT_YEAR:"
            )}-${localStorage.getItem("@XPM_CLIENT_MONTH:")}`;
            if (cnpj && date) {
                const response = await api.get(
                    `/generalities/parameter/${cnpj}/${date}`
                );
                setListGeneralities(response.data);
            } else {
                toast.warning("Por favor, selecione um cliente");
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                if (error.response.status < 500) {
                    toast.error(error?.response?.data?.message, {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    const createParametersGeneralities = async (data) => {
        try {
            await api.post("/generalities/parameter", data);
            getParametersGeneralities();
        } catch (error) {
            if (error?.response?.data?.message) {
                if (error.response.status < 500) {
                    toast.error(error?.response?.data?.message, {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    const updateParametersGeneralities = async (id, data) => {
        try {
            const payload = {
                description: data.description,
                unit: data.unit,
                price: data.price,
                recurrence: data.recurrence,
                quantity: data.quantity,
            };
            await api.patch(`/generalities/parameter/${id}`, payload);

            getParametersGeneralities();
        } catch (error) {
            if (error?.response?.data?.message) {
                if (error.response.status < 500) {
                    toast.error(error?.response?.data?.message, {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    const destroyParametersGeneralities = async (id) => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");

            await api.delete(`/generalities/parameter/${id}/${year}-${month}`);
            getParametersGeneralities();
        } catch (error) {
            if (error?.response?.data?.message) {
                if (error.response.status < 500) {
                    toast.error(error?.response?.data?.message, {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    /* Generalites client */

    const getGeneralities = async () => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

            const response = await api.get(
                `/generalities/one/${clientCnpj}/${year}/${month}`
            );
            // console.log('generalidades', `${month}-${year}: `, response.data)
            setListClientGeneralities(response.data);
            setUpdatedGeneralities(true);
        } catch (error) {
            if (error?.response?.data?.message) {
                if (error.response.status < 500) {
                    toast.error(error?.response?.data?.message, {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    const getFixedGeneralities = async () => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

            const response = await api.get(
                `generalities/fixed-generalities/${clientCnpj}/${year}/${month}`
            );
            // console.log('generalidades fixas', `${month}-${year}: `, response.data);
            if (response.data && response.data.length > 0) {
                setUpdatedGeneralities(false);
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                if (error.response.status < 500) {
                    toast.error(error?.response?.data?.message, {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    const getDetailsGeneralities = async () => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
            const idGeneralities = localStorage.getItem(
                "@XPM_CLIENT_PAGEMENT_DETAILS_GENERALITIES_ID:"
            );
            const response = await api.get(
                `/generalities/details/${clientCnpj}/${idGeneralities}/${year}/${month}`
            );
            setListDetailsGeneralities(response.data);
        } catch (error) {
            if (error?.response?.data?.message) {
                if (error.response.status < 500) {
                    toast.error(error?.response?.data?.message, {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    const createGeneralities = async (data, closeModal) => {
        try {
            await api.post(`/generalities`, data);
            getGeneralities();
            closeModal();
        } catch (error) {
            if (error?.response?.data?.message) {
                if (error.response.status < 500) {
                    toast.error(error?.response?.data?.message, {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    const updateFixedGeneralitites = async (payload) => {
        try {
            const updatedGenerality = await api.patch(
                "/generalities/fixed-generalities",
                payload
            );

            if (updatedGenerality.data) {
                getGeneralities();
                toast.success("Generalidade atualizada", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } catch (error) {
            if (error?.response?.data?.message) {
                if (error.response.status < 500) {
                    toast.error(error?.response?.data?.message, {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    /* Access Manager - Client */

    const onGetClient = async () => {
        try {
            const response = await api.get("/client/account");
            setListClients(response.data);
        } catch (error) {
            if (error?.response?.data?.message) {
                if (error.response.status < 500) {
                    toast.error(error?.response?.data?.message, {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    const onCreateClient = async (data, closeModal) => {
        try {
            await api.post("/client/account", data);
            onGetClient();
            closeModal();
        } catch (error) {
            if (error?.response?.data?.message) {
                if (error.response.status < 500) {
                    toast.error(error?.response?.data?.message, {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    const onEditClient = async (data, closeModal) => {
        try {
            await api.patch(`/client/account/${idEditOrDelete}`, data);
            onGetClient();
            closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    const onDestroyClient = async (closeModal) => {
        try {
            await api.delete(`/client/account/${idEditOrDelete}`);
            onGetClient();
            closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    /* Access Manager - Admin */

  const onGetAdmin = async () => {
    try {
      const response = await api.get("/admin");
      setListAdmins(response.data);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (error?.response.status === 401) {
        localStorage.removeItem('@XPM_CLIENT_COMPANY:');
        localStorage.removeItem('@XPM_ADM_TOKEN:');
        localStorage.removeItem('@XPM_CLIENT_MONTH_COMMENT:');
        navigate("/");
      }
    }
  };

    const onCreateAdmin = async (data, closeModal) => {
        try {
            await api.post("/admin", data);
            onGetAdmin();
            closeModal();
        } catch (error) {
            if (error?.response?.data?.message) {
                if (error.response.status < 500) {
                    toast.error(error?.response?.data?.message, {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    const onEditAdmin = async (data, closeModal) => {
        try {
            await api.patch(`/admin/${idEditOrDelete}`, data);
            onGetAdmin();
            closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    const onDestroyAdmin = async (closeModal) => {
        try {
            await api.delete(`/admin/${idEditOrDelete}`);
            onGetAdmin();
            closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    /* Billing */

    const checkBilling = (value, additionOrSubtraction) => {
        // console.log(additionOrSubtraction);
        if (additionOrSubtraction) {
            // console.log(waitingConference, awaitingPayment, value, "Entrada");
            const subtraction = waitingConference - value;
            const addition = awaitingPayment + value;
            setWaitingConference(subtraction);
            setAwaitingPayment(addition);
        } else if (!additionOrSubtraction) {
            // console.log(waitingConference, awaitingPayment, value, "Saida");
            const subtraction = awaitingPayment - value;
            const addition = waitingConference + value;
            setWaitingConference(addition);
            setAwaitingPayment(subtraction);
        }
    };

    const sendBillingToCustomer = async () => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

            const data = {
                date: `${year}-${month}`,
                client_cnpj: clientCnpj,
                minimumBilling: minimumBilling,
                billingType: billingType,
                overload: overload,
                discount: discount,
            };
            if (billingType === "porcentual") {
                data.manual_billing = manualBilling;
            }
            // console.log(data);
            // alert('enviar email');

            const response = await api.patch("/billing/admin", data);

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            console.log(error);
        }
    };

    const checkPaymentStatus = async () => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

            const response = await api.get(
                `/billing/${clientCnpj}/${year}-${month}`
            );

            const { billingChecked, billingPay } = response.data;

            if (billingPay) {
                setPaymentConfirmed(true);
                setPaymentConcluid(billing);
                return null;
            } else if (billingChecked) {
                setPaymentConfirmed(true);
                setAwaitingPaymentVirtual(billing);
                return null;
            } else {
                setPaymentConfirmed(false);
                setWaitingConference(billing);
            }
            // console.log(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getDiscount = async () => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
            let response = {};
            if (clientCnpj && year && month) {
                response = await api.get(
                    `/billing/admin/discount/${clientCnpj}/${year}-${month}`
                );
            }
            if (response.data && response.data.discount) {
                setDiscount(response.data.discount);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateDiscount = async () => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

            const data = {
                date: `${year}-${month}`,
                client_cnpj: clientCnpj,
                discount: discount,
            };
            if (billing) {
                await api.patch("/billing/admin/discount", data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getTaxes = async () => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
            let response = {};
            if (clientCnpj && year && month) {
                console.log("requistitando taxes", `${year}-${month}`);
                response = await api.get(
                    `/taxes/${clientCnpj}/${year}-${month}`
                );
            }
            if (response.data) {
                setTaxesArray(response.data);
                const sum = response.data.reduce(
                    (acc, curr) => acc + Number(curr.porcentage),
                    0
                );
                console.log("sum taxes", sum);
                setTax(sum);
                setMinTax(sum);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateTax = async (data) => {
        try {
            const payload = {
                name: data.name,
                porcentage: data.porcentage,
            };
            await api.patch(`/taxes/${data.id}`, payload);
        } catch (error) {
            console.log(error);
        }
    };

    const createTax = async (data) => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
            const payload = {
                name: data.name,
                porcentage: data.porcentage,
                clientCnpj,
                date: `${year}-${month}`,
            };
            await api.post(`/taxes`, payload);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteTax = async (id) => {
        try {
            await api.delete(`/taxes/${id}`);
            toast.warning("Imposto deletado", { autoClose: 2000 });
        } catch (error) {
            console.log(error);
        }
    };

    const getPorcent = async () => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
            let response = {};
            if (clientCnpj && year && month) {
                response = await api.get(
                    `/billing/admin/porcent/${clientCnpj}/${year}-${month}`
                );
            }
            if (response.data && response.data.porcent) {
                setPorcent(response.data.porcent);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updatePorcent = async () => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

            const data = {
                date: `${year}-${month}`,
                client_cnpj: clientCnpj,
                porcent: porcent,
            };

            await api.patch("/billing/admin/porcent", data);
        } catch (error) {
            console.log(error);
        }
    };

    /* Variables */

    const getVariables = async (cnpj, year, month) => {
        let clientCnpj = cnpj || localStorage.getItem("@XPM_CLIENT_COMPANY:");
        let yearSelected = year || localStorage.getItem("@XPM_CLIENT_YEAR:");
        let monthSelected = month || localStorage.getItem("@XPM_CLIENT_MONTH:");
        const date = `${yearSelected}-${monthSelected}`;
        try {
            const response = await api.get(`/variables/${clientCnpj}/${date}`);
            if (response.data) {
                const variablesArray = [];
                console.log(arrayOfParams);
                response.data.forEach((variable) => {
                    const filteredParameter = arrayOfParams.filter(
                        (parameter) =>
                            parameter.process === variable.process &&
                            parameter.description === variable.name
                    )[0];
                    console.log(variable, filteredParameter);
                    const payload = {
                        ...variable,
                        value: `${filteredParameter?.soma_qtd_item || 0}`,
                    };
                    variablesArray.push(payload);
                    setVariables(variablesArray);
                });
                // setVariables(response.data);
            } else {
                setVariables([]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (location.pathname !== '/') {
					getVariables();
				}
        // getTaxes();
    }, [arrayOfParams]);

    const createVariables = async (array, cnpj, year, month) => {
        let clientCnpj = cnpj || localStorage.getItem("@XPM_CLIENT_COMPANY:");
        let yearSelected = year || localStorage.getItem("@XPM_CLIENT_YEAR:");
        let monthSelected = month || localStorage.getItem("@XPM_CLIENT_MONTH:");
        const date = `${yearSelected}-${monthSelected}`;
        try {
            const promises = await Promise.all(
                array.map((e) =>
                    api.post("/variables", { ...e, clientCnpj, date })
                )
            );
            //  console.log('variáveis criadas:', promises);
            getVariables(clientCnpj, yearSelected, monthSelected);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteVariable = async (id) => {
        try {
            await api.delete(`/variables/${id}`);
            getVariables();
        } catch (error) {
            console.log(error);
        }
    };

    const updateVariable = async (array) => {
        try {
            const promises = await Promise.all(
                array.map((e) => api.patch(`/variables/${e.id}`, { ...e }))
            );
            //  console.log('variáveis atualizadas:', promises);
            getVariables();
        } catch (error) {
            console.log(error);
        }
    };

    /* MANUAL BILLING */

    const getManualBilling = async () => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
            let response = {};
            if (clientCnpj && year && month) {
                response = await api.get(
                    `/billing/client/manual-billing/${clientCnpj}/${year}-${month}`
                );
                //  console.log('get manual-billing', response);
            }
            if (response.data && response.data.manual_billing) {
                setManualBilling(response.data.manual_billing);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateManualBilling = async (manualBilling = 0) => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");

            const data = {
                date: `${year}-${month}`,
                client_cnpj: clientCnpj,
                manual_billing: manualBilling,
            };

            await api.patch("/billing/admin/manual-billing", data);
        } catch (error) {
            console.log(error);
        }
    };

    /* PARAMS CATEGORY */
    const getParamsCategory = async (array) => {
        try {
            const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
            const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
            await Promise.all(
                array.map(async (param) => {
                    await api.get(
                        `/param-categories/${param.id}/${year}-${month}`
                    );
                })
            );
        } catch (error) {
            console.log(error);
        }
    };

    /* Update state */

    const updateFunction = () => {
        setUpdate(!update);
    };

    const monthUpdate = () => {
        setBilling(0);
        setAwaitingPayment(0);
        setAwaitingPaymentVirtual(0);
        setPaymentConcluid(0);
        setPaymentConfirmed(0);
    };

    useEffect(() => {
        // console.log('comparando')
        // console.log('comparação', billing + Number(discount), awaitingPayment)
        // console.log('comparação', billing + Number(discount) >= awaitingPayment, !waitingConference)
        // console.log('wainting', waitingConference)
        const marginOfError = 0.1;
        if (
            billingType === "atividade" &&
            billing > 0.1 &&
            Math.abs(billing - (awaitingPayment - Number(discount))) <
                marginOfError
        ) {
            updateDiscount();
            // alert('enviar email');
            sendBillingToCustomer();
        }
        if (
            billingType === "porcentual" &&
            Number(manualBilling) > 0 &&
            porcent > 0 &&
            billing > 0.1 &&
            Math.abs(
                Number(billing) + (Number(discount) || 0) - awaitingPayment
            ) < marginOfError
        ) {
            updatePorcent(porcent || 0);
            updateDiscount(discount || 0);
            updateManualBilling(manualBilling || 0);
            // alert('enviar email')
            sendBillingToCustomer();
        }

        if (
            billingType === "mao de obra" &&
            billing > 0.1 &&
            Math.abs(billing - (awaitingPayment - Number(discount))) <
                marginOfError
        ) {
            updateDiscount();
            // alert('enviar email')
            sendBillingToCustomer();
        }

        const total = Number(billing) + Number(discount);
        if (
            billingType === "atividade sem imposto" &&
            awaitingPayment &&
            waitingConference - marginOfError <= 0 &&
            tax > 0 &&
            awaitingPayment >= total - marginOfError
        ) {
            updateTax();
            updateDiscount();
            // alert('enviar email');
            sendBillingToCustomer();
        }
    }, [billing, awaitingPayment, porcent, tax, minTax]);

    useEffect(() => {
        if (billing > 0.1) {
            checkPaymentStatus();
            setAwaitingPaymentVirtual(0);
            setPaymentConcluid(0);
        }
    }, [billing]);

    useEffect(() => {
        if (updatedGeneralities === false && location.pathname !== '/') {
            getGeneralities();
            getFixedGeneralities();
        }
    }, [listGeneralities, updatedGeneralities]);

    useEffect(() => {
        if (manualBilling) {
            updateManualBilling(manualBilling);
        }
        if (porcent) {
            updatePorcent(porcent);
        }
    }, [manualBilling, porcent]);

    return (
        <>
            <DashContext.Provider
                value={{
                    createComments,
                    getComments,
                    createSubComments,
                    comments,
                    updateFunction,
                    update,
                    listClients,
                    onCreateClient,
                    onDestroyClient,
                    onGetClient,
                    onEditClient,
                    setIdEditOrDelete,
                    listAdmins,
                    onCreateAdmin,
                    onDestroyAdmin,
                    onGetAdmin,
                    onEditAdmin,
                    getParametersGeneralities,
                    createParametersGeneralities,
                    updateParametersGeneralities,
                    destroyParametersGeneralities,
                    getGeneralities,
                    getFixedGeneralities,
                    getDetailsGeneralities,
                    createGeneralities,
                    listGeneralities,
                    listDetailsGeneralities,
                    listClientGeneralities,
                    generalitieId,
                    setBilling,
                    billing,
                    waitingConference,
                    setWaitingConference,
                    awaitingPayment,
                    setAwaitingPayment,
                    checkBilling,
                    paymentConfirmed,
                    paymentConcluid,
                    awaitingPaymentVirtual,
                    monthUpdate,
                    discount,
                    setDiscount,
                    minimumBilling,
                    setMinimumBilling,
                    billingType,
                    setBillingType,
                    billingTaxed,
                    setBillingTaxed,
                    minBillingTaxed,
                    setMinBillingTaxed,
                    tax,
                    setTax,
                    minTax,
                    setMinTax,
                    getDiscount,
                    getTaxes,
                    getVariables,
                    variables,
                    setVariables,
                    createVariables,
                    deleteVariable,
                    updateVariable,
                    porcent,
                    setPorcent,
                    getPorcent,
                    getManualBilling,
                    manualBilling,
                    setManualBilling,
                    fixedMonthlyValue,
                    setFixedMonthlyValue,
                    overload,
                    setOverload,
                    updateFixedGeneralitites,
                    allParamsCategory,
                    setAllParamsCategory,
                    getParamsCategory,
                    permission,
                    setPermission,
                    arrayOfParams,
                    setArrayOfParams,
                    taxesArray,
                    setTaxesArray,
                    deleteTax,
                    createTax,
                    updateTax,
                    setListDetailsGeneralities,
                }}
            >
                {children}
            </DashContext.Provider>
        </>
    );
};
