import { InputNumber } from "primereact/inputnumber";
import { useContext, useEffect, useState } from "react";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";

const LinePercentageInput = ({ month, value, type, typeMonths, disabled }) => {
  const {
    setStoreAccuracyAndConsolidation,
    storeAccuracyAndConsolidation,
    createAccuracy,
  } = useContext(GraphsAndParametersContext);
  const [valueInput, setValueInput] = useState(0);

  const createLine = async () => {
    const newLine = {
      month: month,
      accuracy: valueInput,
      type: type,
      typeMonths: typeMonths,
      year: localStorage.getItem("@XPM_CLIENT_YEAR:"),
      client: localStorage.getItem("@XPM_CLIENT_COMPANY:"),
    };

    console.log(newLine);

    await createAccuracy(newLine);
    // setStoreAccuracyAndConsolidation([
    //   ...storeAccuracyAndConsolidation,
    //   newLine,
    // ]);
  };

  const handleInputChange = (event) => {
    const newValue = Math.min(event.value, 100); // Limit to 100
    setValueInput(newValue);
  };

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  return (
    <InputNumber
      disabled={disabled}
      className="primereact textMonthAccuracyTitle"
      onChange={handleInputChange}
      onBlur={() => createLine()}
      value={valueInput}
      placeholder="Insira a métrica"
      suffix="%"
      decimalSeparator=","
      minFractionDigits={2}
      style={{ width: "35px", margin: "0 auto" }}
    />
  );
};

export default LinePercentageInput;
