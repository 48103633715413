import HeaderManager from "../components/HeaderManager";
import OneColumnLayout from "../../../layouts/OneColumLayout";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import BoxSchedulesList from "../components/BoxSchedulesList";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";

const SchedulingByDate = () => {
    const { receivedSchedules, deliveredSchedules, completedSchedules } =
        useContext(SchedulesContext);

    const [active, setActive] = useState("Recebimentos");

    const navigate = useNavigate();

    const container = {
        backgroundColor: "#F9F9F9",
        height: "100vh",
        padding: "0px 50px",
        width: "100%",
    };

    const contentStyle = {
        margin: "0 auto",
        maxWidth: "1200px",
    };

    return (
        <OneColumnLayout
            page={"Company"}
            colum1={
                <div className="containerBox" style={container}>
                    <div style={contentStyle}>
                        <HeaderManager
                            name=""
                            adm={true}
                            back={true}
                            logo={true}
                            active={active}
                            setActive={setActive}
                            onClick={() => navigate("/dashboard/scheduling")}
                        />

                        <BoxSchedulesList
                            schedules={
                                active === "Recebimentos"
                                    ? receivedSchedules
                                    : active === "Coletas"
                                    ? deliveredSchedules
                                    : completedSchedules
                            }
                            active={active}
                        />
                    </div>
                </div>
            }
        />
    );
};

export default SchedulingByDate;
