import React, { useEffect, useState } from "react";
import treePoints from "../../../images/svg/treePoints.svg";
import imageDefaultUser from "../../../images/svg/imageDefaultUser.svg";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { DashContext } from "../../../context/Dashboard/DashContext";
import { useContext } from "react";

const CardUser = ({
  user,
  filterOption,
  globalCheked,
  setCardSelected,
  setModalOpstions,
  setOptionModalFormClient,
  setOptionModalFormAdmin,
  setNameButtom,
  setColorButtom,
  listCardSelected,
  setListCardSelected,
}) => {
  const { setIdEditOrDelete } = useContext(DashContext);
  const [checkedCard, setCheckedCard] = useState(false);

  const letterLimit = (text, limit, threePoints) => {
    console.log(user);
    console.log("texto para split", text);
    let description = [];

    if (threePoints && text) {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 1) {
          description = `${description.join("")}...`;
        }
      });
    } else {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 2) {
          description = `${description.join("")}...`;
        }
      });
    }

    return description;
  };

  const updateListUsers = () => {
    if (checkedCard) {
      const newArray = listCardSelected.filter((elm) => {
        return elm !== user.id;
      });
      setListCardSelected(newArray);
    } else {
      setListCardSelected([...listCardSelected, user.id]);
    }
  };

  useEffect(() => {
    setCheckedCard(globalCheked);
  }, [globalCheked]);

  useEffect(() => {
    if (checkedCard) {
      setNameButtom("Deletar usuário(s)");
      setColorButtom("red");
    }
    if (listCardSelected.length == 0) {
      setColorButtom("var(--color-primary)");
    }
  }, [checkedCard]);

  useEffect(() => {}, [user]);

  return (
    <>
      {filterOption == "Acessos admin" ? (
        <tbody style={{ position: "relative", top: "20px" }}>
          <tr style={{ position: "relative", top: "20px", border: "none" }}>
            <td style={{ borderRight: "none" }}>
              <input
                type="checkbox"
                checked={checkedCard}
                onChange={() => {
                  setCheckedCard(!checkedCard);
                  updateListUsers();
                }}
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "3px",
                }}
              />
            </td>
            <td style={{ borderRight: "none" }}>
              <div
                style={{ display: "flex", position: "relative", top: "9px" }}
              >
                {
                  <div style={{ display: "flex" }}>
                    <img
                      src={user.picture || imageDefaultUser}
                      style={{
                        width: "35px",
                        height: "35px",
                        position: "relative",
                        top: "-8px",
                        left: "-5px",
                        borderRadius: "100px",
                        border: "1px solid #020066",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </div>
                }
                <p
                  style={{
                    borderRight: "none",
                    color: "black",
                    position: "relative",
                    left: "10px",
                  }}
                >
                  <p
                    data-tooltip-place="top"
                    data-tooltip-position-strategy="fixed"
                    data-tooltip-id="name"
                    data-tooltip-content={user?.name}
                    className="textDetailsUserTable"
                    // className="textDetailsUserTable"
                  >
                    {letterLimit(user.name, 16, true)}
                    {/* {user.name} */}
                    <ReactTooltip id="name" />
                  </p>
                </p>
              </div>
            </td>
            <td style={{ borderRight: "none", color: "black" }}>
              <p
                data-tooltip-place="top"
                data-tooltip-position-strategy="fixed"
                data-tooltip-id="email"
                data-tooltip-content={user?.email}
                className="textDetailsUserTable"
                // className="textDetailsUserTable"
              >
                {letterLimit(user.email, 21, true)}
                {/* {user.email} */}
                <ReactTooltip id="email" />
              </p>
            </td>
            <td style={{ borderRight: "none", color: "black" }}>
              <p
                data-tooltip-place="top"
                data-tooltip-position-strategy="fixed"
                data-tooltip-id="permission"
                data-tooltip-content={user?.permission}
                className="textDetailsUserTable"
                // className="textDetailsUserTable"
              >
                {user.permission}
                <ReactTooltip id="permission" />
              </p>
            </td>
            <td
              onClick={() => {
                setCardSelected(user);
                setModalOpstions();
                setOptionModalFormAdmin();
                setIdEditOrDelete(user.id);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={treePoints} />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody style={{ position: "relative", top: "20px" }}>
          <tr style={{ position: "relative", top: "20px", border: "none" }}>
            <td style={{ borderRight: "none" }}>
              <input
                type="checkbox"
                checked={checkedCard}
                onChange={() => setCheckedCard(!checkedCard)}
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "3px",
                }}
              />
            </td>
            <td style={{ borderRight: "none" }}>
              <div
                style={{ display: "flex", position: "relative", top: "9px" }}
              >
                {
                  <div style={{ display: "flex" }}>
                    <img
                      src={user.picture || imageDefaultUser}
                      style={{
                        width: "35px",
                        height: "35px",
                        position: "relative",
                        top: "-8px",
                        left: "-5px",
                        borderRadius: "100px",
                        border: "1px solid #020066",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </div>
                }
                <p
                  data-tooltip-place="top"
                  data-tooltip-position-strategy="fixed"
                  data-tooltip-id="name"
                  data-tooltip-content={user?.name}
                  className="textDetailsUserTable"
                  style={{
                    borderRight: "none",
                    color: "black",
                    position: "relative",
                    left: "10px",
                  }}
                >
                  {letterLimit(user.name, 7, true)}
                  {/* {user.name} */}
                  <ReactTooltip id="name" />
                </p>
              </div>
            </td>
            <td style={{ borderRight: "none", color: "black" }}>
              <p
                data-tooltip-place="top"
                data-tooltip-position-strategy="fixed"
                data-tooltip-id="client"
                data-tooltip-content={user?.client}
                className="textDetailsUserTable"
              >
                {letterLimit(user.client, 21, true)}
                <ReactTooltip id="client" />
              </p>
            </td>
            <td style={{ borderRight: "none", color: "black" }}>
              <p
                data-tooltip-place="top"
                data-tooltip-position-strategy="fixed"
                data-tooltip-id="email"
                data-tooltip-content={user?.email}
                className="textDetailsUserTable"
              >
                {letterLimit(user.email, 21, true)}
                {/* {user.email} */}
                <ReactTooltip id="email" />
              </p>
            </td>
            <td style={{ borderRight: "none", color: "black" }}>
              <p className="textDetailsUserTable">{user.coin}</p>
            </td>
            <td style={{ borderRight: "none", color: "black" }}>
              <p className="textDetailsUserTable">{user.date}</p>
            </td>
            <td
              onClick={() => {
                setCardSelected(user);
                setModalOpstions();
                setOptionModalFormClient();
                setIdEditOrDelete(user.id);
              }}
              style={{ cursor: "pointer" }}
            >
              <img src={treePoints} />
            </td>
          </tr>
        </tbody>
      )}
    </>
  );
};

export default CardUser;
