import ThowColumLayout from "../../../layouts/ThowColumLayout";
import ContainerWigth from "../components/ContainerWigth";
import HeaderManager from "../components/HeaderManager";
import SearchMoreFilter from "../components/SearchMoreFilter";

import Sidebar from "../../../components/Sidebar";
import ContentMonths from "../components/ContentMonths";
import { useEffect, useState } from "react";

const Productivity = () => {
  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    paddingBottom: "50px",
  };

  const [state1, setState1] = useState(false);
const [state2, setState2] = useState(false);


useEffect(() => {
document.body.addEventListener("mousedown", event => {
handleMouseDown(event)
});

document.body.addEventListener("keydown", event => {
handleKeyDown(event)
});

isConsoleOpened();

}, [state1, state2]);

useEffect(() => {
isConsoleOpened();
}, [])

const handleKeyDown = (event) => {
setState1(prevState => !prevState)
if (event.keyCode === 123) {
const a = document.querySelector(".dev-tools-open")
a?.removeAttribute('src');
}

if (event.keyCode === 17) {
const a = document.querySelector(".dev-tools-open")
a?.removeAttribute('src');
}
};

const handleMouseDown = (event) => {
setState2(prevState => !prevState)
if (event.button === 2) {
const a = document.querySelector(".dev-tools-open")
a?.removeAttribute('src');
}
};

function isConsoleOpened() {
let devtools = /./;
devtools.toString = function () {
  alert('dev open')
const a = document.querySelector(".dev-tools-open")
a?.removeAttribute('src');
}

return devtools.opened ? true : false;
}

setInterval(() => {

var devtools = function() {};
devtools.toString = function() {
  if (!this.opened) {
    alert("Opened");
  }
  this.opened = true;
}
}, 1000);

  return (
    <ThowColumLayout
      page={"Company"}
      colum1={<Sidebar page="Productivity" />}
      colum2={
        <div style={container}>
          <HeaderManager name="Produtividade" adm={true} />
          <ContainerWigth>
            <SearchMoreFilter />
            <ContentMonths />
          </ContainerWigth>
        </div>
      }
    ></ThowColumLayout>
  );
};

export default Productivity;
