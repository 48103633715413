import React, { useContext } from "react";
import closeIcon from "../../../images/svg/closeIcon.svg";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";

const ModalDelete = ({ schedule, closeModal }) => {
    const { deleteSchedule } = useContext(SchedulesContext);

    const startDate = schedule.start_time.slice(0, 10).split("-");

    const stylesMenu = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#fff",
        border: "1px solid #D7D7D7",
        borderRadius: "20px",
        width: "360px",
        height: "275px",
        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.18)",
    };

    const stylesHeaderMenu = {
        alignItems: "center",
        boxSizing: "border-box",
        borderBottom: "1px solid #D7D7D7",
        display: "flex",
        justifyContent: "space-between",
        padding: "20px 15px",
        width: "100%",
    };

    const stylesCloseBtn = {
        background: "none",
        border: "none",
    };

    const stylesDeleteBtn = {
        background: "#FFE1E1",
        borderRadius: "20px",
        border: "none",
        color: "#EA0000",
        padding: "10px 25px",
        height: "90%",
    };

    const stylesMessage = {
        textAlign: "center",
        padding: "30px",
        boxSizing: "border-box",
    };

    return (
        <section style={stylesMenu}>
            <header style={stylesHeaderMenu}>
                <button
                    name="closeDeleteMenu"
                    style={stylesCloseBtn}
                    onClick={() => closeModal(false)}
                >
                    <img src={closeIcon} alt="close.png" />
                </button>

                <p>Excluir agendamento</p>

                <button
                    style={stylesDeleteBtn}
                    onClick={() => deleteSchedule(schedule.id, closeModal)}
                >
                    Excluir
                </button>
            </header>

            <p style={stylesMessage}>
                Tem certeza que deseja excluir o agentamento em{" "}
                <strong>{`${startDate[2]}/${startDate[1]}/${startDate[0]}`}</strong>
                {" do cliente "}
                <strong>{schedule.client.name}</strong> ?
            </p>
        </section>
    );
};

export default ModalDelete;
