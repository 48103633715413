import React, { useEffect, useState } from "react";
import Textarea from "./Textarea";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";

const arrowSvg = `
  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.274428 1.61166C0.109402 1.37389 0.109402 1.05863 0.274428 0.820855C0.550264 0.423421 1.13801 0.423421 1.41385 0.820855L2.57301 2.49102C3.41008 3.69709 5.19369 3.69709 6.03076 2.49102L7.18993 0.820855C7.46576 0.423421 8.05351 0.423421 8.32934 0.820855C8.49437 1.05863 8.49437 1.37389 8.32934 1.61166L7.20524 3.23131C5.79952 5.25671 2.80425 5.25671 1.39854 3.23131L0.274428 1.61166Z" fill="black"/>
  </svg>
`;

const arrowSvgUri = encodeURIComponent(arrowSvg);

const BoxParameterSelect = ({
  number,
  type,
  width,
  onChange,
  value,
  valueInput,
  onBlur,
  height,
  options,
  listValues,
  update,
  percentage,
  coin,
  gap,
  unit,
}) => {
  const [valueInputState, setValueInputState] = useState("");
  const [optionSelect, setOptionSelect] = useState("");
  const [typeStructureAll, setTypeStructureAll] = useState([]);

  const [typeCategoryAll, setTypeCategoryAll] = useState([]);
  const [typeNumber, setTypeNumber] = useState(true);
  const [unitOnner, setUnitOnner] = useState("");

  /* STYLES */
  const boxCard = {
    display: "flex",
    gap: "18px",
    alignItems: "end",
  };

  const numberBox = {
    backgroundColor: "#96C",
    color: "#fff",
    fontSize: "19px",
    padding: "6px 16px",
    borderRadius: "60px",
    marginBottom: "5px",
  };

  const selectBox = {
    outline: "none",
    width: width || "100%",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    padding: "12px 13px",
    appearance: "none",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 10px center",
    paddingRight: "30px",
  };

  const inputBox = {
    outline: "none",
    width: width || "100%",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    padding: "12px 13px",
  };

  const verifyNameType = () => {
    if (type === "process") {
      return "Processo";
    } else if (type === "description") {
      return "Descrição da atividade";
    } else if (type === "unit") {
      return "Unidade de medida";
    } else if (type === "price") {
      return `Preço ${value || ""}`;
    } else if (type === "titleGenerality") {
      return "Tipo de generalidade";
    } else if (type === "dateGenerality") {
      return "Data";
    } else if (type === "qtdGenerality") {
      return "Quantidade";
    } else if (type === "typeStructure") {
      return "Tipo de estrutura";
    } else if (type === "typeCategory") {
      return "Tipo de categoria";
    } else if (type === "justification") {
      return "Justificativa";
    } else if (type === "typeCalculation") {
      return "Tipo de calculo";
    } else if (type === "conversion") {
      return "Conversão (Em desenvolvimento)";
    } else if (type === "code") {
      return "Código SKU";
    } else if (type === "qtdBox") {
      return "QTD por caixa";
    } else if (type === "time") {
      return "Tempo";
    } else if (type === "kpi") {
      return "KPI exigido";
    } else if (type === "typeCategoryContractual") {
      return "Horas ou Dias";
    } else if (type === "metric") {
      return "Métrica";
    } else if (type === "typeMetric") {
      return "Tipo de métrica";
    } else if (type === "date") {
      return "Mês e ano";
    } else if (type === "constancy") {
      return "Constância";
    } else if (type === "hourInitial") {
      return "Horário inicial";
    } else if (type === "hourFinished") {
      return "Horário final";
    }
  };

  const formatCoin = (typeCoin, valueCoin, setValueCoin) => {
    if (percentage) {
      const number = Number(valueCoin);
      setValueCoin(`${number}%`);
    } else if (coin === "real" || typeCoin === "real") {
      const number = Number(valueCoin);
      setValueCoin(
        number.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      );
    }
  };

  useEffect(() => {
    setTypeCategoryAll(listValues);
    setTypeStructureAll(listValues);
  }, [listValues]);

  // useEffect(() => {
  //   getStructure();
  // }, []);

  // useEffect(() => {
  //   getStructure();
  // }, []);

  // useEffect(() => {
  //   getCategory();
  // }, [update]);

  useEffect(() => {
    verifyNameType(value);
  }, [value]);

  useEffect(() => {
    setOptionSelect(options);
  }, [options]);

  useEffect(() => {
    setValueInputState(valueInput);
  }, [valueInput]);

  useEffect(() => {
    setUnitOnner(unitOnner);
  }, [unit]);

  return (
    <div style={boxCard}>
      {number && <div style={numberBox}>{number}</div>}
      {gap && <div style={{ marginLeft: "39px ", backgroundColor: "" }}></div>}
      <div style={{ display: "flex", gap: "13px", flexDirection: "column" }}>
        {<p className="textTitleParms">{verifyNameType()}</p>}

        {type !== "description" &&
          type !== "price" &&
          type !== "price" &&
          type !== "dateGenerality" &&
          type !== "qtdGenerality" &&
          type !== "code" &&
          type !== "qtdBox" &&
          type !== "kpi" &&
          type !== "metric" &&
          type !== "date" &&
          type !== "conversion" && (
            <select
              defaultValue={valueInput}
              onBlur={onBlur}
              onChange={onChange}
              style={{
                ...selectBox,
                backgroundImage: `url('data:image/svg+xml;charset=utf-8,${arrowSvgUri}')`,
              }}
            >
              {type === "process" && (
                <>
                  <option value="">Selecione</option>
                  <option value="Recebimento">Recebimento</option>
                  <option value="Saida">Saída</option>
                  <option value="Urgente"> Urgente</option>
                  <option value="Urgente"> Inventário</option>
                </>
              )}
              {type === "time" && unit === "hours" && (
                <>
                  <option value="">Selecione</option>
                  {Array.from({ length: 24 }, (_, index) => (
                    <option key={index + 1} value={`${index + 1}:00`}>
                      {index + 1}:00
                    </option>
                  ))}
                </>
              )}
              {type === "time" && unit === "days" && (
                <>
                  <option value="">Selecione</option>
                  {Array.from({ length: 30 }, (_, index) => (
                    <option key={index + 1} value={`${index + 1}`}>
                      {index + 1} dias
                    </option>
                  ))}
                </>
              )}
              {type === "typeMetric" && (
                <>
                  <option value="default">Selecione</option>
                  <option value=">=">Maior ou igual</option>
                  <option value="<=">Menor ou igual</option>
                  <option value=">">Maior</option>
                  <option value="<">Menor</option>
                  <option value="==">Igual</option>
                </>
              )}
              {type === "hourInitial" && (
                <>
                  <option value="default">Selecione</option>
                  <option value={1}>01:00</option>
                  <option value={2}>02:00</option>
                  <option value={3}>03:00</option>
                  <option value={4}>04:00</option>
                  <option value={5}>05:00</option>
                  <option value={6}>06:00</option>
                  <option value={7}>07:00</option>
                  <option value={8}>08:00</option>
                  <option value={9}>09:00</option>
                  <option value={10}>10:00</option>
                  <option value={11}>11:00</option>
                  <option value={12}>12:00</option>
                  <option value={13}>13:00</option>
                  <option value={14}>14:00</option>
                  <option value={15}>15:00</option>
                  <option value={16}>16:00</option>
                  <option value={17}>17:00</option>
                  <option value={18}>18:00</option>
                  <option value={19}>19:00</option>
                  <option value={20}>20:00</option>
                  <option value={21}>21:00</option>
                  <option value={22}>22:00</option>
                  <option value={23}>23:00</option>
                </>
              )}
              {type === "hourFinished" && (
                <>
                  <option value="default">Selecione</option>
                  <option value={1}>01:00</option>
                  <option value={2}>02:00</option>
                  <option value={3}>03:00</option>
                  <option value={4}>04:00</option>
                  <option value={5}>05:00</option>
                  <option value={6}>06:00</option>
                  <option value={7}>07:00</option>
                  <option value={8}>08:00</option>
                  <option value={9}>09:00</option>
                  <option value={10}>10:00</option>
                  <option value={11}>11:00</option>
                  <option value={12}>12:00</option>
                  <option value={13}>13:00</option>
                  <option value={14}>14:00</option>
                  <option value={15}>15:00</option>
                  <option value={16}>16:00</option>
                  <option value={17}>17:00</option>
                  <option value={18}>18:00</option>
                  <option value={19}>19:00</option>
                  <option value={20}>20:00</option>
                  <option value={21}>21:00</option>
                  <option value={22}>22:00</option>
                  <option value={23}>23:00</option>
                </>
              )}
              {type === "constancy" && (
                <>
                  <option value="default">Selecione</option>
                  <option value="All month">Todos os meses</option>
                  <option value="byPeriod">Por período</option>
                </>
              )}
              {type === "unit" && optionSelect === "Armazenagem" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="volume_m3 - sum: Volume m³">Volume m³</option>
                  <option value="peso_bruto - sum: Peso bruto">
                    Peso bruto
                  </option>
                  <option value="peso_liquido - sum: Peso liquido">
                    Peso liquido
                  </option>
                  <option value="qtd_palete_fisico - sum: Palete fisico">
                    Palete fisico
                  </option>
                  <option value="qtd_enderecos - sum linha: Endereços">
                    Endereços
                  </option>
                  <option value="qtd_saldo - sum: Saldo">Saldo</option>
                </>
              )}
              {type === "unit" && optionSelect === "Recebimento" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="qtd_item - sum: Quantidade de item">
                    Quantidade de item
                  </option>
                  <option value="qtd_palete_fisico - sum: Pallet">
                    Pallet
                  </option>
                  <option value="seq_aviso_recebto - sum: Linhas">
                    Linhas
                  </option>
                  <option value="peso_bruto - sum: Peso bruto">
                    Peso bruto
                  </option>
                  <option value="peso_liquido - sum: Peso liquido">
                    Peso liquido
                  </option>
                  <option value="volume_m3 - sum: Volume m³">Volume m³</option>
                </>
              )}
              {type === "unit" && optionSelect === "Expedição" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="qtd_itens - sum: Quantidade de item">
                    Quantidade de item
                  </option>
                  <option value="trans_solicitacao - sum linhas: Notas Fiscais Expedidas">
                    Notas Fiscais Expedidas
                  </option>
                  <option value="qtd_palete_fisico - sum: Pallet">
                    Pallet
                  </option>
                  <option value="qtd_sequencias - sum: Linhas">Linhas</option>
                  <option value="peso_bruto - sum: Peso bruto">
                    Peso bruto
                  </option>
                  <option value="peso_liquido - sum: Peso liquido">
                    Peso liquido
                  </option>
                  <option value="volume_m3 - sum: Volume m³">Volume m³</option>
                </>
              )}
              {type === "unit" && optionSelect === "Seguro de Armazenagem" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="qtd_itens - sum: Quantidade de item">
                    Quantidade de item
                  </option>
                </>
              )}
              {type === "titleGenerality" && (
                <>
                  <option value="">Selecione</option>
                  <option value="Recebimento">
                    Hora extra/homem - Dias úteis
                  </option>
                </>
              )}
              {type === "typeCalculation" && (
                <>
                  <option value={valueInput}>{valueInput}</option>
                  <option value="">Selecione</option>
                  <option value="Soma">Soma</option>
                  {options !== "Recebimento" && (
                    <option value="Pico">Pico</option>
                  )}
                </>
              )}
              {type === "typeStructure" &&
                typeStructureAll.map((item, i) => {
                  if (
                    i == 0 &&
                    options !== "Recebimento" &&
                    i == 0 &&
                    options !== "Expedição"
                  ) {
                    return (
                      <>
                        <option value={valueInput}>{valueInput}</option>
                        <option value="default">Selecione</option>;
                        <option value={item.des_tip_estrutura}>
                          {item.des_tip_estrutura}
                        </option>
                        ;
                      </>
                    );
                  } else if (
                    options !== "Recebimento" &&
                    options !== "Expedição"
                  ) {
                    return (
                      <>
                        <option value={item.des_tip_estrutura}>
                          {item.des_tip_estrutura}
                        </option>
                      </>
                    );
                  } else if (
                    (i == 0 && options === "Recebimento") ||
                    (i == 0 && options === "Expedição")
                  ) {
                    return <option value="default">Não se aplica</option>;
                  }
                })}
              {type === "typeCategoryContractual" && (
                <>
                  <option value="default">Selecione</option>
                  <option value="days">Dias</option>
                  <option value="hours">Horas</option>
                </>
              )}
              {type === "typeCategory" && typeCategoryAll?.length ? (
                typeCategoryAll.map((item, i) => {
                  if (i == 0) {
                    return (
                      <>
                        <option value={valueInput}>{valueInput}</option>
                        <option value="default">Selecione</option>;
                        <option value={item.des_categoria}>
                          {item.des_categoria}
                        </option>
                        ;
                      </>
                    );
                  }
                  return (
                    <option value={item.des_categoria}>
                      {item.des_categoria}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </select>
          )}
        {type === "description" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Descrição"
            style={inputBox}
          />
        )}
        {type === "code" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira o sku"
            style={inputBox}
          />
        )}
        {type === "kpi" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira a kpi"
            style={inputBox}
          />
        )}
        {type === "qtdBox" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira a quantidade"
            style={inputBox}
          />
        )}
        {type === "conversion" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira a conversão"
            style={inputBox}
          />
        )}
        {type === "metric" && (
          <InputNumber
            className="primereact"
            onBlur={onBlur}
            onChange={onChange}
            value={valueInput}
            placeholder="Insira a métrica"
            style={inputBox}
            suffix="%"
            decimalSeparator=","
            minFractionDigits={2}
          />
        )}
        {type === "date" && (
          <div
            style={inputBox}
            className="primereact-date-calendar card flex justify-content-center"
          >
            <Calendar
              value={valueInputState}
              onChange={onChange}
              view="month"
              dateFormat="mm/yy"
              placeholder="Mês e ano"
            />
          </div>
        )}
        {type === "price" && (
          <input
            onBlur={(e) => {
              setTypeNumber(false);
              onBlur(e);
              formatCoin("real", valueInput, setValueInputState);
            }}
            onChange={onChange}
            onClick={() => {
              setTypeNumber(true);
              setValueInputState(valueInput);
            }}
            value={valueInputState}
            placeholder="Preço"
            type={typeNumber ? "number" : "string"}
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "dateGenerality" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Preço"
            type="date"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "qtdGenerality" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Quantidade"
            type="number"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "justification" && (
          <Textarea
            width={width}
            height={height}
            value={valueInputState}
            placeholder="Descrição da justificativa"
            type="number"
            style={{ inputBox }}
          />
        )}
      </div>
    </div>
  );
};

export default BoxParameterSelect;
