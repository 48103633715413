import { useContext, useEffect, useState } from "react";
import closeImg from "../../../images/svg/closeIcon.svg";
import InputMask from "react-input-mask";
import Textarea from "./Textarea.js";
import Button from "./Button";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters.js";
import { toast } from "react-toastify";

const ModalJustificationDetailsDashboard = ({
  id,
  closeModal,
  searchByMonth,
  teste,
}) => {
  const {
    createdJustification,
    getByIdJustification,
    detailsJustification,
    setDetailsJustification,
  } = useContext(GraphsAndParametersContext);

  const [typeTime, setTypeTime] = useState("");
  const [time, setTime] = useState("");
  const [justification, setJustification] = useState("");

  const modalContainerStyles = {
    width: "470px",

    backgroundColor: "#fff",
    position: "absolute",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
  };

  const headerStyles = {
    display: "flex",
    gap: "15px",
    borderBottom: "1px solid #d7d7d7",
    padding: "15px 20px 10px",
  };

  const closeIconStyles = {
    width: "12px",
    cursor: "pointer",
  };

  const contentStyles = {
    padding: "15px 20px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  };

  const selectContainerStyles = {
    display: "flex",
    gap: "10px",
  };

  const inputContainerStyles = {
    display: "flex",
    gap: "10px",
  };

  const validationAllowance = () => {
    const allNumbers = time.split(":").every((item) => {
      // Divide a string em caracteres e verifica se todos os caracteres são números
      return item.split("").every((char) => !isNaN(char));
    });

    if (typeTime === "default" || typeTime === "") {
      toast.error("Selecione um tipo de tempo", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    } else if (time === "") {
      toast.error("Campo de tempo é obrigaório", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    } else if (typeTime === "hours" && !allNumbers) {
      toast.error("Preencha os 6 digitos", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    } else if (!justification) {
      toast.error("Campo de justificativa é obrigatório", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    } else if (String(justification).length < 3) {
      toast.error("Justicativa muito curta", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return false;
    } else {
      return true;
    }
  };

  const createdData = async () => {
    const data = {
      allowance: time.split("_")[0],
      justification: justification,
    };

    const valitedAllowance = validationAllowance();
    if (valitedAllowance) {
      await createdJustification(data, id, closeModal);
      await searchByMonth();
    }
  };

  useEffect(() => {
    if (detailsJustification) {
      setTypeTime(detailsJustification.typeTime);
      setTime(detailsJustification.time);
      setJustification(detailsJustification.justification);
    }
  }, [detailsJustification]);

  useEffect(() => {
    console.log(teste);
    setTypeTime("");
    setTime("");
    setJustification("");
    if (id) {
      getByIdJustification(id);
    }
  }, [id]);

  return (
    <div style={modalContainerStyles}>
      <div style={headerStyles}>
        <img
          src={closeImg}
          style={closeIconStyles}
          onClick={closeModal}
        />
        <p className="textTitleJustificationDescription">
          Justificativa de abono
        </p>
      </div>

      <div style={contentStyles}>
        <div style={{ ...selectContainerStyles, gap: "26px" }}>
          <label
            htmlFor="typeTime"
            className="textLabelJustificationDescription"
          >
            Tipo de tempo:
          </label>
          <select
            id="typeTime"
            className="textSelectOptionsJustificationDescription"
            onChange={(e) => setTypeTime(e.target.value)}
            defaultValue={typeTime}
            style={{
              outline: "none",
              border: "none",
              borderBottom: "1px solid #d7d7d7",
            }}
          >
            <option value="default">Selecione</option>
            <option value="hours">Horas</option>
            <option value="days">Dias</option>
          </select>
        </div>

        <div style={inputContainerStyles}>
          <label
            htmlFor="time"
            className="textLabelJustificationDescription"
          >
            Tempo de abono:
          </label>
          <InputMask
            style={{
              border: "none",
              borderBottom: "1px solid #d7d7d7",
              outline: "none",
              width: "100px",
            }}
            mask={typeTime === "days" ? "999" : "99:99:99"}
            placeholder="Tempo"
            value={time}
            id="time"
            onChange={(e) => setTime(e.target.value)}
          />
        </div>

        <Textarea
          className="textSelectOptionsJustificationDescription"
          width={"100%"}
          height={"130px"}
          value={justification}
          onChange={(e) => setJustification(e.target.value)}
          placeholder="Descrição da justificativa"
          type="number"
          borderRadius={"6px"}
        />

        <Button
          padding={"0 20px"}
          height="35px"
          background={"var(--color-primary)"}
          borderRadius="8px"
          marginTop="0"
          name={"Enviar"}
          color={"#fff"}
          border="none"
          onClick={createdData}
        />
      </div>
    </div>
  );
};

export default ModalJustificationDetailsDashboard;
