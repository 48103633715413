import { useNavigate } from "react-router-dom";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import HeaderDashboard from "../components/HeaderDashboard";
import Sidebar from "../../../components/Sidebar";
import ContainerInputSearchAndButton from "../components/ContainerInputSearchAndButton";
import CardDetails from "../components/CardDetails";
import CardTablesStorage from "../components/CardTablesStorage";
import api from "../../../services/api";
import { useEffect, useState } from "react";
import Loading from "../components/Loading";
import createExcelFile from "../../../utils/craeteExcelFile";

const DetailsStorage = () => {
  const [loading, setLoading] = useState(true);
  const [storageAll, setStorageAll] = useState([]);
  const [storageQtd, setStorageQtd] = useState(0);
  const [storagePrice, setStoragePrice] = useState(0);

  const [storageUOM, setStorageUOM] = useState(0);
  const [storageDescription, setStorageDescription] = useState(0);
  const [storagePriceUnit, setStoragePriceUnit] = useState(0);

  const navigate = useNavigate();
  const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
  const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
  const month = localStorage.getItem("@XPM_CLIENT_MONTH:");

  const getInfo = async () => {
    try {
      const storage = await api.get(`/storage/details/${clientCnpj}/${year}/${month}`);

      setStorageAll(storage.data);
      console.log(storage.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getStorage = async () => {
    if (storageAll.length > 0) {
      const sum = storageAll.reduce((acc, curr) => {
        return acc + curr.sum;
      }, 0);

      setStorageQtd(storageAll.length);
      setStoragePrice(sum);
      setStorageUOM(storageAll[0].UOM);
      setStorageDescription(storageAll[0].description);
    } else {
      setStorageQtd(0);
      setStoragePrice(0);
      setStorageUOM(0);
      setStorageDescription(0);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (storageAll?.length) {
      getStorage();
      setLoading(false);
    }
  }, [storageAll]);

  // STYLES
  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    marginBottom: "50px",
  };

  const boxCard = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  };

  return (
    <ThowColumLayout
      page={"DashboardAdm"}
      colum1={<Sidebar page="Dashboard" />}
      colum2={
        <div style={container}>
          <HeaderDashboard
            back={true}
            name="Fatura armazenagem"
            adm={true}
            onClick={() => navigate("/dashboard/company")}
          />
          <div style={{ margin: "42px auto 0" }} className="containerBox">
            <ContainerInputSearchAndButton onClick={() => createExcelFile(storageAll.flatMap(obj => obj.values), `${'Armazenagem'} ${year}-${month}`, `${'Armazenagem'} ${year}-${month}.xlsx`)} />

            <div
              style={{
                display: "flex",
                marginTop: "33px",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <CardDetails
                marginLeft="120px"
                paddingLeft={"50px"}
                content={storageDescription}
                quantity={storageQtd}
                UOM={storageUOM}
                priceUnit={storagePriceUnit}
                total={storagePrice}
                totalValue={"R$ 11.980,21"}
              />
              {
                loading ? (
                  <Loading />
                ) : (
                  <CardTablesStorage storage={storageAll} />
                )
              }              
            </div>

            <div style={boxCard}></div>
          </div>
        </div>
      }
    />
  );
};

export default DetailsStorage;
