import React, { useContext, useEffect, useState } from "react";
import add from "../../../images/svg/add.svg";
import PlaceConfigCard from "./PlaceConfigCard";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";

const ScheduleConfig = ({
    items,
    // setItems,
    // branches,
    currentBranch,
    // setCurrentBranch,
    filteredPlaces,
    newPlaces,
    setFilteredPlaces,
    setNewPlaces,
    // setNewChecklists,
    // editedPlaces,
    // setEditedPlaces,
}) => {
    const {
        getBrachs,
        branchs,
        // setDateConfig,
        getLocationsByIdBranchLimit,
        locationsDetails,
        updateLocation,
        // createLocation,
    } = useContext(SchedulesContext);
    const [selectedBranch, setSelectedBranch] = useState("");

    const stylesLabelContainer = {
        marginTop: 20,
        marginBottom: 20,
        width: "100%",
    };

    const stylesSelect = {
        width: "100%",
        border: "1px solid #D7D7D7",
        padding: 10,
        borderRadius: 10,
        background: "none",
        marginTop: 10,
        outline: "none",
    };

    const getPlaces = () => {
        const filteredItems = items.filter(
            (item) => item.branchId === currentBranch.id
        );

        setFilteredPlaces(filteredItems);
    };

    const handleEdit = (values, id, branchId) => {
        try {
            updateLocation(values, id, branchId);
        } catch (error) {
            console.log(error);
        }
    };

    // const handleCreate = (data, id) => {
    //     try {
    //         createLocation(data, id);
    //         setNameState(undefined);
    //         setTypeState(undefined);
    //         setTimeState(undefined);
    //         setStart_timeState(undefined);
    //         setEnd_timeState(undefined);
    //         setLunch_start_timeState(undefined);
    //         setLunch_end_timeState(undefined);
    //         setWeekDays([]);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const handleChange = ({ target }, index) => {
        const { value } = target;
        getLocationsByIdBranchLimit(value);
        setSelectedBranch(value);
    };

    const editNewPlace = ({ target }, index) => {
        const { name, value } = target;
        if (name === "lunch") {
            const newArray = [...newPlaces];
            const editedPlace = newArray[index - filteredPlaces.length];
            editedPlace.hasLunch = !editedPlace.hasLunch;
            setNewPlaces(newArray);
        } else {
            const newArray = [...newPlaces];
            const editedPlace = newArray[index - filteredPlaces.length];
            editedPlace[name] = value;
            setNewPlaces(newArray);
        }
    };

    const addNewPlace = () => {
        const newArray = [...newPlaces];
        const newObj = {
            local_name: "",
            type: "",
            time: "",
            start_time: "",
            end_time: "",
            lunch_start_time: "",
            lunch_end_time: "",
            week_days: [],
        };

        newArray.push(newObj);
        setNewPlaces(newArray);
    };

    useEffect(() => {
        getPlaces();
    }, [currentBranch]);

    useEffect(() => {
        getBrachs();
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "90%",
            }}
        >
            <label
                className="textFilialModalConfig"
                style={stylesLabelContainer}
            >
                Filial
                <select
                    name="branch"
                    style={stylesSelect}
                    onChange={(e) => handleChange(e)}
                    className="textOptionsSchedules"
                    value={selectedBranch}
                >
                    <option key={"default"} value={"default"}>
                        Selecione
                    </option>
                    {branchs.map((e, index) => (
                        <option key={`${e.name}-${index}`} value={e.id}>
                            {e.branch_name}
                        </option>
                    ))}
                </select>
            </label>
            <p className="textLocalModalConfig">Locais e horários</p>

            {locationsDetails?.map((place, index) => (
                <PlaceConfigCard
                    key={place.local.id}
                    place={place.local}
                    index={index}
                    newItem={false}
                    handleEdit={handleEdit}
                    selectedBranch={selectedBranch}
                />
            ))}

            {newPlaces
                ? newPlaces.map((place, index) => (
                      <PlaceConfigCard
                          key={index + filteredPlaces.length}
                          place={place}
                          index={locationsDetails.length}
                          handleChange={editNewPlace}
                        //   handleCreate={handleCreate}
                          newItem={true}
                          selectedBranch={selectedBranch}
                          setNewPlaces={setNewPlaces}
                      />
                  ))
                : null}

            <div
                style={{
                    display: "flex",
                    gap: 10,
                    color: "#9966CC",
                    cursor: "pointer",
                }}
                onClick={() => addNewPlace()}
            >
                <img src={add} alt="adicionar lugar" />
                <p>Adicionar novo local</p>
            </div>
        </div>
    );
};

export default ScheduleConfig;
