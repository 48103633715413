import React, { useState } from "react";
import HeaderDetails from "./HeaderDetails";

const ScheduleDetails = ({ upArrow, downArrow, openMenu, schedule }) => {
    const [isOpen, setIsOpen] = useState(false);

    const stylesContaier = {
        width: "100%",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 5,
        border: "1px solid #D7D7D7",
        borderRadius: 10,
        maxHeight: 370,
        backgroundColor: "#fff",
        marginBottom: 10,
    };

    const stylesDetailsCOntainer = {
        overflowY: "scroll",
        height: 270,
        marginTop: 20,
        marginBottom: 10,
        backgroundColor: "#fff",
    };

    const stylesCheckList = {
        display: "flex",
        flexDirection: "column",
        gap: 20,
        paddingLeft: 0,
    };

    return (
        <section style={stylesContaier}>
            <HeaderDetails
                schedule={schedule}
                arrow={isOpen ? upArrow : downArrow}
                openMenu={openMenu}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
            />

            {isOpen ? (
                <div style={stylesDetailsCOntainer} className="scrollLower">
                    <ul style={stylesCheckList}>
                        {/* {checklist.map((e, i) => (
                            <li key={i} style={{ display: "flex" }}>
                                {e.status ? (
                                    <img
                                        src={checkOn}
                                        alt="check-positive"
                                        style={{ marginRight: 20 }}
                                        onClick={() =>
                                            updateChecklist(i, item.id)
                                        }
                                    />
                                ) : (
                                    <img
                                        src={checkOff}
                                        style={{ marginRight: 20 }}
                                        alt="check-negative"
                                        onClick={() =>
                                            updateChecklist(i, item.id)
                                        }
                                    />
                                )}
                                {e.description}
                            </li>
                        ))} */}
                    </ul>
                    <div>
                        <p>Em desenvolvimento...</p>
                    </div>
                </div>
            ) : null}
        </section>
    );
};

export default ScheduleDetails;
