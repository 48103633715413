import { useEffect, useState } from "react";

const DaysOfWeekCard = ({ weekDays, setWeekDays }) => {
    const daysOfWeek = [
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
        "Domingo",
    ];

    const parsedWeekDays = weekDays.length > 0 ? JSON.parse(weekDays) : [];

    const initialSelectedDays = daysOfWeek.map((day) =>
        parsedWeekDays.includes(day.substring(0, 3).toLowerCase())
            ? day.substring(0, 3).toLowerCase()
            : null
    );

    const [selectedDays, setSelectedDays] = useState(initialSelectedDays);

    const dayOfWeekContainer = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "10px",
        width: "100%",
        overflowX: "scroll",
        padding: "0 0 10px",
    };

    const lineBorder = {
        border: "1px solid var(--color-primary)",
        padding: "8px 15px ",
        borderRadius: "60px",
        cursor: "pointer",
    };

    const onSelectOrRemove = (item) => {
        const abbr = item.substring(0, 3).toLowerCase();
        const isSelected = selectedDays.includes(abbr);

        let updatedSelectedDays;
        if (isSelected)
            updatedSelectedDays = selectedDays.filter((day) => day !== abbr);
        else {
            updatedSelectedDays = selectedDays.filter((day) => day !== null);
            updatedSelectedDays.push(abbr);
        }

        setSelectedDays(updatedSelectedDays);
    };

    useEffect(() => {
        const filteredSelectedDays = selectedDays.filter((day) => day !== null);
        setWeekDays(JSON.stringify(filteredSelectedDays));
    }, [selectedDays, setWeekDays]);

    return (
        <div>
            <h2 className="textTitleWeek">Dias das semanas disponíveis</h2>
            <ul
                className="containerLiWeek scrollLowerUp"
                style={dayOfWeekContainer}
            >
                {daysOfWeek.map((item) => {
                    const abbr = item.substring(0, 3).toLowerCase();
                    const isSelected = selectedDays.includes(abbr);
                    return (
                        <li
                            className={
                                isSelected
                                    ? "activeLiWeek"
                                    : "desactivatedLiWeek"
                            }
                            key={abbr}
                            style={lineBorder}
                            onClick={() => onSelectOrRemove(item)}
                        >
                            {item}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default DaysOfWeekCard;
