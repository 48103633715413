import { useContext, useEffect, useState } from "react";
import ContentChat from "./ContentChat";
import SendMessage from "./SendMessage";
import moment from "moment";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";
import updateIconImg from "../../../images/png/atualizar.png";

const CommentYearDashboard = () => {
  const { getCommentsAll, hoursUpdate, setHoursUpdate, process, setProcess } =
    useContext(GraphsAndParametersContext);
  const [maximize, setMaximize] = useState(false);

  const getUpdateMonth = (value) => {
    localStorage.setItem("@XPM_CLIENT_MONTH_COMMENT:", value);
    const momentHours = moment().format("DD/MM/YYYY - HH:mm:ss");
    // setProcess(value);
    console.log(momentHours);
    setHoursUpdate(momentHours);
    getCommentsAll();
  };

  const getUpdateProcess = (value) => {
    localStorage.setItem("@XPM_CLIENT_PROCESS_COMMENT:", value);
    setProcess(value);
    getCommentsAll();
  };

  useEffect(() => {
    const momentDate = moment().month() + 1;
    const momentHours = moment().format("DD/MM/YYYY - HH:mm:ss");
    localStorage.setItem("@XPM_CLIENT_MONTH_COMMENT:", momentDate);
    setHoursUpdate(momentHours);
  }, []);

  const update = () => {
    const momentHours = moment().format("DD/MM/YYYY - HH:mm:ss");
    setHoursUpdate(momentHours);
    getCommentsAll();
  };

  return (
    <div
      style={{
        width: "100%",
        background: "#fff",
        borderRadius: "10px",
        border: "1px solid #d7d7d7",
      }}
    >
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid #d7d7d7",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
        }}
      >
        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <h3 style={{ transform: "translateY(4px)", fontSize: "16px" }}>
            COMENTÁRIOS
          </h3>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img
              src={updateIconImg}
              style={{ cursor: "pointer", width: "20px", objectFit: "contain" }}
              className="hoverRotation"
              onClick={() => update()}
            />
            <p style={{ fontSize: "11px", fontWeight: "bold" }}>
              {hoursUpdate}
            </p>
          </div>

          <div
            onClick={() => setMaximize(!maximize)}
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              backgroundColor: "#9966CC",
              padding: "3px 0px",
              border: "1px solid transparent",
              borderRadius: "10px",
              cursor: "pointer",
              width: "120px",
              justifyContent: "center",
            }}
          >
            <p style={{ color: "#fff", fontSize: "13px" }}>
              {maximize ? "-" : "+"}
            </p>
            <p style={{ color: "#fff", fontSize: "13px" }}>
              {maximize ? "Minimizar" : "Maximizar"}
            </p>
          </div>
        </div>

        <div
          style={{
            border: "1px solid #d7d7d7",
            borderRadius: "10px",
            padding: "5px",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div>Processo</div>
          <select
            onChange={(e) => getUpdateProcess(e.target.value)}
            style={{ border: "none", outline: "none" }}
          >
            <option value={"Recebimento"}>Recebimento</option>
            <option value={"Expedição"}>Expedição</option>
            <option value={"Urgência"}>Urgência</option>
          </select>
        </div>

        <div
          style={{
            border: "1px solid #d7d7d7",
            borderRadius: "10px",
            padding: "5px",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div>Mês:</div>
          <select
            defaultValue={localStorage.getItem("@XPM_CLIENT_MONTH_COMMENT:")}
            onChange={(e) => getUpdateMonth(e.target.value)}
            style={{ border: "none", outline: "none" }}
          >
            <option value={1}>Janeiro</option>
            <option value={2}>Fevereiro</option>
            <option value={3}>Março</option>
            <option value={4}>Abril</option>
            <option value={5}>Maio</option>
            <option value={6}>Junho</option>
            <option value={7}>Julho</option>
            <option value={8}>Agosto</option>
            <option value={9}>Setembro</option>
            <option value={10}>Outubro</option>
            <option value={11}>Novembro</option>
            <option value={12}>Dezembro</option>
          </select>
        </div>
      </div>

      <div
        style={{
          padding: "10px",
          width: "100%",
          height: maximize ? "600px" : "300px",
        }}
      >
        <ContentChat />
      </div>

      <div>
        <SendMessage />
      </div>
    </div>
  );
};

export default CommentYearDashboard;
