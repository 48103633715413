import uploadIcon from "../../../images/svg/uploadIcon.svg";
import { read, utils } from "xlsx";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";
import eye from "../../../images/svg/eyeOfViewing.svg";

const Hollidays = ({ openHollidays }) => {
  const { createdHolidays } = useContext(GraphsAndParametersContext);
  const [stock, setStock] = useState([]);

  const handleFile = async (file) => {
    if (
      file.type !=
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toast.error("Formato de arquivo inválido!", {
        autoClose: 2000,
      });
      return false;
    }

    const data = await file.arrayBuffer();

    const workbook = read(data);

    const workSheet = workbook.Sheets[workbook.SheetNames[0]];
    const worksheet = utils.sheet_to_json(workSheet);

    setStock(worksheet);
  };

  const createData = async () => {
    const dataCre = {
      holidays: stock.map((elem) => {
        return {
          holiday: elem["Feriados"],
          resons: elem["Motivo do feriado"],
        };
      }),
    };

    console.log(dataCre);
  };

  useEffect(() => {
    if (stock.length > 0) {
      createData();
    }
  }, [stock]);
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      <label
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          border: "2px solid #d7d7d7",
          padding: "11px 20px",
          borderRadius: "60px",
          cursor: "pointer",
          backgroundColor: "#fff",
        }}
      >
        <img src={uploadIcon} />
        <p className="textClientInfoHeader">Upload de feriados</p>
        <input
          type="file"
          id="file"
          onChange={(e) => handleFile(e.target.files[0])}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          name="file"
          style={{ display: "none" }}
        />
      </label>

      <div
        onClick={openHollidays}
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          border: "2px solid #d7d7d7",
          padding: "12px 12px",
          borderRadius: "60px",
          cursor: "pointer",
          backgroundColor: "#fff",
        }}
      >
        <img src={eye} />
      </div>
    </div>
  );
};

export default Hollidays;
