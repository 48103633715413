import React, { useEffect, useState } from 'react';

const CardValueInput = ({
  icon,
  title,
  value,
  hasInput,
  onChange,
  afterIcon,
  result,
  type,
}) => {

  const [isDivVisible, setDivVisibility] = useState(false);
  const [formattedValue, setFormattedValue] = useState('');

  const stylesCardContainerNormal = {
    position: 'relative',
    width: '100%',
    minWidth: 200,
    height: 110,
    border: '1px solid #d7d7d7',
    borderRadius: '10px',
    padding: '17px',
    display: 'flex',
    flexDirection: 'column',
    gap: '17px',
    backgroundColor: '#fff',      
  };

  const stylesCardContainerResult = {
    position: 'relative',
    width: '100%',
    height: 110,
    border: '1px solid #9966CC',
    borderRadius: '10px',
    padding: '17px',
    display: 'flex',
    flexDirection: 'column',
    gap: '17px',
    backgroundColor: '#FCF9FF',
    color: '#9966CC'
  };

  const helperContainer = {
    position: 'absolute',
    top: 40, // Posiciona a div abaixo do afterIcon
    left: 125,
    backgroundColor: '#F9F3FF',
    padding: '0px 10px',
    border: '1px solid #9966CC',
    borderRadius: 10,
    color: '#9966CC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 1,
  };

  const helperText = {
    width: 150,
    borderBottom: '1px solid #9966CC',
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 400,
    fontSize: 11,
  };

  const helperExemple = {
    fontWeight: 500,
    fontSize: 12
  };

  const styleInput = {
    border: '1px solid #D7D7D7',
    borderRadius: 10,
    height: 32,
    padding: 10,
    width: '100%',
  };

  const getContainerStyles = () => {
    return result ? stylesCardContainerResult : stylesCardContainerNormal;
  };

  const getFormattedValue = (title, value) => {
    if (value && title === 'Percentual') {
      console.log('porcentagem', value);
      return (`${value}%`);
    } else if(value && title !== 'Percentual'){
      return (`R$ ${value}`);
    }
  };

  const formatCurrency = (formatted) => {
    const cleanString = formatted
    .replace('R$', '')
    .replaceAll(' ', '')
    .replace(',', '.')
    .replace('%', '');
    console.log('clean',cleanString)
    const newValue = getFormattedValue(title, cleanString);
    setFormattedValue(newValue);
  };

  const changeState = (formatted) => {
    const cleanString = formatted.replace('R$', '').replace(',', '.').replace('%', '').replaceAll(' ', '');
    if (title !== 'Percentual') {
      console.log('mudar valor para: ', cleanString);
      onChange(cleanString);
    } else {
      // onChange((Number(cleanString) / 100).toFixed(2));
      onChange(cleanString);
    }
  };
  
  useEffect(() => {
    if (value) {
      setFormattedValue(getFormattedValue(title, value));
    } else {
      setFormattedValue('');
    }
  }, [value])

  return (
    <div
      style={getContainerStyles()}
    >
      <div
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        {
          icon ? (<img src={icon} />) : null
        }
        <p className="textTitleTotalCard">{title}</p>
        {afterIcon && (
          <img
            src={afterIcon}
            alt="afterIcon"
            onMouseEnter={() => setDivVisibility(true)}
            onMouseLeave={() => setDivVisibility(false)}
          />
        )}
      </div>

      {isDivVisible && (
        <div
          style={helperContainer}
        >
          <div style={helperText}>
            <p>Valor Fixo</p>
            <p style={helperExemple}>R$ 100.000,00</p>
          </div>
          <div style={helperText}>
            <p>Filial Fiscal - Virtual</p>
            <p style={helperExemple}>R$ 5.000,00</p>
          </div>
          <div style={helperText}>
            <p>Seguro Armazenagem</p>
            <p style={helperExemple}>R$ 40.000,00</p>
          </div>
        </div>
      )}


      {!hasInput ? (
        <div>
          <p className="textNameCompanyCard">
            {
              (value && (type === undefined)) ? (
                Number(value).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              ) : 'R$ 0,00'
            }
            {
              (value && (type === 'porcentage')) ? (
                `${value * 100}%`
              ) : null
            }
          </p>
        </div>
      ) : (
        <div>
          <input
            type="text"
            id="money"
            placeholder={title === 'Percentual' ? '0 %' : 'R$ 0,00'}
            value={formattedValue || ""}
            style={styleInput}
            onChange={(e) => formatCurrency(e.target.value)}
            onBlur={(e) => changeState(e.target.value)}
          />
        </div>
      )}
    </div>
  );
};

export default CardValueInput;
