import Button from "./Button";
import CardCompanyDetails from "./CardCompanyDetails";
import CardDateYear from "./CardDateYear";
import CardValueTotal from "./CardValueTotal";
import * as moment from "moment";
import "moment/locale/pt-br";
import { useContext, useEffect, useState } from "react";
import ConfigParameter from "./ConfigParameter";
import { DashContext } from "../../../context/Dashboard/DashContext";
import helpeCircle from '../../../images/svg/help-circle.svg';
// import mockClients from "./mocks/mockClients";
import mockBillingByMonth from "./mocks/mockBillingByMonth";
import CardValueInput from "./CardValueInput";
import CardValuePorcentage from "./CardValuePorcentage";
import CardValueTax from "./CardValueTax";
import api from "../../../services/api";
import { toast } from "react-toastify";

const CardParameters = ({
  dateNotFormactApi,
  setDateNotFormactApi,
  btnIsActive,
  configureParameter,
  setConfigureParameter,
  configureGeneralityOn,
  update,
  billingType,
  setBillingType,
  clientsSelect,
  setClientsSelect,
  discount,
  setDiscount,
  fixedBilling,
  setFixedBilling,
  billingTaxed,
  setBillingTaxed,
  minBillingTaxed,
  setMinBillingTaxed,
  tax,
  setTax,
  minTax,
  setMinTax,
  setMinimumBillingMenu,
  simulated,
  generalidades,
  arrayOfParams,
  taxesMenu,
  setTaxesMenu,
}) => {
  const {
    updateFunction,
    billing,
    setBilling,
    waitingConference,
    awaitingPayment,
    awaitingPaymentVirtual,
    paymentConcluid,
    getDiscount,
    getTaxes,
    getVariables,
    minimumBilling,
    setMinimumBilling,
    variables,
    setVariables,
    porcent,
    setPorcent,
    getPorcent,
    getManualBilling,
    manualBilling,
    setManualBilling,
    updateManualBilling,
    fixedMonthlyValue,
    setFixedMonthlyValue,
    getGeneralities,
    getFixedGeneralities,
    permission,
    taxesArray,
  } = useContext(DashContext);
  const [dateMoment, setDateMoment] = useState('');
  const [dateNotFormact, setDateNotFormact] = useState('');
  const [amount, setAmount] = useState();
  const [nfValue, setNfValue] = useState(0);
  const [biggestDateLimit, setBiggestDateLimit] = useState(false);
  const [shorttestDateLimit, setShorttestDateLimit] = useState(false);

  useEffect(() => {
    const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
    const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
    if (year && month) {
      const numericYear = parseInt(year, 10);
      const numericMonth = parseInt(month, 10);
      setDateMoment(moment().set({ month: numericMonth - 1, year: numericYear }).format("MMMM _ YYYY"));
      setDateNotFormact(moment().set({ month: numericMonth - 1, year: numericYear }).format());
    } else {
      setDateMoment(moment().format("MMMM _ YYYY"));
      setDateNotFormact(moment());
    }
  }, [])

  const boxCards = {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const addMonth = () => {
    const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
    const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
    const biggestDate = moment().add(1, 'month');
    const sameMonthYear = biggestDate.isSame(dateNotFormactApi, 'month') && biggestDate.isSame(dateNotFormactApi, 'year');
    if (sameMonthYear) {
      setBiggestDateLimit(true);
    } else {      
      setBiggestDateLimit(false);
    }
    if (year && month && !sameMonthYear) {
      const numericYear = parseInt(year, 10);
      const numericMonth = parseInt(month, 10);
      console.log(numericYear, numericMonth);
      const prevSelectectedDate = moment().set({ month: numericMonth - 1, year: numericYear });
      console.log(prevSelectectedDate);
      setDateNotFormact(prevSelectectedDate.add(1, "month"));
      console.log('avançou', prevSelectectedDate);
      setDateMoment(prevSelectectedDate.format("MMMM _ YYYY"));
      setDateNotFormactApi(prevSelectectedDate.format('YYYY-MM-DD'));
      setShorttestDateLimit(false);
      updateFunction();
    }
  };

  const backMonth = () => {
    const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
    const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
    const shotestDate = moment('04-2024', 'MM-YYYY');
    const sameMonthYear = shotestDate.isSame(dateNotFormactApi, 'month') && shotestDate.isSame(dateNotFormactApi, 'year');
    if (sameMonthYear) {
      setShorttestDateLimit(true);
    } else {
      setShorttestDateLimit(false);
    }
    if (year && month && !sameMonthYear) {
      const numericYear = parseInt(year, 10);
      const numericMonth = parseInt(month, 10);
      console.log(numericYear, numericMonth);
      const prevSelectectedDate = moment().set({ month: numericMonth - 1, year: numericYear });
      console.log(prevSelectectedDate);
      setDateNotFormact(prevSelectectedDate.subtract(1, "month"));
      console.log('avançou', prevSelectectedDate);
      setDateMoment(prevSelectectedDate.format("MMMM _ YYYY"));
      setDateNotFormactApi(prevSelectectedDate.format('YYYY-MM-DD'));
      setBiggestDateLimit(false);
      updateFunction();
    }
  };

  useEffect(() => {
    // backMonth();
  }, []);

  useEffect(() => {
    if(clientsSelect) {
      setBillingType(clientsSelect.billing_type)
    }
  }, [clientsSelect]);

  const calcTotal = () => {
    let calculatedValue = 0;

    const minimum = Number(minimumBilling.total)

    if (billingType === 'atividade') {
      calculatedValue = fixedBilling <= minimum ? minimum + (simulated.filter((e) => e.name === 'Generalidades')[0]?.value || 0) : fixedBilling;
    }

    if (billingType === 'porcentual') {
      /* verifica se faturamento do cliente é maior que o mínimo, faz a multiplicação pelo percentual */
      calculatedValue = manualBilling > (minimumBilling.total + (simulated.filter((e) => e.name === 'Generalidades')[0]?.value || 0)) ? (manualBilling * (porcent / 100)) + (simulated.filter((e) => e.name === 'Generalidades')[0]?.value || 0) : (minimumBilling.total + simulated.filter((e) => e.name === 'Generalidades')[0]?.value || 0);
      console.log('calculatedValue: ', calculatedValue);
      /* soma os valores excedentes */
      calculatedValue += simulated.reduce((acc, curr) => curr.name !== 'Generalidades' ? acc + curr.value : acc, 0);
      console.log('calculatedValue + execende: ', calculatedValue);
    }

    if (billingType === 'mao de obra') {
      calculatedValue = amount;
    }

    if (billingType === 'atividade sem imposto') {
      const minWithGenerality = minimum + (simulated.filter((e) => e.name === 'Generalidades')[0]?.value || 0);
      calculatedValue = fixedBilling > minimum ? fixedBilling * tax + fixedBilling : minWithGenerality * tax + minWithGenerality;
      console.log('calculatedValue: ', calculatedValue);
      console.log('fixedBilling', fixedBilling);
      console.log('tax', tax);
      console.log('minimum', minimum);
    }
    /* aplica desconto para porcentual e atividade sem imposto */
    if (billingType !== 'mao de obra') {
      calculatedValue -= Number(discount) || 0;
    }
    // calculatedValue -= Number(discount) || 0;
    setNfValue(calculatedValue || 0);
  };

  const getFixedMonthlyValue = async () => {
    const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
    const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
    const date = `${year}-${month}`;
    const cnpj = localStorage.getItem('@XPM_CLIENT_COMPANY:');
    const response = await api.get(`/minimum-billing/${cnpj}/${date}`);
    if (response && response.data.id) {
      setFixedMonthlyValue(response.data.value);
    } else {
      setFixedMonthlyValue(0);
    }
  };

  const nfDetailGenerator = () => {
    if (billingType === 'porcentual') {
      if (manualBilling > minimumBilling.total) {
        const fatClient = {
          name: 'Percentual do faturamento',
          value: manualBilling * porcent / 100,
        };

        const fatGeneralities = {
          name: 'Generalidades',
          value: generalidades[0]?.sum_total || 0,
        };

        const fatOverloaded = {
          name: 'Excedente',
          value: simulated.reduce((acc, curr) => curr.name !== 'Generalidades' ? acc + curr.value : acc, 0),
        };

        return ([fatClient, fatGeneralities, fatOverloaded]);

      } else {
        const fatMinim = {
          name: 'Faturamento mínimo',
          value: minimumBilling.total,
        };

        const fatGeneralities = {
          name: 'Generalidades',
          value: generalidades[0].sum_total,
        };

        const fatOverloaded = {
          name: 'Excedente',
          value: simulated.reduce((acc, curr) => curr.name !== 'Generalidades' ? acc + curr.value : acc, 0),
        };

        return ([fatMinim, fatGeneralities, fatOverloaded]);
      }
    };
  }

  useEffect(() => {
    getFixedMonthlyValue();
    const obj = clientsSelect;
    const newBilling = billing || 0;
    const newMonthlyBilling = obj.monthly_billing || 0;
    const newPercentage = porcent || 0;
    const generalitiesValue = simulated.filter((e) => e.name === 'Generalidades')[0]?.value || 0;
    const newNfValue = newBilling > (newMonthlyBilling + generalitiesValue) ? newBilling : (newMonthlyBilling + generalitiesValue);

    if(billingType === 'atividade') {
      // console.log('---return minimum', getFixedMonthlyValue());
      setAmount(newBilling);
      // setFixedMonthlyValue(newMonthlyBilling);
      setNfValue(newNfValue)
    } else if (billingType === 'porcentual') {
      setAmount(manualBilling);
      // setFixedMonthlyValue(newMonthlyBilling);
      setPorcent(newPercentage);
      setNfValue(newNfValue * newPercentage);
    } else if (billingType === 'mao de obra') {
      setAmount(newBilling);
      setFixedMonthlyValue(0);
      setNfValue(newNfValue);
    } else if (billingType === 'atividade sem imposto') {
      setAmount(newBilling);
      // setFixedMonthlyValue(newMonthlyBilling);
      // setNfValue(newNfValue);
    }
    getVariables();
    // getTaxes();
    calcTotal();
  }, [billingType, clientsSelect, dateNotFormactApi, billing, porcent, arrayOfParams])

  useEffect(() => {
    if (billingType !== 'porcentual') {
      calcTotal();
    }
  }, [discount, tax, minTax, amount, porcent, minimumBilling])

  useEffect(() => {
    if (variables && variables.length > 0) {
      const sumVariables = variables.reduce((acum, obj) => acum + Number(obj.value), 0)
      const total = sumVariables + Number(fixedMonthlyValue);
      setMinimumBilling({fixed: fixedMonthlyValue, total});
    } else {
      setMinimumBilling({fixed: fixedMonthlyValue, total: fixedMonthlyValue});
    }
  }, [fixedMonthlyValue, variables, dateNotFormactApi])

  useEffect(() => {
    getPorcent();
    getDiscount();
    getManualBilling();
    if (dateNotFormactApi && clientsSelect) {
      getVariables();
      getGeneralities();
      getFixedGeneralities();
    }
  }, [clientsSelect, dateNotFormactApi])

  useEffect(() => { // seta o valor da NF para atividade sem imposto
    calcTotal();
  }, [tax, minTax, discount])

  useEffect(() => {
    setTax(0);
    setMinTax(0);
    // setDiscount(0);
  }, [dateNotFormactApi])

  useEffect(() => {
    calcTotal();
  }, [minimumBilling])

  useEffect(() => {
    if (billingType === 'porcentual') {
      calcTotal();
      setFixedBilling(manualBilling);
    }
  }, [manualBilling, discount])

  return configureParameter ? (
    <></>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "55px",
        marginBottom: "20px",
        gap: "33px",
      }}
    >
      <div style={boxCards}>
        
        <CardCompanyDetails
          update={update}
          name={"Empresa 01: Startpn"}
          value={nfValue.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
          status={
            (awaitingPayment > 0 && "Aguardando conferência") ||
            (paymentConcluid > 0 && "Fatura paga") ||
            "Aguardando conferência"
          }
          clientsSelect={clientsSelect}
          setClientsSelect={setClientsSelect}
          permission={permission}
        />

        <CardDateYear
          isActive={btnIsActive}
          add={() => addMonth()}
          back={() => backMonth()}
          date={dateMoment}
          dateApi={dateNotFormactApi}
          biggestDate={biggestDateLimit}
          shorttestDate={shorttestDateLimit}
        />

        {
          permission !== "Operacional" && (
            <ConfigParameter onClick={() => setConfigureParameter(true)} />
          )
        }        

        <Button
          width="235px"
          height="48px"
          background="var(--color-primary)"
          borderRadius="60px"
          marginTop="0"
          name="Adicionar generalidade"
          onClick={configureGeneralityOn}
          border="none"
        />
      </div>

        {
          billingType === "atividade" && permission !== "Operacional" && (
            <div
              style={{
                ...boxCards,
                marginBottom: "41px",
                display: "flex",
                gap: "16px",
              }}
            >
              <CardValueTotal
                icon={false}
                title={"Simulado"}
                value={fixedBilling}
                type={'BRL'}
                afterIcon={helpeCircle}
                list={simulated}
                onClick={() => console.log('')}
                topHelper={40}
                leftHelper={80}
              />
              <CardValueTotal
                icon={false}
                value={(Number(minimumBilling?.total) || 0) + (generalidades[0]?.sum_total || 0)}
                title={"Mínimo mensal"}
                onClick={setMinimumBillingMenu}
                list={[{name: 'Valor fixo', value: fixedMonthlyValue}, ...variables, {name: 'Generalidades', value: generalidades[0]?.sum_total}]}
                afterIcon={helpeCircle}
                topHelper={40}
                leftHelper={125}
              />
              <CardValueInput
                icon={false}
                value={discount}
                title={"Desconto"}
                hasInput={true}
                onChange={setDiscount}
              />
              <CardValueTotal
                icon={false}
                title={"Emissão de NF"}
                value={nfValue}
                result={true}
              />
            </div>
          )
        }

        {
          billingType === "porcentual" && permission !== "Operacional" && (
            <div
              style={{
                ...boxCards,
                marginBottom: "41px",
                display: "flex",
                gap: "16px",
              }}
            >
              <CardValueInput
                icon={false}
                value={manualBilling}
                title={"Faturamento do cliente"}
                hasInput={true}
                onChange={setManualBilling}
              />
              <CardValueTotal
                icon={false}
                afterIcon={helpeCircle}
                value={minimumBilling.total}
                title={"Mínimo mensal"}
                onClick={setMinimumBillingMenu}
                list={[{name: 'Valor fixo', value: fixedMonthlyValue}, ...variables]}
                topHelper={40}
                leftHelper={125}
              />
              <CardValueInput
                icon={false}
                value={porcent}
                title={"Percentual"}
                hasInput={true}
                onChange={setPorcent}
              />
              <CardValueInput
                icon={false}
                value={discount}
                title={"Desconto"}
                hasInput={true}
                onChange={setDiscount}
              />
              <CardValueTotal
                icon={false}
                title={"Emissão de NF"}
                value={nfValue}
                result={true}
                afterIcon={helpeCircle}
                list={nfDetailGenerator()}
                topHelper={40}
                leftHelper={0}
              />
            </div>
          )
        }

        {
          billingType === "mao de obra" && permission !== "Operacional" && (
            <div
              style={{
                ...boxCards,
                marginBottom: "41px",
                display: "flex",
                gap: "16px",
              }}
            >
              <CardValueTotal
                icon={false}
                title={"Valor total"}
                value={fixedBilling}
              />
              <CardValueInput
                icon={false}
                value={discount}
                title={"Desconto"}
                hasInput={true}
                onChange={setDiscount}
              />
              <CardValueTotal
                icon={false}
                title={"Emissão de NF"}
                value={nfValue}
                result={true}
              />
            </div>
          )
        }

        {
          billingType === "atividade sem imposto" && permission !== "Operacional" && (
            <div
              style={{
                ...boxCards,
                marginBottom: "41px",
                display: "flex",
                gap: "16px",
              }}
            >
              <CardValueTotal
                icon={false}
                title={"Simulado"}
                value={fixedBilling}
                type={'BRL'}
                afterIcon={helpeCircle}
                list={simulated}
                onClick={() => console.log('')}
                topHelper={40}
                leftHelper={80}
              />
              <CardValueTax
                icon={false}
                value={fixedBilling}
                tax={tax || 0}
                title={"Imposto simulado"}
                hasInput={true}
                onChange={setTax}
                afterIcon={helpeCircle}
                topHelper={40}
                leftHelper={125}
                onClick={() => setTaxesMenu(true)}
                list={taxesArray?.map((tax) => ({name: tax.name, value: tax.porcentage}))}
              />
              <CardValueTotal
                icon={false}
                afterIcon={helpeCircle}
                value={minimumBilling.total + generalidades[0].sum_total}
                title={"Mínimo mensal"}
                onClick={setMinimumBillingMenu}
                list={[{name: 'Valor fixo', value: fixedMonthlyValue}, ...variables, {name: 'Generalidades', value: generalidades[0].sum_total}]}
                topHelper={40}
                leftHelper={125}
              />
              <CardValueTax
                icon={false}
                value={minimumBilling.total + generalidades[0].sum_total}
                tax={tax || 0}
                title={"Imposto mínimo"}
                hasInput={true}
                onChange={() => console.log('imposto mínimo')}
                readOnly={true}
                afterIcon={helpeCircle}
                topHelper={40}
                leftHelper={125}
                onClick={() => console.log()}
                list={taxesArray?.map((tax) => ({name: tax.name, value: tax.porcentage}))}
              />
              <CardValueInput
                icon={false}
                value={discount || 0}
                title={"Desconto"}
                hasInput={true}
                onChange={setDiscount}
              />
              <CardValueTotal
                icon={false}
                title={"Emissão de NF"}
                value={nfValue}
                result={true}
              />
            </div>
          )
        }
      
    </div>
  );
};

export default CardParameters;
