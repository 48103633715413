/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { useEffect } from "react";
import CardDetailsContent from "./CardDetailsContent";
import { toBePartiallyChecked } from "@testing-library/jest-dom/matchers";

const CardDetailsInvoicing = ({
  title,
  content,
  content2,
  quantity,
  quantity2,
  UOM,
  UOM2,
  priceUnit,
  priceUnit2,
  total,
  total2,
  totalValue,
  marginLeft,
  marginLeft2,
  onClick,
  check,
  setCheck,
  contentAll,
  priceTotal,
  onlyOne,
  percentage,
  getWaitingConference,
  comments,
  hasContract,
  subtotalsArray,
  setSubtotalsArray,
}) => {
  // const [check, setCheck] = useState(false)
  const [check2, setCheck2] = useState(false);
  const [contentApi, setContentApi] = useState([]);

  const [sumAllOne, setSumAllOne] = useState("");

  const [subTotal, setsubTotal] = useState(0);

  const contentValueCard = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "5px",
  };

  const oneCardSettings = () => {
    if (contentApi[0]) {
      setSumAllOne(contentApi[0].sum_all);
    }
  };

  const calcSubTotal = async () => {
    const newArray = contentAll.slice(); // Cria uma cópia do array
    newArray.shift(); // Remove o primeiro elemento
    let processTotal = 0;

    if (!hasContract) {
      processTotal = newArray.reduce((acc, item) => {
        const factor = title === 'Armazenagem' ? Number(item.factor) : 1;
        const result = acc + item.qtd_item * item.price * factor;
        return result;
      }, 0);
    }

    if (hasContract) {
      processTotal = newArray.reduce((acc, item) => {
        const factor = title === 'Armazenagem' ? Number(item.factor) : 1;
        const diff = item.qtd_item - (item.qtd_contract || 0);
        const overload = diff > 0 ? diff : 0
        const result = acc + overload * item.price * factor;
        return result;
      }, 0);
    }

    // console.log('processTotal', processTotal)

    if (title === 'Seguro de Armazenagem') {
      processTotal = processTotal / 100
    };

    if (processTotal < 0) {
      processTotal = 0
    };
    
    setsubTotal(processTotal);
    return processTotal;
  };

useEffect(() => {
  calcSubTotal();
}, [contentAll])

  useEffect(() => {
    if (onlyOne) {
      oneCardSettings();
    }
  }, [onlyOne, contentAll, contentApi]);

  useEffect(() => {
    setContentApi(contentAll);
  }, [contentAll]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "13px",
      }}
    >
      <h3 className="textTitleDetailsInvoicing">{title}</h3>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "13px",
        }}
      >
        {onlyOne ? (
          <CardDetailsContent
            item={{
              description: "Unidades armazenadas",
              qtd_item: contentApi.length - 1 > 0 ? contentApi.length - 1 : 0,
              soma_qtd_item: sumAllOne || 0,
              price: 0,
              UOM: "Diversas",
            }}
            getWaitingConference={getWaitingConference}
            UOM={UOM}
            content={content}
            marginLeft={marginLeft}
            onClick={onClick}
            priceUnit={priceUnit}
            quantity={quantity}
            total={total}
            comments={comments}
            onlyOne={onlyOne}
            title={title}
          />
        ) : contentApi?.length > 1 ? (
          contentApi.map((item, i) => {
            console.log(item.id);
            if (i > 0) {
              return (
                <CardDetailsContent
                  item={item}
                  getWaitingConference={getWaitingConference}
                  content={content}
                  marginLeft={marginLeft}
                  onClick={onClick}
                  comments={comments}
                  percentage={percentage}
                  hasContract={hasContract}
                  title={title}
                />
              );
            }
          })
        ) : (
          <CardDetailsContent
            item={{
              description: "Não há parametros",
              qtd_item: contentApi.length - 1 > 0 ? contentApi.length - 1 : 0,
              soma_qtd_item: sumAllOne || 0,
              price: 0,
              UOM: "Não encontrado",
            }}
            UOM={UOM}
            content={content}
            marginLeft={marginLeft}
            onClick={onClick}
            priceUnit={priceUnit}
            quantity={quantity}
            total={total}
            comments={comments}
            title={title}
          />
        )}

        <div
          style={{
            border: "1px solid #d7d7d7",
            borderRadius: "10px",
            padding: "17px 23px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <p className="textTotalValueInvoicing">Valor Total:</p>
            <p className="totalValueInvoicing">
              {subTotal?.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDetailsInvoicing;
