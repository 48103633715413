import React, { useState } from "react";
import menuIcon from "../../../images/svg/menuIcon.svg";

import logo from "../../../images/png/logo-temporario.png";
import upArrow from "../../../images/png/upArrow.png";
import downArrow from "../../../images/png/downArrow.png";

import ModalContainer from "./ModalContainer";
import ModalActions from "./ModalActions";
import ModalDelete from "./ModalDelete";

const HeaderDetails = ({ schedule, isOpen, setIsOpen }) => {
    const [optionsModal, setOptionsModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const stylesContainer = {
        display: "flex",
        borderBottom: "1px solid #D7D7D7",
        paddingBottom: 10,
        paddingTop: 10,
    };

    const stylesCompanyData = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRight: "1px solid #D7D7D7",
        paddingRight: "25px",
        paddingTop: 5,
    };

    const stylesTrasnportData = {
        width: "33%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        borderRight: "1px solid #D7D7D7",
        paddingLeft: "25px",
        paddingRight: "25px",
    };

    const stylesActivityData = {
        flex: "1",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "25px",
    };

    return (
        <>
            <header style={stylesContainer}>
                <div style={stylesCompanyData}>
                    <button
                        style={{
                            background: "none",
                            border: "none",
                            marginRight: 10,
                        }}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <img
                            src={isOpen ? upArrow : downArrow}
                            alt="schedule-menu"
                        />
                    </button>

                    <img
                        style={{
                            width: 40,
                            height: 40,
                        }}
                        src={logo}
                        alt="change-schedule"
                    />

                    <p
                        className="textScheduleHeaderPrincipal"
                        style={{
                            marginLeft: 10,
                        }}
                    >
                        {schedule?.client.name}
                    </p>
                </div>

                <div style={stylesTrasnportData}>
                    <p className="textScheduleHeaderPrincipal">
                        {schedule?.transporter_name}
                    </p>

                    <p className="textScheduleHeaderSecundary">{`${schedule?.vehicle_plate} · ${schedule?.vehicle_model}`}</p>
                </div>

                <div style={stylesActivityData}>
                    <div>
                        <p className="textScheduleHeaderPrincipal">
                            {schedule?.local.local_name}
                        </p>

                        <p className="textScheduleHeaderSecundary">
                            {schedule.local.type}
                        </p>
                    </div>

                    <button
                        style={{
                            backgroundColor: "#fff",
                            border: "none",
                            height: "100%",
                            width: 20,
                        }}
                        onClick={() => setOptionsModal(true)}
                    >
                        <img src={menuIcon} alt="schedule-menu" />
                    </button>
                </div>
            </header>

            {optionsModal && (
                <ModalContainer
                    isActive={optionsModal}
                    closeModal={setOptionsModal}
                >
                    <ModalActions
                        closeModal={setOptionsModal}
                        setDeleteModal={setDeleteModal}
                        setOptionsModal={setOptionsModal}
                    />
                </ModalContainer>
            )}

            {deleteModal && (
                <ModalContainer
                    isActive={deleteModal}
                    closeModal={setDeleteModal}
                >
                    <ModalDelete
                        closeModal={setDeleteModal}
                        schedule={schedule}
                    />
                </ModalContainer>
            )}
        </>
    );
};

export default HeaderDetails;
