import Button from "./Button";
import notSku from "../../../images/svg/notSku.svg";
import LineOptions from "./LineOptions";
import { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import { toast } from "react-toastify";

/* eslint-disable jsx-a11y/alt-text */
const CardsProductConversion = ({ company, cnpj, picture }) => {
  const [content, setContent] = useState([]);
  const [fileCvg, setFileCvg] = useState([]);
  const [stock, setStock] = useState([]);

  /* STYLES */
  const container = {
    height: "559px",
    width: "48%",
    backgroundColor: "#fff",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    padding: "20px",
  };

  const boxContent = {
    width: "100%",
    borderBottom: "1px solid #d7d7d7",
    paddingBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
  };

  const headerCard = {
    width: "50%",
    borderRight: "1px solid #d7d7d7",
    display: "flex",
    gap: "10px",
  };

  const boxImage = {
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    border: "1px solid #d7d7d7",
  };

  const contentNotProducts = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flexDirection: "column",
    gap: "29px",
  };

  const uploadProducts = async (file) => {
    if (
      file.type !==
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      toast.error("Formato de arquivo inválido!", {
        autoClose: 2000,
      });
      return false;
    }

    const data = await file.arrayBuffer();

    const workbook = read(data);

    const workSheet = workbook.Sheets[workbook.SheetNames[0]];
    const worksheet = utils.sheet_to_json(workSheet);

    setFileCvg(file);
    setStock(worksheet);

    console.log(worksheet);
  };

  /* Exemple Data */
  useEffect(() => {
    // const data = [
    //   { code: "AB5789", qtdBox: 20 },
    //   { code: "BB5790", qtdBox: 13 },
    //   { code: "NE1357", qtdBox: 53 },
    //   { code: "NE1357", qtdBox: 53 },
    //   { code: "NE1357", qtdBox: 53 },
    //   { code: "NE1357", qtdBox: 53 },
    // ];

    if (stock.length > 0) {
      const data = stock.map((item) => {
        return { code: item["Código SKU"], qtd_box: item["QTD por caixa"] };
      });

      setContent(data);
    }
  }, [stock]);

  return (
    <div style={container} className="animate__animated animate__fadeIn">
      <div style={boxContent}>
        <div style={headerCard}>
          <img src={picture} style={boxImage} />
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <p className="textTitleDetailsCardConversion">{company}</p>
            <p className="textContentDetailsCardConversion">{cnpj}</p>
          </div>
        </div>

        <Button
          padding={"0 20px"}
          height="35px"
          background={"var(--color-primary)"}
          borderRadius="60px"
          marginTop="0"
          name={"Upload de itens"}
          color={"#fff"}
          border="none"
          onClick={() => document.getElementById("file").click()}
        />

        <input
          onChange={(e) => uploadProducts(e.target.files[0])}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          name="file"
          id="file"
          type="file"
          style={{ display: "none" }}
        />
      </div>

      {content?.length > 0 ? (
        <div
          className="animate__animated animate__fadeIn scrollLower"
          style={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            overflow: "hidden",
            overflowY: "scroll",
            height: "85%",
            paddingRight: "20px",
          }}
        >
          {content.map((item, index) => {
            return (
              <LineOptions code={item.code} qtdBox={item.qtd_box} i={index} />
            );
          })}
        </div>
      ) : (
        <div style={contentNotProducts}>
          <img src={notSku} />
          <p className="textDetailsBannerConversion">
            Não há nenhum SKU, mas quando fizer o upload, eles aparecerá aqui :)
          </p>
        </div>
      )}
    </div>
  );
};

export default CardsProductConversion;
