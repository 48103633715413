import React, { useEffect, useState } from "react";
import FormInputText from "./FormInputText";
import InputCounter from "./InputCounter";
import deleteIcon from "../../../images/svg/deleteIcon.svg";

const BranchConfigCard = ({ item, index, handleChange, destroyBranch }) => {
    const [valueInput, setValueInput] = useState("");

    useEffect(() => {
        setValueInput(item.branch_name);
    }, [item.branch_name]);

    return (
        <div key={item.id} style={{ marginTop: 20, marginBottom: 10 }}>
            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <InputCounter
                    number={index + 1}
                    width={34}
                    height={34}
                    color={"#fff"}
                    background={"#96C"}
                    textAlign={"center"}
                    borderRadius={17}
                    border={"none"}
                />

                <FormInputText
                    name={"name"}
                    width={"80%"}
                    height={"45px"}
                    value={valueInput}
                    label={"Nome da filial"}
                    placeholder={"Escreva o nome"}
                    border={`1px solid #D7D7D7`}
                    borderRadius={"7px"}
                    onChange={(e) => setValueInput(e.target.value)}
                    onBlur={(e) => handleChange(e, item.id)}
                />

                <img
                    src={deleteIcon}
                    style={{ cursor: "pointer" }}
                    alt="delete"
                    onClick={destroyBranch}
                />
            </div>
        </div>
    );
};

export default BranchConfigCard;
