import productivity from "../mocks/productivity";
import CardMonthsDetailsTeams from "./CardMonthsDetailsTeams";

const ContentMonths = () => {
  return (
    <div>
      {productivity.length > 0 ? (
        productivity.map((elem) => {
          return (
            <div>
              <CardMonthsDetailsTeams elem={elem} />
            </div>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default ContentMonths;
