import React, { useContext, useEffect } from "react";
import add from "../../../images/svg/add.svg";
import BranchConfigCard from "./BranchConfigCard";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";

const BranchConfig = ({
    items,
    setItems,
    newBranches,
    setNewBranches,
    editedBranches,
    setEditedBranches,
}) => {
    const { getBrachs, branchs, updateBranch, createBranch, destroyBranch } =
        useContext(SchedulesContext);

    const addBranch = () => {
        newFunction();
        function newFunction() {
            const newArray = [...newBranches];

            const newObj = {
                name: "",
                city: "",
                state: "",
                country: "",
            };

            newArray.push(newObj);
            
            setNewBranches(newArray);
        }
    };

    const editExistingBranch = ({ target }, id) => {
        const { value } = target;
        updateBranch({ name: value }, id);
    };

    const editNewBranches = ({ target }, index) => {
        const { value } = target;

        createBranch({ branchName: value });
        setNewBranches([]);
    };

    useEffect(() => {
        getBrachs();
    }, []);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: 420,
            }}
        >
            {branchs.length > 0
                ? branchs.map((item, index) => {
                      return (
                          <BranchConfigCard
                              item={item}
                              index={index}
                              destroyBranch={() => destroyBranch(item.id)}
                              handleChange={editExistingBranch}
                          />
                      );
                  })
                : []}

            {newBranches.length > 0
                ? newBranches.map((item, index) => (
                      <BranchConfigCard
                          item={item}
                          index={index + branchs.length}
                          handleChange={editNewBranches}
                      />
                  ))
                : null}

            <div
                style={{
                    display: "flex",
                    gap: 10,
                    color: "#9966CC",
                    cursor: "pointer",
                }}
                onClick={() => addBranch()}
            >
                <img src={add} alt="adicionar lugar" />
                <p>Adicionar nova filial</p>
            </div>
        </div>
    );
};

export default BranchConfig;
