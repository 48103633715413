import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
const moment = require("moment");

export const SchedulesContext = createContext({});

export const SchedulesValidation = ({ children }) => {
    const [selectedBranch, setSelectedBranch] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");
    const [selectedDate, setSelectedDate] = useState(undefined);

    const [detailsSelectedHours, setDetailsSelectedHours] = useState([]);
    const [detailsSelectedGeneral, setDetailsSelectedGeneral] = useState([]);
    const [idDeletSchedule, setIdDeletSchedule] = useState("");
    const [dateConfig, setDateConfig] = useState([]);
    const [branchs, setBranchs] = useState([]);

    const [scheduledTimes, setScheduledTimes] = useState([]);
    const [locationsDetails, setLocationsDetails] = useState([]);
    const [selectedSchedules, setSelectedSchedules] = useState([]);

    const [receivedSchedules, setReceivedSchedules] = useState([]);
    const [deliveredSchedules, setDeliveredSchedules] = useState([]);
    const [completedSchedules, setCompletedSchedules] = useState([]);

    const getBrachs = async () => {
        try {
            const response = await api.get("/schedules/branches");

            setBranchs(response.data);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const createBranch = async (data) => {
        try {
            if (data.branchName.length < 1) return;

            await api.post("/schedules/branches", data);

            getBrachs();
        } catch (error) {}
    };

    const updateBranch = async (data, id) => {
        try {
            await api.patch(`/schedules/branch/${id}`, data);

            getBrachs();
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const destroyBranch = async (id) => {
        try {
            await api.delete(`/schedules/branches/${id}`);

            getBrachs();
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const getLocationsByIdBranch = async (branchId, date) => {
        try {
            const response = await api.get(
                `/schedules/admin/locals/${branchId}/:${date}`
            );

            setLocationsDetails(response.data.locals);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const getLocationsByIdBranchLimit = async (branchId, date) => {
        try {
            const response = await api.get(
                `/schedules/admin/locals/${branchId}/:${date}`
            );
            
            setLocationsDetails(response.data.locals);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const updateLocation = async (data, id, branchId) => {
        try {
            const response = await api.patch(`/schedules/local/${id}`, data);

            getLocationsByIdBranch(branchId);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const createLocation = async (data, branch_id) => {
        try {
            await api.post(`/schedules/locals/${branch_id}`, data);

            getLocationsByIdBranchLimit(branch_id, selectedDate);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const getHoursDisponible = async (localId, date) => {
        try {
            const parts = date.split("-");
            const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

            const response = await api.get(
                `/schedules/grouped/${localId}/${formattedDate}`
            );

            setScheduledTimes(response.data);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const deleteSchedule = async (scheduleId, closeModal) => {
        try {
            const response = await api.delete(`/schedules/admin/${scheduleId}`);

            toast.success(response.data.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            closeModal(false);

            const updatedSchedules = selectedSchedules.filter(
                (schedule) => schedule.id !== scheduleId
            );

            const updatedScheduledTimes = scheduledTimes
                .map((group) => {
                    group.schedules = group.schedules.filter(
                        (schedule) => schedule.id !== scheduleId
                    );

                    if (group.schedules.length === 0) return null;
                    else return group;
                })
                .filter(Boolean);

            setScheduledTimes(updatedScheduledTimes);
            setSelectedSchedules(updatedSchedules);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const getFetchedSchedules = async (localId, selectedDate) => {
        try {
            const parts = selectedDate.split("-");
            const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

            const response = await api.get(
                `/schedules/admin/all/received/delivered/cancelled/${localId}/${formattedDate}`
            );

            setReceivedSchedules(response.data.received);
            setDeliveredSchedules(response.data.delivered);
            setCompletedSchedules(response.data.completed);
        } catch (error) {
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    useEffect(() => {
        if (selectedBranch) getLocationsByIdBranch(selectedBranch);
    }, [selectedBranch]);

    useEffect(() => {
        if (selectedLocation && selectedDate) {
            setScheduledTimes([]);
            setSelectedSchedules([]);
            setReceivedSchedules([]);
            setDeliveredSchedules([]);
            setCompletedSchedules([]);
            getHoursDisponible(selectedLocation, selectedDate);
            getFetchedSchedules(selectedLocation, selectedDate);
        }
    }, [selectedLocation, selectedDate]);

    useEffect(() => {
        const dateNow = moment();
        const dateFormact = dateNow.format("DD-MM-YYYY");

        setSelectedDate(dateFormact);
    }, []);

    return (
        <>
            <SchedulesContext.Provider
                value={{
                    getBrachs,
                    branchs,
                    updateBranch,
                    createBranch,
                    destroyBranch,
                    setDateConfig,
                    getLocationsByIdBranch,
                    locationsDetails,
                    updateLocation,
                    createLocation,
                    selectedBranch,
                    setSelectedBranch,
                    selectedDate,
                    setSelectedDate,
                    selectedLocation,
                    setSelectedLocation,
                    scheduledTimes,
                    detailsSelectedHours,
                    getLocationsByIdBranchLimit,
                    deleteSchedule,
                    setIdDeletSchedule,
                    detailsSelectedGeneral,
                    setSelectedSchedules,
                    selectedSchedules,
                    receivedSchedules,
                    setReceivedSchedules,
                    deliveredSchedules,
                    setDeliveredSchedules,
                    completedSchedules,
                    setCompletedSchedules,
                }}
            >
                {children}
            </SchedulesContext.Provider>
        </>
    );
};
