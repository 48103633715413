import checkOk from "../../../images/svg/checkOk.svg";
import checkOff from "../../../images/svg/checkOff.svg";

const CardTeamsDetails = ({ team }) => {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    border: "1px solid #d7d7d7",
    padding: "20px 30px",
    borderRadius: "10px",
    marginTop: "10px",
  };

  const lineOptions = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const formactResult = (content, type) => {
    if (type === "content") return content ? "Resultado OK" : "Resultado NOK";
    if (type === "result") return content ? checkOk : checkOff;
    if (type === "class")
      return content ? "borderTeamsPositive" : "borderTeamsNegative";
  };

  return (
    <div style={containerStyle}>
      <p style={{ width: "130px", fontSize: "13px" }}>{team.team}</p>

      <div style={{ ...lineOptions, width: "160px" }}>
        <p className={formactResult(team.absenteeism, "class")}>
          {formactResult(team.absenteeism, "content")}
        </p>
      </div>

      <div style={{ ...lineOptions, width: "160px" }}>
        <p className={formactResult(team.capacity, "class")}>
          {formactResult(team.capacity, "content")}
        </p>
      </div>

      <div style={{ ...lineOptions, width: "160px" }}>
        <p className={formactResult(team.iqs, "class")}>
          {formactResult(team.iqs, "content")}
        </p>
      </div>

      <div style={{ ...lineOptions, width: "160px" }}>
        <p className={formactResult(team.tourniquet, "class")}>
          {formactResult(team.tourniquet, "content")}
        </p>
      </div>

      <div style={{ ...lineOptions, width: "160px" }}>
        <img
          style={{ height: "23px" }}
          src={formactResult(team.result, "result")}
        />
      </div>
    </div>
  );
};

export default CardTeamsDetails;
