import { useEffect } from "react";

const CardMonth = ({ name, average, metrics }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        alignItems: "center",
        height: "72px",
        width: "72px",
        borderRadius: "10px",
        border: "1px solid #d7d7d7",
        justifyContent: "center",
        gap: "10px",
      }}
    >
      <p className="textMonthAverageYear">{name}</p>
      <p
        className="textMonthAverageYear"
        style={{
          color: average < metrics ? "#dd2727" : "#22a837",
        }}
      >
        {average}%
      </p>
    </div>
  );
};

export default CardMonth;
