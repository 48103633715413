const productivity = [
  {
    month: "01",
    teams: [
      {
        team: "Fase1/2",
        absenteeism: true,
        capacity: false,
        iqs: true,
        tourniquet: true,
        result: true,
      },
      {
        team: "Fase 3",
        absenteeism: true,
        capacity: false,
        iqs: true,
        tourniquet: true,
        result: false,
      },
    ],
  },
];

export default productivity;
