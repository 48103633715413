import React from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { BigHead } from "@bigheads/core";
import BackArrow from "./BackArrow";

const HeaderDashboard = ({ name, page, adm, back, onClick }) => {
  const [nameUser, setNameUser] = useState("XPM Admin");
  const [pictureUser, setPictureUser] = useState("");

  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 1326 });

  const exit = async () => {
    localStorage.clear();
    navigate("/");
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "2.6875rem",
    paddingLeft: isDesktop ? "0" : "3.375rem",
    zIndex: "1",
    width: !isDesktop && "95%",
  };

  const userInfo = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "1.1rem",
    width: "234px",
    background: "white",
    border: "1.9px solid #d7d7d7",
    borderRadius: "60px",
    padding: "6px",
    paddingLeft: "20px",
    height: "54px",
  };

  return (
    <div
      className="boxContainerHeader"
      style={{
        width: "100%",
        background: "#F9F9F9",
      }}
    >
      <div className="containerHeader" style={headerStyle}>
        <div style={{ position: "relative" }}>
          {back && (
            <div
              style={{
                position: "absolute",
                top: "11px",
                cursor: "pointer",
              }}
            >
              <BackArrow onClick={onClick} />
            </div>
          )}
          <p
            style={{ marginLeft: back && "40px" }}
            className="textPrimaryHeaderDashboard"
          >
            {name}
          </p>
        </div>

        <div class="dropdown">
          <button
            style={userInfo}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div style={{ position: "relative", right: "13px" }}>
              {pictureUser ? (
                <img
                  src={pictureUser}
                  style={{
                    width: "40px",
                    height: "40px",
                    position: "absolute",
                    top: "-20px",
                    left: "0",
                    borderRadius: "60px",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "55px",
                    height: "55px",
                    position: "absolute",
                    top: "-33px",
                    left: "-7px",
                  }}
                >
                  <BigHead
                    accessory="shades"
                    body="chest"
                    circleColor="blue"
                    clothing="tankTop"
                    clothingColor="black"
                    eyebrows="angry"
                    eyes="wink"
                    facialHair="mediumBeard"
                    graphic="vue"
                    hair="short"
                    hairColor="black"
                    hat="none"
                    hatColor="green"
                    lashes="false"
                    lipColor="purple"
                    mask="true"
                    faceMask="true"
                    mouth="open"
                    skinTone="brown"
                  />
                </div>
              )}
            </div>
            <p className="textClientInfoHeader">{nameUser}</p>

            <svg
              width="8"
              height="8"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.182668 1.78582C0.0688405 1.57763 0.0688405 1.32582 0.182668 1.11763C0.446809 0.634515 1.14065 0.634515 1.40479 1.11763L2.41937 2.97328C3.12207 4.25852 4.96793 4.25852 5.67064 2.97327L6.68521 1.11763C6.94935 0.634515 7.64319 0.634515 7.90733 1.11763C8.02116 1.32582 8.02116 1.57763 7.90733 1.78582L6.67725 4.03563C5.53942 6.11672 2.55058 6.11672 1.41275 4.03563L0.182668 1.78582Z"
                fill="black"
              />
            </svg>
          </button>

          <div
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            style={{ width: "234px" }}
          >
            <a class="dropdown-item" onClick={() => exit()} href="#">
              Sair
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderDashboard;
