/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import waitingConferenceIcon from "../../../images/svg/waitingConferenceIcon.svg";
import api from "../../../services/api";
import { DashContext } from "../../../context/Dashboard/DashContext";
import CheckOn from '../../../images/svg/checkOnIcon.svg';
import CheckOff from '../../../images/svg/checkOffIcon.svg';
import { toast } from "react-toastify";

const CardCompanyBPO = ({
  client,
  billing,
}) => {

  const [status, setStatus] = useState(false);

  const userInfo = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px",
    width: "280px",
    background: "white",
    border: "1px solid #d8d8d8",
    borderRadius: "60px",
    padding: "6px",
    paddingLeft: "20px",
    height: "40px",
    position: "relative",
  };

  const getStatus = () => {
    if (!billing.checked_by_admin) {
      return "Aguardando conferência"
    }
    if (!billing.checked_by_client) {
      return "Aguardando aprovação"
    }
    if (!billing.invoiced) {
      return "Aguardando faturamento"
    }
    if (billing.invoiced) {
      return "Faturado"
    }
    return 'Carregando'
  };

  const markAsInvoiced = async () => {
    try {
      if (status !== 'Aguardando conferência') {
        const response = await api.patch(`/billing/admin/invoiced/${billing.id}`);
        toast.success(response.data.message, { autoClose: 2000 });
        setStatus('Faturado')
      } else {
        toast.warning('É necessário que seja feita a conferência antes de faturar a ordem', { autoClose: 2000 });
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 2000 });
      console.log(error);
    }
  };

  useEffect(() => {
    if (billing) {
      setStatus(getStatus() || 'Carregando');
    }
  }, [billing]);

  return (
    <div
      style={{
        backgroundColor: "#fff",
        border: "1px solid #d7d7d7",
        width: "319px",
        borderRadius: "10px",
        padding: "20px",
      }}
    >

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #d7d7d7",
          paddingBottom: "17px",
        }}
      >
        <div style={userInfo}>
          <p >{client.client.trim()}</p>
          {
            status === "Faturado" ? (
              <img src={CheckOn} alt="concluido" onClick={() => markAsInvoiced(status)} style={{cursor: "pointer"}} />
            ) : (
              <img  src={CheckOff} alt="não realizado" onClick={() => markAsInvoiced(status)} style={{cursor: "pointer"}} />
            )
          }
        </div>        
      </div>

      <div
        style={{
          marginTop: "17px",
          display: "flex",
          flexDirection: "column",
          gap: "17px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="textTitleValue">Valor a pagar</p>
          <p className="textContentValue">
            {
              Number(billing?.billing_value)?.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }
          </p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="textTitleValue">Status</p>
          <p className="textStatusValue">{status}</p>
        </div>
      </div>
    </div>
  );
};

export default CardCompanyBPO;
