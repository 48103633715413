import axios from "axios";

const api = axios.create({
  // baseURL: "http://localhost:8080/",
  baseURL: "https://api.xpmlogistica.com.br/",
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("@XPM_ADM_TOKEN:");

    if (token) config.headers["Authorization"] = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
