import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";

export const LimitViewContext = createContext({});

export const LimitViewValidation = ({ children }) => {
  const [limitsClient, setLimitsClient] = useState("");
  const [alreadyRequestLimits, setAlreadyRequestLimits] = useState(false);
  const showToast = (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "info":
        toast.info(message);
        break;
      case "warn":
        toast.warn(message);
        break;
      case "loading":
        return toast.loading(message); // Retorna o ID do toast de loading
      default:
        toast(message); // Exibe um toast padrão sem ícone específico
    }
  };

  const onCreateLimit = async (body, closeModal) => {
    try {
      const { data } = await api.post("/limitation", body);
      closeModal && closeModal();

      setAlreadyRequestLimits(false);

      showToast("success", data.message);
    } catch (error) {
      showToast("error", error.response.data.message);
    }
  };

  const onGetLimit = async () => {
    try {
      const client_cnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
      const { data } = await api.get(`/limitation?client_cnpj=${client_cnpj}`);
      setAlreadyRequestLimits(true);
      setLimitsClient(data);
    } catch (error) {
      console.log(error);
      showToast("error", error.response.data.message);
    }
  };

  return (
    <>
      <LimitViewContext.Provider
        value={{
          onCreateLimit,
          limitsClient,
          onGetLimit,
          alreadyRequestLimits,
        }}
      >
        {children}
      </LimitViewContext.Provider>
    </>
  );
};
