import ContainerWigth from "../components/ContainerWigth";
import SearchBranch from "./SearchBranch";
import Button from "../components/Button";
import BoxDayScheduling from "./BoxDayScheduling";
import { useContext, useState } from "react";
import ModalContainer from "./ModalContainer";
import ModalFilter from "./ModalFilter";
import ScheduleDetails from "./ScheduleDetails";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";

import ModalScheduleConfig from "./ModalScheduleConfig"

import closeIcon from "../../../images/svg/closeIcon.svg"

const BoxContainerShedules = () => {
    const [configOpen, setConfigOpen] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);

    const { selectedSchedules, setSelectedDate } = useContext(SchedulesContext);

    const [places, setPlaces] = useState([])
    const [branches, setBranches] = useState([])
    const [currentBranch, setCurrentBranch] = useState([])

    return (
        <>
            <ContainerWigth>
                <SearchBranch setOpenFilter={setOpenFilter} />

                <Button
                    width={216}
                    height={45}
                    background={"#9966CC"}
                    borderRadius={60}
                    marginTop={0}
                    name={"Configuração"}
                    border={"none"}
                    disabled={false}
                    onClick={() => setConfigOpen(!configOpen)}
                    color={"#FFF"}
                    padding={0}
                />

                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                    <BoxDayScheduling />

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: 20,
                            width: "68%",
                        }}
                    >
                        {selectedSchedules?.map((schedule) => (
                            <ScheduleDetails schedule={schedule} />
                        ))}
                    </div>
                </div>
            </ContainerWigth>

            <ModalContainer isActive={openFilter} closeModal={setOpenFilter}>
                <ModalFilter
                    setSelectedDate={setSelectedDate}
                    setOpenFilter={setOpenFilter}
                />
            </ModalContainer>

            {configOpen ? (
                <ModalContainer
                    isActive={configOpen}
                    closeModal={() => setConfigOpen(true)}
                >
                    <ModalScheduleConfig
                        closeModal={setConfigOpen}
                        closeIcon={closeIcon}
                        places={places}
                        setPlaces={setPlaces}
                        branches={branches}
                        setBranches={setBranches}
                        currentBranch={currentBranch}
                        setCurrentBranch={setCurrentBranch}
                    />
                </ModalContainer>
            ) : null}
        </>
    );
};

export default BoxContainerShedules;
