import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { BigHead } from "@bigheads/core";
import BackArrow from "./BackArrow";
import api from "../../../services/api";

const ClientsSelect = ({
  name,
  page,
  adm,
  back,
  onClick,
  limit,
  title,
  borderRadius,
  paddingTop,
  width,
  update,
}) => {
  const [nameUser, setNameUser] = useState("Nome do cliente");
  const [pictureUser, setPictureUser] = useState("");
  const [clientsAll, setClientsAll] = useState("");
  const [clientsSelect, setClientsSelect] = useState("");

  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ minWidth: 1326 });

  const getClients = async () => {
    try {
      const clientsCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
      const response = await api.get("/clients");
      setClientsAll(response.data);

      if (clientsCnpj) {
        const client = response.data.find((e) => e.cnpj === clientsCnpj);

        setClientsSelect(client.name);
      } else {
        setClientsSelect(response.data[0].name);
        localStorage.setItem("@XPM_CLIENT_COMPANY:", response.data[0].cnpj);
        if (limit) {
          setClientsSelect(letterLimit(response.data[0].name, 10, true));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const exit = async () => {
    localStorage.clear();

    navigate("/");
  };

  const letterLimit = (text, limit, threePoints) => {
    let description = [];

    if (threePoints) {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 1) {
          description = `${description.join("")}...`;
        }
      });
    } else {
      text.split("").map((res) => {
        if (description.length <= Number(limit)) {
          description.push(res);
        } else if (description.length == Number(limit) + 2) {
          description = `${description.join("")}...`;
        }
      });
    }

    return description;
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    update && update();
  }, [clientsSelect]);

  const headerStyle = {
    display: "flex",
    alignItems: "center",
  };

  const userInfo = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "1.1rem",
    width: limit ? "220px" : width ? width : "220px",
    background: "white",
    border: "1.9px solid #d7d7d7",
    borderRadius: borderRadius || "60px",
    padding: "6px",
    paddingLeft: "20px",
    height: "45px",
  };

  return (
    <div
      style={{
        background: "#F9F9F9",
      }}
    >
      <div style={headerStyle}>
        <div style={{ position: "relative" }}>
          {back && (
            <div
              style={{
                position: "absolute",
                top: "11px",
                cursor: "pointer",
              }}
            >
              <BackArrow onClick={onClick} />
            </div>
          )}
          <p
            style={{ marginLeft: back && "40px" }}
            className="textPrimaryHeaderDashboard"
          >
            {name}
          </p>
        </div>

        <div class="dropdown">
          <p className="textTitleGenerality">{title}</p>
          <button
            style={userInfo}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div style={{ position: "relative", right: "13px" }}>
              {pictureUser ? (
                <img
                  src={pictureUser}
                  style={{
                    width: "40px",
                    height: "40px",
                    position: "absolute",
                    top: "-20px",
                    left: "0",
                    borderRadius: "60px",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "44px",
                    height: "55px",
                    position: "absolute",
                    top: "-26px",
                    left: "-7px",
                  }}
                >
                  <BigHead
                    accessory="shades"
                    body="chest"
                    circleColor="blue"
                    clothing="tankTop"
                    clothingColor="black"
                    eyebrows="angry"
                    eyes="wink"
                    facialHair="mediumBeard"
                    graphic="vue"
                    hair="short"
                    hairColor="black"
                    hat="none"
                    hatColor="green"
                    lashes="false"
                    lipColor="purple"
                    mask="true"
                    faceMask="true"
                    mouth="open"
                    skinTone="brown"
                  />
                </div>
              )}
            </div>
            <p
              className="textClientInfoHeader"
              style={{
                transform: title && "translateX(-240px)",
              }}
            >
              {clientsSelect}
            </p>

            <svg
              width="8"
              height="8"
              viewBox="0 0 8 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.182668 1.78582C0.0688405 1.57763 0.0688405 1.32582 0.182668 1.11763C0.446809 0.634515 1.14065 0.634515 1.40479 1.11763L2.41937 2.97328C3.12207 4.25852 4.96793 4.25852 5.67064 2.97327L6.68521 1.11763C6.94935 0.634515 7.64319 0.634515 7.90733 1.11763C8.02116 1.32582 8.02116 1.57763 7.90733 1.78582L6.67725 4.03563C5.53942 6.11672 2.55058 6.11672 1.41275 4.03563L0.182668 1.78582Z"
                fill="black"
              />
            </svg>
          </button>

          <div
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            style={{
              width: limit ? "210px" : "210px",
              height: "250px",
              overflow: "hidden",
              overflowY: "auto",
            }}
          >
            {clientsAll.length > 0 &&
              clientsAll.map((item) => {
                if (limit) {
                  return (
                    <>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          localStorage.setItem(
                            "@XPM_CLIENT_COMPANY:",
                            item.cnpj
                          );
                          setClientsSelect(item.name);
                        }}
                        href="#"
                      >
                        {letterLimit(item.name, 10, true)}
                      </a>
                    </>
                  );
                }
                return (
                  <>
                    <a
                      class="dropdown-item"
                      onClick={() => {
                        localStorage.setItem("@XPM_CLIENT_COMPANY:", item.cnpj);
                        setClientsSelect(item.name);
                      }}
                      href="#"
                    >
                      {item.name}
                    </a>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsSelect;
