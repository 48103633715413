import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { RoutesUrl } from "./routes/routes";
import { ToastContainer } from "react-toastify";
import "animate.css";
import { DashValidation } from "./context/Dashboard/DashContext";
import { ContactedValidation } from "./context/Dashboard/ContactedContext";
import { SchedulesValidation } from "./context/Dashboard/SchedulesContext";
import { GraphsAndParametersValidation } from "./context/Dashboard/GraphsAndParameters";
import { NpsResearchValidation } from "./context/Quality/NpsResearchContext";
import { InternalDocumentsValidation } from "./context/Quality/InternalDocumentsContext";
import { ExternalDocumentsValidation } from "./context/Quality/ExternalDocumentsContext";
import { LimitViewValidation } from "./context/Dashboard/LimitViewContext";

const App = () => {
  return (
    <>
      <DashValidation>
        <LimitViewValidation>
          <ContactedValidation>
            <SchedulesValidation>
              <GraphsAndParametersValidation>
                <NpsResearchValidation>
                  <InternalDocumentsValidation>
                    <ExternalDocumentsValidation>
                      <RoutesUrl />
                    </ExternalDocumentsValidation>
                  </InternalDocumentsValidation>
                </NpsResearchValidation>
              </GraphsAndParametersValidation>
            </SchedulesValidation>
          </ContactedValidation>
        </LimitViewValidation>
      </DashValidation>
      <ToastContainer />
    </>
  );
};

export default App;
