const ModalContainer = ({ children, isActive, closeModal }) => {
    const container = {
        position: "fixed",
        zIndex: 6,
        backgroundColor: "#E5E5E570",
        width: "100%",
        height: "100%",
        top: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    const onCloseModal = (e) => {
        if (e.target.id === "modalContainer") closeModal();
    };

    return (
        isActive && (
            <div id="modalContainer" onClick={onCloseModal} style={container}>
                {children}
            </div>
        )
    );
};

export default ModalContainer;
