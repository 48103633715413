import React, { useState } from "react";
import ControlMenu from "./ControlMenu";
import ScheduleConfig from "./ScheduleConfig";
import BranchConfig from "./BranchConfig";
import { v4 as uuidv4 } from "uuid";

const ModalScheduleConfig = ({
    places,
    setPlaces,
    branches,
    setBranches,
    currentBranch,
    setCurrentBranch,
    closeModal,
    deleteItem,
    closeIcon,
}) => {
    const [menus, setMenus] = useState(["Horários", "Filiais"]);
    const [active, setActive] = useState("Horários");

    const [filteredPlaces, setFilteredPlaces] = useState([]);
    const [newPlaces, setNewPlaces] = useState([]);
    const [editedPlaces, setEditedPlaces] = useState([]);

    const [newBranches, setNewBranches] = useState([]);
    const [editedBranches, setEditedBranches] = useState([]);

    const stylesMenu = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#fff",
        border: "1px solid #D7D7D7",
        borderRadius: "20px",
        width: "450px",
        height: "90%",
        boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.18)",
    };

    const stylesHeaderMenu = {
        alignItems: "center",
        boxSizing: "border-box",
        borderBottom: "1px solid #D7D7D7",
        display: "flex",
        justifyContent: "space-between",
        padding: "20px 15px",
        width: "100%",
    };

    const stylesCloseBtn = {
        background: "none",
        border: "none",
    };

    const stylesEditBtn = {
        background: "#96C",
        borderRadius: 50,
        border: "none",
        color: "#fff",
        padding: "8px 25px",
        height: "90%",
    };

    const submit = () => {
        if (newPlaces.length > 0) {
            // adiciona novos locais
            const newArray = [...places];
            newPlaces.forEach((e) => {
                const newId = uuidv4();
                e.id = newId;
                newArray.push(e);
            });

            setPlaces(newArray);
            setNewPlaces([]);
        }

        if (newBranches.length > 0) {
            const newArray = [...branches];
            newBranches.forEach((e) => {
                console.log(e.name);
                const newId = uuidv4();
                e.id = newId;
                newArray.push(e);
            });

            setBranches(newArray);
            setCurrentBranch(newArray[newArray.length - 1]);
            setNewBranches([]);
        }

        closeModal(false);
    };

    return (
        <main style={stylesMenu}>
            <header style={stylesHeaderMenu}>
                <div
                    style={{
                        display: "flex",
                        gap: "20px",
                        alignItems: "center",
                    }}
                >
                    <button
                        name="closeDeleteMenu"
                        style={stylesCloseBtn}
                        onClick={() => closeModal(false)}
                    >
                        <img src={closeIcon} alt="close" />
                    </button>
                    <p className="textHeaderModalConfig">Configuração</p>
                </div>
                <button style={stylesEditBtn} onClick={() => submit()}>
                    Editar
                </button>
            </header>
            
            <div
                style={{
                    padding: "20px 10px",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                }}
            >
                {menus ? (
                    <section
                        style={{
                            padding: "0px 10px",
                            overflowY: "auto",
                            width: "100%",
                            height: "100%",
                        }}
                        className="scrollLower"
                    >
                        <ControlMenu
                            items={menus}
                            active={active}
                            setActive={setActive}
                        />

                        {active === "Horários" ? (
                            <ScheduleConfig
                                items={places}
                                setItems={setPlaces}
                                branches={branches}
                                currentBranch={currentBranch}
                                setCurrentBranch={setCurrentBranch}
                                filteredPlaces={filteredPlaces}
                                setFilteredPlaces={setFilteredPlaces}
                                newPlaces={newPlaces}
                                setNewPlaces={setNewPlaces}
                                // setNewChecklists={setNewChecklists}
                                editedPlaces={editedPlaces}
                                setEditedPlaces={setEditedPlaces}
                            />
                        ) : null}

                        {active === "Filiais" ? (
                            <BranchConfig
                                items={branches}
                                setItems={setBranches}
                                newBranches={newBranches}
                                setNewBranches={setNewBranches}
                                editedBranches={editedBranches}
                                setEditedBranches={setEditedBranches}
                            />
                        ) : null}
                    </section>
                ) : null}
            </div>
        </main>
    );
};

export default ModalScheduleConfig;
