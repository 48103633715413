import { useNavigate } from "react-router-dom";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import HeaderDashboard from "../components/HeaderDashboard";
import Sidebar from "../../../components/Sidebar";
import ContainerInputSearchAndButton from "../components/ContainerInputSearchAndButton";
import CardDetails from "../components/CardDetails";
import CardInsurance from "../components/CardInsurance";
import api from "../../../services/api";
import { useEffect } from "react";
import { useState } from "react";
import Loading from "../components/Loading";
import createExcelFile from "../../../utils/craeteExcelFile";

const DetailsInsurance = () => {
  const navigate = useNavigate();

  const [safeAll, setSafeAll] = useState([]);
  const [safeDetailsResume, setSafeDetailsResume] = useState({});
  const [safeQtd, setSafeQtd] = useState(0);

  const [safePrice, setSafePrice] = useState(0);
  const [listItens, setListItens] = useState([]);
  const [safeUOM, setSafeUOM] = useState(0);

  const [safeDescription, setSafeDescription] = useState(0);
  const [safePriceUnit, setSafePriceUnit] = useState(0);

  const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
  const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
  const month = localStorage.getItem("@XPM_CLIENT_MONTH:");

  const getInfo = async () => {
    // const id = localStorage.getItem("@XPM_CLIENT_PAGEMENT_DETAILS_ID:");

    try {
      const [safe, safeDetails] = await Promise.all([
        api.get(`/safe/details/complete/${clientCnpj}/${year}/${month}`),
        api.get(`/safe/month/${clientCnpj}/${year}/${month}`),
      ]);

      setSafeAll(safe.data);
      setSafeDetailsResume(safeDetails.data);
    } catch (error) {
    console.log(error)
 }
  };

  useEffect(() => {
    getInfo();
  }, []);

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    marginBottom: "50px",
  };

  const boxCard = {
    display: "flex",
    width: "100%",
    gap: "16px",
  };

  return (
    <ThowColumLayout
      page={"DashboardAdm"}
      colum1={<Sidebar page="Dashboard" />}
      colum2={
        <div style={container}>
          <HeaderDashboard
            back={true}
            name="Fatura seguro"
            adm={true}
            onClick={() => navigate("/dashboard/company")}
          />
          <div style={{ margin: "42px auto 0" }} className="containerBox">
            <ContainerInputSearchAndButton onClick={() => createExcelFile(safeAll, `${`Seguro`} ${year}-${month}`, `${`Seguro`} ${year}-${month}.xlsx`)} />

            <div
              style={{
                display: "flex",
                marginTop: "33px",
                width: "100%",
                flexDirection: "column",
              }}
            >
              {
                safeDetailsResume?.length ? (
                  <CardDetails
                    marginLeft="140px"
                    paddingLeft={"150px"}
                    content={safeDetailsResume[1]?.description}
                    quantity={safeDetailsResume[1]?.qtd_item}
                    UOM={safeDetailsResume[1]?.UOM}
                    priceUnit={safeDetailsResume[1]?.price || 0}
                    total={safeDetailsResume[0]?.sum_all || 0}
                    totalValue={"R$ 11.980,21"}
                  />
                ) : null
              }
              
            </div>

            <div style={boxCard}>
              {
                safeAll?.length ? (
                  <CardInsurance item={safeAll} priceUnit={safeDetailsResume[1]?.price || 0} />
                ) : <Loading />
              }
            </div>
          </div>
        </div>
      }
    />
  );
};

export default DetailsInsurance;
