import React, { useContext } from "react";
import InputCounter from "./InputCounter";
import FormInputText from "./FormInputText";
import DaysOfWeekCard from "./DaysOfWeekCard";
import { useState } from "react";
import { SchedulesContext } from "../../../context/Dashboard/SchedulesContext";

const PlaceConfigCard = ({
    place,
    index,
    handleEdit,
    // handleCreate,
    selectedBranch,
    newItem,
    setNewPlaces
}) => {
    const { createLocation } = useContext(SchedulesContext)

    const [nameState, setNameState] = useState(undefined);
    const [typeState, setTypeState] = useState(undefined);
    const [timeState, setTimeState] = useState(undefined);
    const [start_timeState, setStart_timeState] = useState(undefined);
    const [end_timeState, setEnd_timeState] = useState(undefined);
    const [lunch_start_timeState, setLunch_start_timeState] =
        useState(undefined);
    const [lunch_end_timeState, setLunch_end_timeState] = useState(undefined);
    const [weekDays, setWeekDays] = useState([]);

    const {
        id,
        local_name,
        type,
        time,
        start_time,
        end_time,
        lunch_start_time,
        lunch_end_time,
        week_days,
    } = place;

    const handleCreate = (data, id) => {
        try {
            createLocation(data, id);
            setNameState(undefined);
            setTypeState(undefined);
            setTimeState(undefined);
            setStart_timeState(undefined);
            setEnd_timeState(undefined);
            setLunch_start_timeState(undefined);
            setLunch_end_timeState(undefined);
            setWeekDays([]);
            setNewPlaces([])
        } catch (error) {
            console.log(error);
        }
    };

    useState(() => {
        setNameState(local_name);
        setTypeState(type);
        setTimeState(time);
        setStart_timeState(start_time);
        setEnd_timeState(end_time);
        setLunch_start_timeState(lunch_start_time);
        setLunch_end_timeState(lunch_end_time);
        setWeekDays(week_days);
    }, [
        id,
        local_name,
        type,
        time,
        start_time,
        end_time,
        lunch_start_time,
        lunch_end_time,
        week_days,
    ]);

    const stylesEditBtn = {
        background: "#96C",
        borderRadius: 50,
        border: "none",
        color: "#fff",
        padding: "8px 25px",
        height: "90%",
        marginBottom: "30px",
    };

    return (
        <>
            <div key={place.id} style={{ marginBottom: 10 }}>
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                    <InputCounter
                        number={index + 1}
                        width={34}
                        height={34}
                        color={"#fff"}
                        background={"#96C"}
                        textAlign={"center"}
                        borderRadius={17}
                        border={"none"}
                    />

                    <FormInputText
                        name={"local_name"}
                        width={"90%"}
                        height={"45px"}
                        value={nameState}
                        label={"Nome do lugar"}
                        placeholder={"Escreva o nome"}
                        border={`1px solid #D7D7D7`}
                        borderRadius={"7px"}
                        onChange={(e) => setNameState(e.target.value)}
                    />
                </div>

                <div style={{ display: "flex", gap: 10, width: "100%" }}>
                    <FormInputText
                        name={"type"}
                        width={"50%"}
                        height={"45px"}
                        value={typeState}
                        inputOrSelect={"select"}
                        options={["Selecione", "recebimento", "expedição"]}
                        label={"Tipo"}
                        placeholder={"Tipo de atividade"}
                        border={`1px solid #D7D7D7`}
                        borderRadius={"7px"}
                        onChange={(e) => setTypeState(e.target.value)}
                    />

                    <FormInputText
                        name={"time"}
                        width={"50%"}
                        height={"45px"}
                        value={timeState}
                        label={"Tempo"}
                        placeholder={"Tempo em minutos"}
                        border={`1px solid #D7D7D7`}
                        borderRadius={"7px"}
                        onChange={(e) => setTimeState(e.target.value)}
                    />
                </div>

                <div style={{ display: "flex", gap: 10, width: "100%" }}>
                    <FormInputText
                        name={"start_time"}
                        width={"50%"}
                        height={"45px"}
                        value={start_timeState}
                        label={"Horário de inicio"}
                        placeholder={"Inicio"}
                        border={`1px solid #D7D7D7`}
                        borderRadius={"7px"}
                        onChange={(e) => setStart_timeState(e.target.value)}
                    />

                    <FormInputText
                        name={"end_time"}
                        width={"50%"}
                        height={"45px"}
                        value={end_timeState}
                        label={"Horário de termino"}
                        placeholder={"Termino"}
                        border={`1px solid #D7D7D7`}
                        borderRadius={"7px"}
                        onChange={(e) => setEnd_timeState(e.target.value)}
                    />
                </div>

                <div style={{ display: "flex", gap: 10, width: "100%" }}>
                    <FormInputText
                        name={"lunch_start_time"}
                        width={"50%"}
                        height={"45px"}
                        value={lunch_start_timeState}
                        label={"Horário de inicio almoço"}
                        placeholder={"Horário de almoço"}
                        border={`1px solid #D7D7D7`}
                        borderRadius={"7px"}
                        onChange={(e) =>
                            setLunch_start_timeState(e.target.value)
                        }
                    />

                    <FormInputText
                        name={"lunch_end_time"}
                        width={"50%"}
                        height={"45px"}
                        value={lunch_end_timeState}
                        label={"Horário de termino almoço"}
                        placeholder={"Horário de almoço"}
                        border={`1px solid #D7D7D7`}
                        borderRadius={"7px"}
                        onChange={(e) => setLunch_end_timeState(e.target.value)}
                    />
                </div>

                <DaysOfWeekCard weekDays={weekDays} setWeekDays={setWeekDays} />
            </div>

            <button
                style={stylesEditBtn}
                onClick={() =>
                    newItem
                        ? handleCreate(
                              {
                                  localName: nameState,
                                  type: typeState,
                                  time: timeState,
                                  startTime: start_timeState,
                                  endTime: end_timeState,
                                  lunchStartTime: lunch_start_timeState,
                                  lunchEndTime: lunch_end_timeState,
                                  weekDays: weekDays,
                              },
                              selectedBranch
                          )
                        : handleEdit(
                              {
                                  local_name: nameState,
                                  type: typeState,
                                  time: timeState,
                                  start_time: start_timeState,
                                  end_time: end_timeState,
                                  lunch_start_time: lunch_start_timeState,
                                  lunch_end_time: lunch_end_timeState,
                                  week_days: weekDays,
                              },
                              place.id,
                              selectedBranch
                          )
                }
            >
                {newItem ? "Salvar" : "Editar"}
            </button>
        </>
    );
};

export default PlaceConfigCard;
