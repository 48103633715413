/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useRef, useState } from "react";
import clips from "../../../images/svg/clips.svg";
import send from "../../../images/png/send.png";
import closeIcon from "../../../images/png/cross (1).png";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";
import moment from "moment";

const SendMessage = () => {
  const {
    createdComment,
    editId,
    setEditId,
    editMessage,
    setEditMessage,
    editedComment,
    setHoursUpdate,
    process,
  } = useContext(GraphsAndParametersContext);
  const [sendIcon, setSendIcon] = useState(false);
  const [messageContent, setMessageContent] = useState("");

  const inputFileRef = useRef(null);

  const handleImageClick = () => {
    inputFileRef.current.click();
  };

  const sendMenssageClick = () => {
    setTimeout(() => {
      setSendIcon(false);
    }, 150);
  };

  const sendMenssageClickButton = (e) => {
    if (String(messageContent).length > 0) {
      if (String(editId).length > 0) {
        const data = {
          message: messageContent,
        };
        editedComment(data);
      } else {
        const data = {
          message: messageContent,
          cnpj: localStorage.getItem("@XPM_CLIENT_COMPANY:"),
          process: process,
        };

        createdComment(data);
        setMessageContent("");
      }
    }
    const momentHours = moment().format("DD/MM/YYYY - HH:mm:ss");
    setHoursUpdate(momentHours);
  };

  const sendMenssageEnter = (e) => {
    if (e.key === "Enter") {
      sendMenssageClickButton(e);
    }
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    console.log(file.type);
    const filesPermitted = [
      "image/png",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "image/jpeg",
      "text/plain",
    ];
    console.log(file);
    if (file) {
      if (filesPermitted.includes(file.type)) {
        readFile(file);
      }
    }
  };

  const readFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result;

      const data = {
        message:
          base64String +
          "f5c2e4dc60e9733695a21e6ec74f7c1a8f5" +
          "file:name" +
          file.name,
        cnpj: localStorage.getItem("@XPM_CLIENT_COMPANY:"),
        process,
      };
      createdComment(data);
    };
    reader.onerror = (error) => {
      console.error("Erro ao ler o arquivo:", error);
    };
  };

  const cancelEdit = () => {
    setEditId("");
    setEditMessage("");
  };

  useEffect(() => {
    setMessageContent(editMessage);
  }, [editMessage, editId]);

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          width: "100%",
          border: "1px solid #d7d7d7",
          borderRadius: "8px",
          padding: "8px 10px",
          display: "flex",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <img
          src={clips}
          onClick={handleImageClick}
          style={{ cursor: "pointer" }}
          alt="Selecione um arquivo"
        />

        {/* Input file oculto que é acionado quando a imagem é clicada */}
        <input
          type="file"
          onChange={handleFileInputChange}
          ref={inputFileRef}
          style={{ display: "none" }}
        />

        {editId && (
          <img
            src={closeIcon}
            style={{ width: "20px", cursor: "pointer" }}
            onClick={cancelEdit}
          />
        )}
        <input
          type="text"
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          onClick={() => setSendIcon(true)}
          onBlur={() => sendMenssageClick()}
          onKeyUp={(e) => sendMenssageEnter(e)}
          style={{
            width: "100%",
            outline: "none",
            border: "1px solid #9966CC",
            borderRadius: "4px",
            fontSize: "14px",
            padding: "4px 12px",
          }}
        />
        {sendIcon && (
          <img
            onClick={() => sendMenssageClickButton()}
            src={send}
            style={{
              height: "20px",
              cursor: "pointer",
              filter: "brightness(0)",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SendMessage;
