import { useNavigate } from "react-router-dom";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import HeaderDashboard from "../components/HeaderDashboard";
import Sidebar from "../../../components/Sidebar";
import ContainerInputSearchAndButton from "../components/ContainerInputSearchAndButton";
import CardDetails from "../components/CardDetails";
import CardTableList from "../components/CardTableList";
import { useEffect, useState } from "react";
import ScrollBotton from "../components/ScrollBotton";
import api from "../../../services/api";
import Loading from "../components/Loading";
import createExcelFile from "../../../utils/craeteExcelFile";

const DetailsInvoice = () => {
  const [loading, setLoading] = useState(true);
  const [devolutionAll, setDevolutionAll] = useState([]);
  const [devolutionDetailsResume, setDevolutionDetailsResume] = useState({});
  const [devolutionQtd, setDevolutionQtd] = useState(0);

  const [devolutionPrice, setDevolutionPrice] = useState(0);
  const [listItens, setListItens] = useState([]);
  const [devolutionUOM, setDevolutionUOM] = useState(0);

  const [devolutionDescription, setDevolutionDescription] = useState(0);
  const [devolutionPriceUnit, setDevolutionPriceUnit] = useState(0);

  let div = document.getElementById("test");

  const navigate = useNavigate();

  const clientCnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
  const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
  const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
  const id = localStorage.getItem("@XPM_CLIENT_PAGEMENT_DETAILS_ID:");

  const getInfo = async () => {

    try {
      const [devolution, devolutionDetails] = await Promise.all([
        api.get(`/devolution/details/complete/${clientCnpj}/${id}/${year}/${month}`),
        api.get(`/devolution/month/owner/${clientCnpj}/${year}/${month}/${id}`),
      ]);

      setDevolutionAll(devolution.data);
      setDevolutionDetailsResume(devolutionDetails.data?.filter((e) => e.id === id)[0]);
      setLoading(false);
    } catch (error) {
    console.log(error)
 }
  };

  const getDevolution = async () => {
    if (devolutionDetailsResume.qtd_item) {
      setDevolutionQtd(devolutionDetailsResume.qtd_item.toLocaleString("pt-BR"));
      setDevolutionPrice(devolutionDetailsResume.soma_qtd_item);
      setDevolutionUOM(devolutionDetailsResume.UOM);
      setDevolutionPriceUnit(
        devolutionDetailsResume?.price?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      );
      setDevolutionDescription(devolutionDetailsResume.description);
    } else {
      setDevolutionQtd(0);
      setDevolutionPrice(0);
    }
  };

  const syncScroll = () => {
    div = document.getElementById("test");

    // Iterar sobre os elementos do loop e definir o scroll esquerdo igual ao do elemento principal
    if (listItens && listItens.length) {
      listItens.forEach((item) => {
        let divLoop = document.getElementById(item);
        console.log(divLoop, 8080);

        if (divLoop) {
          divLoop.scrollLeft = div.scrollLeft;
        }
      });
    }
  };

  useEffect(() => {
    const div = document.getElementById("test");
    if (div && listItens) {
      div.addEventListener("scroll", syncScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener("scroll", syncScroll);
      }
    };
  }, [listItens]);

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (devolutionDetailsResume) {
      getDevolution();
    }
  }, [devolutionDetailsResume]);

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    marginBottom: "50px",
  };

  const boxCard = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  };

  return (
    <ThowColumLayout
      page={"DashboardAdm"}
      colum1={<Sidebar page="Dashboard" />}
      colum2={
        <div style={container}>
          <HeaderDashboard
            back={true}
            name="Fatura recebimento"
            adm={true}
            onClick={() => navigate("/dashboard/company")}
          />
          <div style={{ margin: "42px auto 0" }} className="containerBox">
            <ContainerInputSearchAndButton onClick={() => createExcelFile(devolutionAll, `${`Recebimento`} ${year}-${month}`, `${`Recebimento`} ${year}-${month}.xlsx`)} />

            <div
              style={{
                display: "flex",
                marginTop: "33px",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <CardDetails
                marginLeft="140px"
                paddingLeft={"150px"}
                content={devolutionDescription}
                quantity={devolutionQtd}
                UOM={devolutionUOM}
                priceUnit={devolutionPriceUnit}
                total={devolutionPrice}
                totalValue={devolutionPrice}
              />

              {!devolutionAll?.length > 0 ? (<Loading />) : (
                devolutionAll?.map((item, index) => {
                  return (
                    <CardTableList
                      qtdItem={item.qtd_item}
                      qtdPalete={item.qtd_palete_fisico}
                      pesoBruto={item.peso_bruto}
                      pesoLiquido={item.peso_liquido}
                      m3={item.volume_m3}
                      dateOpen={item.dat_hor_abertura_cesv_docum}
                      dateCesv={item.dat_hor_conclusao_conferencia}
                      nameItem={item.den_item_reduz}
                      skuDefault={item.sku_padrao}
                      typeStock={item.tip_estoque}
                      seqAvisoRecebto={item.seq_aviso_recebto}
                      restriction={item.restricao}
                      qtdItemDetail={item.qtd_item}
                      qtdItemSku={item.qtd_item_sku}
                      qtyPhysicalPallet={item.qtd_palete_fisico}
                      qtyDefaultPallet={item.qtd_palete_padrao}
                      pesoBrutoDetails={item.peso_bruto}
                      netWeight={item.peso_liquido}
                      volumeM3={item.volume_m3}
                      categoryItem={item.des_categoria}
                      listItens={listItens}
                      setListItens={setListItens}
                      index={index}
                      codItem={item.item}
                      fiscalNote={item.num_nf}
                    />
                  );
                }))                
              }
              <ScrollBotton width={"1781px"} />
            </div>

            <div style={boxCard}></div>
          </div>
        </div>
      }
    />
  );
};

export default DetailsInvoice;
