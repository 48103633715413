import React, { useEffect, useState } from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../../../components/Sidebar";
import HeaderDashboard from "../components/HeaderDashboard";
import * as moment from "moment";
import "moment/locale/pt-br";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useContext } from "react";
import { DashContext } from "../../../context/Dashboard/DashContext";
import Loading from "../components/Loading";
import CardDateYear from "../components/CardDateYear";
import CardCompanyBPO from "../components/CardCompanyBPO";
import FiltroDropDown from "../components/FiltroDropDown";
import InputSearch from "../components/InputSearch";

const BPO = (
  {
    dateNotFormactApi,
    setDateNotFormactApi,
    btnIsActive,
  }
) => {
  const {
    updateFunction,
    billing,
    setBilling,
    waitingConference,
    awaitingPayment,
    awaitingPaymentVirtual,
    paymentConcluid,
    getDiscount,
    getTaxes,
    getVariables,
    minimumBilling,
    setMinimumBilling,
    variables,
    setVariables,
    porcent,
    setPorcent,
    getPorcent,
    getManualBilling,
    manualBilling,
    setManualBilling,
    updateManualBilling,
    fixedMonthlyValue,
    setFixedMonthlyValue,
    getGeneralities,
    getFixedGeneralities,
    permission,
    taxesArray,
  } = useContext(DashContext);

  const [loading, setLoading] = useState(true);
  const [filteredBranch, setFilteredBranch] = useState("");
  const [clientsAll, setClientsAll] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [billingsAll, setBillingsAll] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [filteredStatus, setFilteredStatus] = useState("");
  const [writtenDate, setWrittenDate] = useState("");
  const [dateApi, setDateApi] = useState("");
  const [biggestDateLimit, setBiggestDateLimit] = useState(false);
  const [shorttestDateLimit, setShorttestDateLimit] = useState(false);
  const [dateNotFormact, setDateNotFormact] = useState('');
  const [dateMoment, setDateMoment] = useState('');

  const navigate = useNavigate();

  const CLIENT_BRANCHS = [
    "Unidade Matriz Joinville - SC",
    "Unidade IN HOUSE Joinville - SC",
    "Unidade Filial Barueri - SP",
  ];

  const BILLING_STATUS = [
    // "Todos os status",
    // "Aguardando aprovação",
    "Aguardando faturamento",
    "Faturado",
  ]

  const getDate = () => {
    let year = localStorage.getItem('@XPM_CLIENT_YEAR:');
    let month = localStorage.getItem('@XPM_CLIENT_MONTH:');
    let date = `${year}-${month}`;
    if (year && month) {
      setWrittenDate(moment(date).format('MMMM _ YYYY'));
      setDateApi(moment(date).format('YYYY-MM-DD'));
      return date;
    } else {
      year = moment().format('YYYY');
      month = moment().format('MM');
      date = `${year}-${month}`;
      setWrittenDate(moment(date).format('MMMM _ YYYY'));
      setDateApi(moment(date).format('YYYY-MM-DD'));
      localStorage.setItem('@XPM_CLIENT_YEAR:', year);
      localStorage.setItem('@XPM_CLIENT_MONTH:', month);
      return date;
    };
  };
  
  const addMonth = () => {
    const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
    const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
    const biggestDate = moment().add(1, 'month');
    const sameMonthYear = biggestDate.isSame(dateNotFormactApi, 'month') && biggestDate.isSame(dateNotFormactApi, 'year');
    if (sameMonthYear) {
      setBiggestDateLimit(true);
    } else {      
      setBiggestDateLimit(false);
    }
    if (year && month && !sameMonthYear) {
      const numericYear = parseInt(year, 10);
      const numericMonth = parseInt(month, 10);
      console.log(numericYear, numericMonth);
      const prevSelectectedDate = moment().set({ month: numericMonth - 1, year: numericYear });
      console.log(prevSelectectedDate);
      setDateNotFormact(prevSelectectedDate.add(1, "month"));
      console.log('avançou', prevSelectectedDate);
      setDateMoment(prevSelectectedDate.format("MMMM _ YYYY"));
      setDateNotFormactApi(prevSelectectedDate.format('YYYY-MM-DD'));
      setShorttestDateLimit(false);
      updateFunction();
    }
  };

  const backMonth = () => {
    const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
    const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
    const shotestDate = moment('04-2024', 'MM-YYYY');
    const sameMonthYear = shotestDate.isSame(dateNotFormactApi, 'month') && shotestDate.isSame(dateNotFormactApi, 'year');
    if (sameMonthYear) {
      setShorttestDateLimit(true);
    } else {
      setShorttestDateLimit(false);
    }
    if (year && month && !sameMonthYear) {
      const numericYear = parseInt(year, 10);
      const numericMonth = parseInt(month, 10);
      console.log(numericYear, numericMonth);
      const prevSelectectedDate = moment().set({ month: numericMonth - 1, year: numericYear });
      console.log(prevSelectectedDate);
      setDateNotFormact(prevSelectectedDate.subtract(1, "month"));
      console.log('avançou', prevSelectectedDate);
      setDateMoment(prevSelectectedDate.format("MMMM _ YYYY"));
      setDateNotFormactApi(prevSelectectedDate.format('YYYY-MM-DD'));
      setBiggestDateLimit(false);
      updateFunction();
    }
  };

  const getClients = async () => {
    try {
      const response = await api.get("/client/account");
      const sortedClients = response?.data?.sort((a, b) => {
        if (a.client.trim() < b.client.trim()) {
          return -1;
        }
        if (a.client > b.client) {
          return 1;
        }
        return 0;
      });
      setClientsAll(sortedClients || []);
      setLoading(false);
    } catch (error) {
      console.log('error', error)
    }
  };

  const getBillings = async () => {
    try {
      const response = await api.get(`/billing/admin/${getDate()}`);
      setBillingsAll(response.data.filter((e) => e.checked_by_client));
      // setBillingsAll(response.data)
      setLoading(false);
    } catch (error) {
      console.log('getBillings', error)
    };
  };

  const filterByBranch = () => {
    const filtered = clientsAll.filter((e) => (
      billingsAll.some((billing) => billing.client_cnpj === e.cnpj)
    ));
    setFilteredClients(filtered);
  };

  const filterByName = () => {
    const filtered = clientsAll.filter((e) => (
      e.client.trim().toLowerCase().includes(searchName.toLowerCase()) &&
      billingsAll.some((billing) => billing.client_cnpj === e.cnpj)
    ));
    setFilteredClients(filtered);
  };



  const filterByStatus = () => {
    // if (filteredStatus ===  "Aguardando conferência") {
    //   const filtered = clientsAll.filter((e) => (
    //     e.client.trim().toLowerCase().includes(searchName.toLowerCase()) &&
    //     billingsAll.some((billing) => billing.client_cnpj === e.cnpj &&
    //     !billing.checked_by_admin)
        
    //   ));
    //   setFilteredClients(filtered);
    // }
    if (filteredStatus ===  "Aguardando aprovação") {
      const filtered = clientsAll.filter((e) => (
        e.client.trim().toLowerCase().includes(searchName.toLowerCase()) &&
        billingsAll.some((billing) => billing.client_cnpj === e.cnpj &&
        !billing.checked_by_client && billing.checked_by_admin)
        
      ));
      setFilteredClients(filtered);
    }
    if (filteredStatus ===  "Aguardando faturamento") {
      const filtered = clientsAll.filter((e) => (
        e.client.trim().toLowerCase().includes(searchName.toLowerCase()) &&
        billingsAll.some((billing) => billing.client_cnpj === e.cnpj &&
        !billing.invoiced && billing.checked_by_admin && billing.checked_by_client)
        
      ));
      setFilteredClients(filtered);
    }
    if (filteredStatus ===  "Faturado") {
      const filtered = clientsAll.filter((e) => (
        e.client.trim().toLowerCase().includes(searchName.toLowerCase()) &&
        billingsAll.some((billing) => billing.client_cnpj === e.cnpj &&
        billing.invoiced)
        
      ));
      setFilteredClients(filtered);
    }
    if (!filteredStatus || filteredStatus ===  "Todos os status") {
      const filtered = clientsAll.filter((e) => (
        e.client.trim().toLowerCase().includes(searchName.toLowerCase()) &&
        billingsAll.some((billing) => billing.client_cnpj === e.cnpj)        
      ));
      setFilteredClients(filtered);
    }
  };

  useEffect(() => {
    setLoading(true);
    getClients();
    getBillings();
  }, [writtenDate, dateApi])

  useEffect(() => {
    if (billingsAll?.length > 0 && clientsAll?.length > 0) {
      filterByBranch();
    }
  }, [billingsAll, clientsAll]);

  useEffect(() => {
    filterByName();
  }, [searchName])

  useEffect(() => {
    filterByStatus();
  }, [filteredStatus])

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    paddingBottom: "50px",
    paddingLeft: "50px",
    paddinRight: "50px",
  };

  return (
    <ThowColumLayout
      page={"Company"}
      colum1={<Sidebar page="Company" />}
      colum2={
        <main style={container}>
          <HeaderDashboard
            name={"Faturamento BPO"}
            adm={true}
          />
          <section
            id="page-controller"
            style={{display: "flex", width: "100%", alignItems: "center", marginBottom: '40px', marginTop: '40px', gap: 10}}
          >
            <InputSearch
              onChange={setSearchName}
            />

            <CardDateYear
              isActive={btnIsActive}
              add={() => addMonth()}
              back={() => backMonth()}
              date={dateMoment}
              dateApi={dateNotFormactApi}
              biggestDate={biggestDateLimit}
              shorttestDate={shorttestDateLimit}
            />

            <FiltroDropDown
              itemsArray={CLIENT_BRANCHS}
              filteredItem={filteredBranch}
              onClick={setFilteredBranch}
              placeholder="Todas as unidades"
            />

            <FiltroDropDown
              itemsArray={BILLING_STATUS}
              filteredItem={filteredStatus}
              onClick={setFilteredStatus}
              placeholder="Todos os status"
            />
            
          </section>
          {
            !loading ? (
              <section
                id="companies-table"
                style={{display:'flex', width: '100%', gap: '10px', flexWrap: 'wrap'}}
              >
                {
                  filteredClients?.length && billingsAll?.length ? (
                    filteredClients.filter((e) => {
                        return filteredBranch ? (e.branch === filteredBranch) : (e.branch !== null)
                      }
                    )
                    .map((e) => (
                        <CardCompanyBPO
                          client={e}
                          billing={billingsAll.filter((b) => b.client_cnpj === e.cnpj)[0]}
                        />
                      )
                    )
                  ) : <p>Nenhuma ordem de serviço encontrada</p>
                }
              </section>
            ) : <Loading />
          }
        </main>
      }
    />
  );
};

export default BPO;
