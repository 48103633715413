import React from "react";
import Button from "./Button";
import Input from "./Input";
import Select from "./Select";
import { options } from "./mocks/SelectGenericOptions";
import ResponsibleBoxCreateExternalDocumentModal from "./ResponsibleBoxCreateExternalDocumentModal";
import PermissionsBoxCreateExternalDocumentModal from "./PermissionsBoxCreateExternalDocumentModal";
import DocumentBoxModal from "./DocumentBoxModal";

const ModalCreateDocument = ({
  closeModal,
  closeIcon,
}) => {

  const container = {
    display: "flex",
    flexDirection: "column",
    background: "var(--white)",
    border: "1px solid var(--grey-0)",
    borderRadius: "20px",
    width: "881px",
    height: "704px",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.18)",
  };

  const stylesHeaderMenu = {
    alignItems: "center",
    boxSizing: "border-box",
    borderBottom: "1px solid var(--grey-0)",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    width: "100%",
  };

  const stylesCloseBtn = {
    background: "none",
    border: "none",
    marginRight: "16px",
  };

  const styleTitle = {
    color: "var(--black)",
    fontSize: "18px",
    fontWeight: 500,
  };

  const styleContentBox = {
    padding: "20px",
    overflowY: "auto",
  };

  const styleInfoDocumentsBox = {
    borderBottom: "1px solid var(--grey-0)",
  };

  const styleInfoDocumentsItemsBox = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }

  return (
    <div style={container}>
      <header style={stylesHeaderMenu}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            name="closeDeleteMenu"
            style={stylesCloseBtn}
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} alt="close.png" />
          </button>
          <p style={styleTitle}>Criar Documento interno</p>
        </div>
        <Button
          width="188px"
          height="36px"
          background="var(--color-primary)"
          color="var(--white)"
          borderRadius="60px"
          marginTop="0"
          name="Salvar documento"
          border="none"
        />
      </header>
      <div style={styleContentBox}>
        <div style={styleInfoDocumentsBox}>
          <div style={styleInfoDocumentsItemsBox}>
            <Input
              name="Título*"
              width="362px"
            />
            <Select
              label="Processo*"
              width="202px"
              options={options}
            />
            <Select
              label="Distribuição*"
              width="202px"
              options={options}
            />
          </div>
          <div style={styleInfoDocumentsItemsBox}>
            <Input
              name="Unidade*"
              width="362px"
            />
            <Input
              name="Localização*"
              width="202px"
            />
            <Select
              label="Associar a outros DOCs"
              width="202px"
              options={options}
            />
          </div>
        </div>

        <ResponsibleBoxCreateExternalDocumentModal />
        <PermissionsBoxCreateExternalDocumentModal />
        <DocumentBoxModal />

        {/* No componente AttachmentBoxForModal está incluída a função para abrir a galeria e anexar arquivos do coputador.  */}
        {/* <AttachmentBoxForModal /> */}
        {/* <TextEditorBoxModal /> */}
      </div>
    </div>
  );
};

export default ModalCreateDocument;
