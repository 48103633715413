import { useContext, useEffect, useState } from "react";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";
import moment from "moment";

const ModalHolidays = ({ ModalHolidays }) => {
  const { getYearsHolidays, getFilterYearsHolidays } = useContext(
    GraphsAndParametersContext
  );

  const [years, setYears] = useState([]);
  const [yearsHolidays, setYearsHolidays] = useState([]);

  const getYears = async () => {
    const searchYears = await getYearsHolidays(moment().year());

    setYears(searchYears);
  };

  const getDates = async (year) => {
    const searchDates = await getFilterYearsHolidays(year);

    console.log(searchDates);
    setYearsHolidays(searchDates);
  };

  useEffect(() => {
    getYears();
  }, []);

  return (
    <div
      style={{
        width: "650px",
        height: "450px",
        backgroundColor: "#fff",
        border: "1px solid #d7d7d7",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <h3 className="textHolidaysTitleHeader">
        Tabela de feriados e fins de semana
      </h3>
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <label
          className="textHolidaysTitle"
          style={{ display: "flex", gap: "10px" }}
        >
          Selecione o ano:
          <select
            onChange={(e) => getDates(e.target.value)}
            className="textHolidaysContent"
            style={{
              outline: "none",
              border: "none",
              borderBottom: "1px solid",
            }}
          >
            <option value={2000}>Selecione</option>
            {years?.map((elem) => {
              return (
                <option key={elem.year} value={elem.year}>
                  {elem.year}
                </option>
              );
            })}
          </select>
        </label>
      </div>
      <div style={{ height: "80%", overflowY: "scroll" }}>
        <table style={{ width: "98%", maxHeight: "100%" }}>
          <thead>
            <tr>
              <th className="textHolidaysContentBold">Datas</th>
              <th className="textHolidaysContentBold">Motivo</th>
              <th className="textHolidaysContentBold">Dias da semana</th>
            </tr>
          </thead>
          <tbody>
            {yearsHolidays?.map((elem, index) => (
              <tr key={index}>
                <td className="textHolidaysContent">{elem.date}</td>
                <td className="textHolidaysContent">{elem.reason}</td>
                <td className="textHolidaysContent">{elem.dayOfWeek}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ModalHolidays;
