import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "./Button";
import AttachmentIcon from "../../../images/png/arquivo.png";
import CloseIcon from "../../../images/svg/closeIcon.svg";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";

const ModalAttachment = ({ title, type, closeModal }) => {
  const { createAnexo, getAnexo, deleteAnexo } = useContext(
    GraphsAndParametersContext
  );
  const [month, setMonth] = useState();
  const [year, setYear] = useState();
  const [attachment, setAttachment] = useState();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [attachmentTitle, setAttachmentTitle] = useState("");
  const fileInputRef = useRef(null);
  // Criando um array com os meses formatados
  const months = moment.months().map((month, index) => ({
    label: month.charAt(0).toUpperCase() + month.slice(1),
    value: index + 1,
  }));

  // Criando um array de anos
  const currentYear = moment().year();
  const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

  const initialAttachments = Array.from({ length: 50 }, (_, index) => ({
    type: `type${index + 1}`,
    url: `https://example.com/file${index + 1}.pdf`,
    name: `Arquivo anexo xpm ${index}`,
  }));

  const styles = {
    modalContainer: {
      width: "700px",
      height: "510px",
      backgroundColor: "#fff",
      border: "1px solid #d7d7d7",
      borderRadius: "8px",
    },
    modalHeader: {
      padding: "15px",
      borderBottom: "1px solid #d7d7d7",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    title: {
      fontSize: "22px",
    },
    closeButton: {
      width: 30,
      cursor: "pointer",
      textAlign: "center",
    },
    filterContainer: {
      padding: "15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "end",
    },
    filterSelectContainer: {
      display: "flex",
      gap: "30px",
    },
    selectBox: {
      border: "none",
      borderBottom: "1px solid rgb(169 168 168)",
      outline: "none",
    },
    buttonGroup: {
      display: "flex",
      gap: "10px",
    },
    attachmentContainer: {
      width: "100%",
      borderTop: "1px solid #d7d7d7",
      padding: "10px 15px",
    },
    attachmentHeaderTitle: {
      display: "flex",
      gap: "20px",
      alignItems: "center",
    },
    attachmentTitle: {
      fontSize: "15px",
      marginBottom: "15px",
      fontWeight: 500,
    },
    attachmentContent: {
      fontSize: "15px",
      marginBottom: "15px",
    },
    attachmentGrid: {
      display: "flex",
      flexWrap: "wrap",
      height: "285px",
      overflowY: "auto",
      gap: "20px",
    },
    attachmentItem: {
      position: "relative",
      marginTop: "5.9px",
      height: "110px",
      textDecoration: "none",
      color: "#000",
    },
    attachmentImage: {
      width: "90px",
    },
    attachmentName: {
      maxWidth: "90px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: "13px",
      fontWeight: 500,
    },
    attachmentType: {
      position: "absolute",
      bottom: 35,
      right: 8,
      backgroundColor: "var(--color-primary)",
      color: "#fff",
      padding: "4px 7px",
      borderRadius: "8px",
      fontSize: "7px",
    },
    closeIcon: (isVisible) => ({
      position: "absolute",
      top: -6,
      right: 1,
      color: "var(--color-primary)",
      backgroundColor: "#fff",
      padding: "1px 8px",
      borderRadius: "60px",
      fontSize: "15px",
      fontWeight: 600,
      border: "2px solid var(--color-primary)",
      cursor: "pointer",
      display: isVisible ? "block" : "none", // Mostra ou esconde com base no estado `isVisible`
    }),
  };

  // Função para truncar o nome do arquivo
  const truncateName = (name, maxLength) => {
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + "..."; // Trunca o nome e adiciona "..."
    }
    return name; // Retorna o nome como está se for menor que o limite
  };

  const getAnexos = async () => {
    const client = localStorage.getItem("@XPM_CLIENT_COMPANY:");
    const anexos = await getAnexo({ month, year, type, client });

    console.log(anexos);
    if (anexos?.length) setAttachment(anexos);
    else if (!anexos?.length) setAttachment([]);
  };

  const destroyAnexo = async (id) => {
    const client = localStorage.getItem("@XPM_CLIENT_COMPANY:");
    const anexos = await deleteAnexo(id, { month, year, type, client });

    if (anexos?.length) setAttachment(anexos);
    else if (!anexos?.length) setAttachment([]);
  };

  const handleClick = () => {
    // Disparar o evento de clique no input file
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = async () => {
        const base64String = reader.result.split(",")[1]; // Converte para base64
        const client = localStorage.getItem("@XPM_CLIENT_COMPANY:");

        // Montando o objeto
        const fileObject = {
          fileString: base64String,
          fileType: file.type,
          fileName: file.name,
          typeAttachments: type,
          date: `${month}/${year}`,
          client,
        };

        const response = await createAnexo(fileObject, {
          month,
          year,
          type,
          client,
        });

        if (response?.length) setAttachment(response);
      };

      reader.readAsDataURL(file); // Converte o arquivo para Data URL (base64)
    }
  };

  useEffect(() => {
    setMonth(moment().month() + 1);
    setYear(moment().year());
    // setAttachment(initialAttachments);
  }, []);

  return (
    <div style={styles.modalContainer}>
      <div style={styles.modalHeader}>
        <h3 style={styles.title}>Anexos - {title}</h3>
        <div style={styles.closeButton}>
          <img src={CloseIcon} onClick={closeModal} />
        </div>
      </div>
      <div style={styles.filterContainer}>
        <div style={styles.filterSelectContainer}>
          <label className="textTitleFilterContacted">
            Mês
            <select
              style={styles.selectBox}
              onChange={(e) => setMonth(e.target.value)}
              value={month}
            >
              {months.map(({ label, value, index }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </label>
          <label className="textTitleFilterContacted">
            Ano
            <select
              style={styles.selectBox}
              onChange={(e) => setYear(e.target.value)}
              value={year}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div style={styles.buttonGroup}>
          <Button
            padding={"0 20px"}
            height="35px"
            background={"var(--color-primary)"}
            borderRadius="8px"
            marginTop="0"
            name={"Anexar"}
            color={"#fff"}
            border="none"
            onClick={handleClick}
          />
          <Button
            padding={"0 20px"}
            height="35px"
            background={"var(--color-primary)"}
            borderRadius="8px"
            marginTop="0"
            name={"Buscar"}
            color={"#fff"}
            border="none"
            onClick={() => getAnexos()}
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>
      </div>
      <div style={styles.attachmentContainer}>
        <div style={styles.attachmentHeaderTitle}>
          <p style={styles.attachmentTitle}>Nome do arquivo:</p>
          <p style={styles.attachmentContent}>{attachmentTitle}</p>
        </div>
        <div style={styles.attachmentGrid}>
          {attachment?.map((elem, index) => (
            <a
              style={styles.attachmentItem}
              key={index}
              onMouseEnter={() => {
                setHoveredIndex(index);
                setAttachmentTitle(elem.name);
              }}
              onMouseLeave={() => {
                setHoveredIndex(null);
                setAttachmentTitle("");
              }}
            >
              <a href={elem?.url} target="_blank">
                <img src={AttachmentIcon} style={styles.attachmentImage} />
              </a>
              <p style={styles.attachmentType}>{elem.type_file}</p>
              <p style={styles.attachmentName}>{truncateName(elem.name, 10)}</p>
              <p
                onClick={() => destroyAnexo(elem.id)}
                style={styles.closeIcon(hoveredIndex === index)}
              >
                x
              </p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModalAttachment;
