import { useContext, useEffect, useState } from "react";
import InputsPrime from "../../../components/InputsPrime";
import Button from "./Button";
import moment from "moment";
import { LimitViewContext } from "../../../context/Dashboard/LimitViewContext";

const ModalLimityView = ({ closeModal }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isCurrentDate, setIsCurrentDate] = useState(false);
  const [typeLimit, setTypeLimit] = useState("");
  const [limits, setLimits] = useState([]);

  const { onCreateLimit, limitsClient, onGetLimit, alreadyRequestLimits } =
    useContext(LimitViewContext);

  const handleCheckboxChange = () => {
    if (!isCurrentDate) setEndDate("");
    setIsCurrentDate(!isCurrentDate);
    handleLimitChange(); // Atualiza o limite ao alterar o checkbox
  };

  const handleLimitChange = () => {
    const client_cnpj = localStorage.getItem("@XPM_CLIENT_COMPANY:");
    console.log(startDate);
    if (startDate) {
      const newLimit = {
        limit_type: typeLimit,
        start_date: startDate
          ? moment(startDate, "YYYY-MM-DD").format("DD/MM/YYYY")
          : null,
        end_date: !endDate
          ? null
          : moment(endDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
        client_cnpj,
      };

      setLimits((prevLimits) => {
        const index = prevLimits.findIndex(
          (item) => item.limit_type === typeLimit
        );
        if (index !== -1) {
          const updatedLimits = [...prevLimits];
          updatedLimits[index] = newLimit;
          return updatedLimits;
        } else {
          return [...prevLimits, newLimit];
        }
      });
    } else {
      const newLimit = {
        limit_type: typeLimit,
        start_date: null,
        end_date: null,
        client_cnpj,
      };

      setLimits((prevLimits) => {
        const index = prevLimits.findIndex(
          (item) => item.limit_type === typeLimit
        );
        if (index !== -1) {
          const updatedLimits = [...prevLimits];
          updatedLimits[index] = newLimit;
          return updatedLimits;
        } else {
          return [...prevLimits, newLimit];
        }
      });
    }
  };

  const handleTypeLimitChange = (e) => {
    const newTypeLimit = e.target.value;
    setTypeLimit(newTypeLimit);
    loadDefaultValues(newTypeLimit); // Carrega os valores predefinidos para o tipo selecionado
  };

  const loadDefaultValues = (type) => {
    const limit = limits.find((item) => item.limit_type === type);

    if (limit) {
      setStartDate(
        limit.start_date
          ? moment(limit.start_date, "DD/MM/YYYY").format("YYYY-MM-DD")
          : ""
      );
      setEndDate(
        limit.end_date
          ? moment(limit.end_date, "DD/MM/YYYY").format("YYYY-MM-DD")
          : ""
      );
      setIsCurrentDate(!limit.end_date); // Se não houver data final, o checkbox deve estar marcado
    } else {
      // Limpar os campos se não houver valores predefinidos
      setStartDate("");
      setEndDate("");
      setIsCurrentDate(false);
    }
  };

  const handleSave = () => {
    handleLimitChange(); // Certifique-se de que o limite é adicionado ou atualizado

    // Dados a serem enviados
    const data = limits;

    onCreateLimit(data, closeModal);
  };

  const handleDateChange = (date, setDate) => {
    setDate(date);
  };

  useEffect(() => {
    handleLimitChange();
  }, [startDate]);

  useEffect(() => {
    handleLimitChange();
  }, [endDate]);

  useEffect(() => {
    // if (!alreadyRequestLimits) {
    // }
    onGetLimit();
  }, [alreadyRequestLimits]);

  useEffect(() => {
    if (limitsClient.length) {
      const convertedLimits = limitsClient?.map((limit) => ({
        ...limit,
        start_date: limit?.start_date
          ? moment(limit.start_date).format("DD/MM/YYYY")
          : null,
        end_date: limit?.end_date
          ? moment(limit.end_date).format("DD/MM/YYYY")
          : null,
      }));

      setLimits(convertedLimits);
    }
  }, [limitsClient]);

  useEffect(() => {
    console.log(startDate);

    if (!startDate.length) setEndDate("");
  }, [startDate]);

  const styles = {
    container: {
      width: "700px",
      height: "400px",
      backgroundColor: "#fff",
      border: "1px solid #d7d7d7",
      borderRadius: "8px",
    },
    header: {
      borderBottom: "1px solid #d7d7d7",
    },
    headerContent: {
      padding: "20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    boxContent: {
      padding: "10px",
      display: "flex",
      gap: "20px",
      flexDirection: "column",
    },
    contentInputs: {
      display: "flex",
      alignItems: "end",
      gap: "20px",
    },
    label: {
      display: "flex",
      gap: "10px",
      flexDirection: "column",
    },
    labelSelect: {
      display: "flex",
      gap: "10px",
      flexDirection: "column",
      alignItems: "start",
    },
    input: {
      padding: "5px 7px",
      outline: "none",
      border: "1px solid #d7d7d7",
      borderRadius: "8px",
      width: "100%",
    },
    select: {
      padding: "5px 7px",
      outline: "none",
      border: "1px solid #d7d7d7",
      borderRadius: "8px",
      width: "100%",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.headerContent}>
          <h5 className="textTitleModalLimit">Configurações do cliente</h5>
          <Button
            padding={"0 20px"}
            height="35px"
            background={"var(--color-primary)"}
            borderRadius="8px"
            marginTop="0"
            name={"Salvar"}
            color={"#fff"}
            border="none"
            fontSize={"15px"}
            onClick={handleSave}
          />
        </div>
      </div>

      <div style={{ padding: "10px" }}>
        <div style={styles.boxContent}>
          <p className="textTitleInputsLimit">Visualização de informação </p>

          <div style={styles.contentInputs}>
            <label style={styles.labelSelect} className="textLabelModalView">
              Área de limite de visualização
              <select
                style={styles.select}
                value={typeLimit}
                onChange={handleTypeLimitChange}
              >
                <option value="">Selecione</option>
                <option value="GERAL">Geral</option>
                <option value="INDICADORES_DE_PERFORMANCE">
                  Indicadores de Performance
                </option>
                <option value="COMENTARIOS">Comentários</option>
                <option value="CONTRATO_VS_REALIZADO">
                  Contrato vs Realizado
                </option>
              </select>
            </label>
          </div>

          <div style={styles.contentInputs}>
            <label style={styles.label} className="textLabelModalView">
              Limite inicial da visualização
              <input
                disabled={!typeLimit?.length}
                style={styles.input}
                type="date"
                onChange={(e) => handleDateChange(e.target.value, setStartDate)}
                value={startDate}
              />
            </label>

            <label style={styles.label} className="textLabelModalView">
              Limite final da visualização
              <input
                type="date"
                style={styles.input}
                onChange={(e) => handleDateChange(e.target.value, setEndDate)}
                value={endDate}
                min={moment(startDate).add(1, "days").format("YYYY-MM-DD")}
                disabled={!typeLimit?.length || isCurrentDate}
              />
            </label>

            <label className="textLabelModalView">
              <input
                type="checkbox"
                className="custom-checkbox"
                checked={isCurrentDate}
                onChange={handleCheckboxChange}
              />
              Até data atual
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLimityView;
