import { useContext, useEffect, useState } from "react";

import CardCommentsMessage from "./CardCommentsMessage";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";

const ContentChat = () => {
  const { commentsAll, getCommentsAll } = useContext(
    GraphsAndParametersContext
  );

  useEffect(() => {
    getCommentsAll();
  }, []);

  useEffect(() => {
    const element = document.getElementById("scroll_comment5");
    const scrollHeight = element.scrollHeight;
    element.scrollBy({
      top: scrollHeight,
    });
  }, [commentsAll]);

  return (
    <div
      id="scroll_comment5"
      style={{
        padding: "10px 12px",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        overflowX: "auto",
        overflowX: "hidden",
        height: "100%",
      }}
    >
      {commentsAll?.length > 0 ? (
        commentsAll.map((elem, i) => {
          return (
            <div>
              <CardCommentsMessage elem={elem} i={i} messages={commentsAll} />
            </div>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default ContentChat;
