import { useNavigate } from "react-router-dom";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import HeaderDashboard from "../components/HeaderDashboard";
import Sidebar from "../../../components/Sidebar";
import ContainerInputSearchAndButton from "../components/ContainerInputSearchAndButton";
import CardDetails from "../components/CardDetails";
import CardTableList from "../components/CardTableList";
import { useEffect, useState } from "react";
import ScrollBotton from "../components/ScrollBotton";
import api from "../../../services/api";
import Loading from "../components/Loading";
import createExcelFile from "../../../utils/craeteExcelFile";

const DetailsInvoice = () => {
  const [loading, setLoading] = useState(true);
  const [receiptsAll, setReceiptsAll] = useState([]);
  const [receiptsDetailsResume, setReceiptsDetailsResume] =
    useState({});
  const [receiptsQtd, setReceiptsQtd] = useState(0);

  const [receiptsPrice, setReceiptsPrice] = useState(0);
  const [listItens, setListItens] = useState([]);
  const [receiptsUOM, setReceiptsUOM] = useState(0);

  const [receiptsDescription, setReceiptsDescription] =
    useState(0);
  const [receiptsPriceUnit, setReceiptsPriceUnit] = useState(0);

  let div = document.getElementById("test");
  let div2 = document.getElementById("test2");
  let div3 = document.getElementById("test3");
  let div4 = document.getElementById("test4");

  const navigate = useNavigate();

  const clientCnpj = localStorage.getItem(
    "@XPM_CLIENT_COMPANY:"
  );
  const year = localStorage.getItem("@XPM_CLIENT_YEAR:");
  const month = localStorage.getItem("@XPM_CLIENT_MONTH:");
  const id = localStorage.getItem(
    "@XPM_CLIENT_PAGEMENT_DETAILS_ID:"
  );

  const getInfo = async () => {
    try {
      const [receipts, receiptsDetails] = await Promise.all([
        api.get(
          `/receipts/details/complete/${clientCnpj}/${id}/${year}/${month}`
        ),
        api.get(
          `/receipts/month/owner/${clientCnpj}/${year}/${month}/${id}`
        ),
      ]);

      setReceiptsAll(receipts.data);
      setReceiptsDetailsResume(receiptsDetails.data?.filter((e) => e.id === id)[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getReceipts = async () => {
    if (receiptsDetailsResume.qtd_item) {
      setReceiptsQtd(receiptsDetailsResume.qtd_item.toLocaleString("pt-BR"));
      setReceiptsPrice(receiptsDetailsResume.soma_qtd_item);
      setReceiptsUOM(receiptsDetailsResume.UOM);
      setReceiptsPriceUnit(
        receiptsDetailsResume?.price?.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      );
      setReceiptsDescription(receiptsDetailsResume.description);
    } else {
      setReceiptsQtd(0);
      setReceiptsPrice(0);
    }
  };

  const syncScroll = () => {
    div = document.getElementById("test");

    // Iterar sobre os elementos do loop e definir o scroll esquerdo igual ao do elemento principal
    if (listItens && listItens.length) {
      listItens.forEach((item) => {
        let divLoop = document.getElementById(item);
        console.log(divLoop, 8080);

        if (divLoop) {
          divLoop.scrollLeft = div.scrollLeft;
        }
      });
    }
  };

  useEffect(() => {
    const div = document.getElementById("test");
    if (div && listItens) {
      div.addEventListener("scroll", syncScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener("scroll", syncScroll);
      }
    };
  }, [listItens]);

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    if (receiptsDetailsResume) {
      getReceipts();
    }
  }, [receiptsDetailsResume]);

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    marginBottom: "50px",
  };

  const boxCard = {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  };

  return (
    <ThowColumLayout
      page={"DashboardAdm"}
      colum1={<Sidebar page="Dashboard" />}
      colum2={
        <div style={container}>
          <HeaderDashboard
            back={true}
            name="Fatura recebimento"
            adm={true}
            onClick={() => navigate("/dashboard/company")}
          />
          <div
            style={{ margin: "42px auto 0" }}
            className="containerBox"
          >
            <ContainerInputSearchAndButton
              onClick={() =>
                createExcelFile(
                  receiptsAll,
                  `${`Recebimento`} ${year}-${month}`,
                  `${`Recebimento`} ${year}-${month}.xlsx`
                )
              }
            />

            <div
              style={{
                display: "flex",
                marginTop: "33px",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <CardDetails
                marginLeft="140px"
                paddingLeft={"150px"}
                content={receiptsDescription}
                quantity={receiptsQtd}
                UOM={receiptsUOM}
                priceUnit={receiptsPriceUnit}
                total={receiptsPrice}
                totalValue={receiptsPrice}
              />

              {!receiptsAll?.length > 0 ? (
                <Loading />
              ) : (
                receiptsAll?.map((item, index) => {
                  return (
                    <CardTableList
                      qtdItem={item.qtd_item}
                      qtdPalete={item.qtd_palete_fisico}
                      pesoBruto={item.peso_bruto}
                      pesoLiquido={item.peso_liquido}
                      m3={item.volume_m3}
                      dateOpen={item.dat_hor_abertura_cesv_docum}
                      dateCesv={
                        item.dat_hor_conclusao_conferencia
                      }
                      nameItem={item.den_item_reduz}
                      skuDefault={item.sku_padrao}
                      typeStock={item.tip_estoque}
                      seqAvisoRecebto={item.seq_aviso_recebto}
                      restriction={item.restricao}
                      qtdItemDetail={item.qtd_item}
                      qtdItemSku={item.qtd_item_sku}
                      qtyPhysicalPallet={item.qtd_palete_fisico}
                      qtyDefaultPallet={item.qtd_palete_padrao}
                      pesoBrutoDetails={item.peso_bruto}
                      netWeight={item.peso_liquido}
                      volumeM3={item.volume_m3}
                      categoryItem={item.des_categoria}
                      listItens={listItens}
                      setListItens={setListItens}
                      index={index}
                      codItem={item.item}
                      fiscalNote={item.num_nf}
                    />
                  );
                })
              )}
              <ScrollBotton width={"1781px"} />
            </div>

            <div style={boxCard}></div>
          </div>
        </div>
      }
    />
  );
};

export default DetailsInvoice;
