import CardMinInsurance from "./CardMinInsurance";

const CardInsurance = ({ item, priceUnit }) => {
  /* STYLES */
  const container = {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    marginTop: "22px",
    justifyContent: "space-between",
  };

  return (
    <div style={container}>
      {item?.length > 0 ? (
        item.map((elem) => {
          return (
            <CardMinInsurance
              date={elem.data_base_calculo}
              valueStock={elem.acumulado}
              invoicedStock={elem.acumulado * (priceUnit / 100)}
            />
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default CardInsurance;
