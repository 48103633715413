import Button from "./Button";
import BoxParameterSelect from "./BoxParameterSelect";
import ImageUser from "./ImageUser";
import plusPurple from "../../../images/svg/plusPurple.svg";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { DashContext } from "../../../context/Dashboard/DashContext";
import { toast } from "react-toastify";

const ModalFormClient = ({
  closeModal,
  cardSelected,
  optionModalFormClient,
}) => {
  const { onCreateClient, onEditClient } = useContext(DashContext);

  /* STATES */
  const [picture, setPicture] = useState("");
  const [name, setName] = useState("");
  const [cnpj, setCnpj] = useState("");

  const [coin, setCoin] = useState(",");
  const [data, setData] = useState("");
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");

  const [billingType, setBillingType] = useState(",");
  const [dayBilling, setDayBilling] = useState("01");
  const [branch, setBranch] = useState(".");

  /* STYLES */
  const container = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    width: "690px",
    boxShadow: "0px 0px 13px -3px #00000033",
    border: "1px solid transparent",
    borderRadius: "20px",
  };

  const headerOption = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #D7D7D7",
    padding: "15px 18px 18px",
  };

  const content = {
    width: "100%",
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  };

  const boxTextDelete = {
    maxHeight: "510px",
    display: "flex",
    alignItems: "center",
    padding: "18px",
    flexDirection: "column",
    overflowY: "auto",
  };

  const border = {
    minWidth: "100%",
    minHeight: "1px",
    marginTop: "20px",
    backgroundColor: "#D7D7D7",
  };

  const addUserContainer = {
    width: "100%",
    marginTop: "23px",
    display: "flex",
    alignItems: "start",
    gap: "7px",
    color: "#9966CC",
    cursor: "pointer",
  };

  const validateEmail = (mail) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(mail);
  };

  const mountDataFunction = (type) => {
    if (!email && !name && !cnpj && !coin && !data) {
      return toast.error(
        "Verifique se todos os dados foram preechidos corretamente.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
    if (!validateEmail(email)) {
      return toast.error("Email inválido", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    const dataObject = {
      name: name,
      cnpj: cnpj,
      coin: coin,
      date: data,
      email: email,
      picture: picture,
      billing_type: billingType,
      monthly_billing: 0,
      billing_day: dayBilling,
      branch: branch,
    };

    if (String(password).length > 0) {
      dataObject.password = password;
      dataObject.password_hash = password;
    }

    if (type === "edit") {
      onEditClient(dataObject, closeModal);
    }
    if (type === "create") {
      onCreateClient(dataObject, closeModal);
    }
  };

  useEffect(() => {
    if (cardSelected && optionModalFormClient == "edit") {
      setPicture(cardSelected.picture);
      setName(cardSelected.name);
      setCnpj(cardSelected.cnpj);
      setCoin(cardSelected.coin);
      setData(cardSelected.date);
      setEmail(cardSelected.email);
      setPassword(cardSelected.password);
      setBillingType(cardSelected.billing_type);
      setDayBilling(cardSelected.billing_day);
      setBranch(cardSelected.branch || ".");
    }
  }, [cardSelected]);

  return (
    <div className="modalEdit" style={container}>
      <div style={headerOption}>
        <div
          style={{
            display: "flex",
            gap: "27px",
            alignItems: "center",
          }}
        >
          <div
            onClick={closeModal}
            style={{
              cursor: "pointer",
            }}
          >
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.48406 6.36766L12.4871 1.35916C12.7976 1.04823 12.7976 0.544109 12.4871 0.2332C12.1765 -0.0777335 11.673 -0.0777335 11.3624 0.2332L6.35997 5.24222L1.35752 0.2332C1.04696 -0.0777335 0.543453 -0.0777335 0.232919 0.2332C-0.0776148 0.544134 -0.0776396 1.04825 0.232919 1.35916L5.23589 6.36766L0.232919 11.3762C-0.0776396 11.6871 -0.0776396 12.1912 0.232919 12.5021C0.543478 12.8131 1.04699 12.8131 1.35752 12.5021L6.35997 7.4931L11.3624 12.5021C11.673 12.8131 12.1765 12.8131 12.487 12.5021C12.7976 12.1912 12.7976 11.6871 12.487 11.3762L7.48406 6.36766Z"
                fill="black"
              />
            </svg>
          </div>
          {optionModalFormClient == "edit" ? (
            <p className="textModalTitleOption">Editar cliente</p>
          ) : (
            <p className="textModalTitleOption">Criar cliente</p>
          )}
        </div>

        {optionModalFormClient == "edit" ? (
          <Button
            width="181px"
            height="33px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Editar"
            onClick={() => mountDataFunction("edit")}
            border="none"
          />
        ) : (
          <Button
            width="181px"
            height="33px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Criar"
            onClick={() => mountDataFunction("create")}
            border="none"
          />
        )}
      </div>
      <div style={boxTextDelete}>
        <ImageUser image={picture} setImage={setPicture} />
        <div style={content}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <BoxParameterSelect
              width={"310px"}
              type={"nameClient"}
              onChange={(e) => setName(e.target.value)}
              valueInput={name}
            />
            <BoxParameterSelect
              width={"310px"}
              type={"cnpj"}
              onChange={(e) => setCnpj(e.target.value)}
              valueInput={cnpj}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <BoxParameterSelect
              width={"310px"}
              type={"billingType"}
              onChange={(e) => setBillingType(e.target.value)}
              valueInput={billingType}
            />
            <BoxParameterSelect
              width={"310px"}
              type={"beginningBilling"}
              onChange={(e) => setData(e.target.value)}
              valueInput={data}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <BoxParameterSelect
              width={"310px"}
              type={"dayBilling"}
              onChange={(e) => setDayBilling(e.target.value)}
              valueInput={dayBilling || "01"}
            />
            <BoxParameterSelect
              width={"310px"}
              type={"coin"}
              onChange={(e) => setCoin(e.target.value)}
              valueInput={coin}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <BoxParameterSelect
              width={"310px"}
              type={"email"}
              onChange={(e) => setEmail(e.target.value)}
              valueInput={email}
            />

            <BoxParameterSelect
              width={"310px"}
              type={"password"}
              onChange={(e) => setPassword(e.target.value)}
              valueInput={password}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <BoxParameterSelect
              width={"310px"}
              type={"branch"}
              onChange={(e) => setBranch(e.target.value)}
              valueInput={branch}
            />
          </div>
          {/* <div style={border}></div> */}
        </div>
        {/* <div style={addUserContainer}>
          <img src={plusPurple} />
          <p>Adicionar outra conta do mesmo cliente</p>
        </div> */}
      </div>
    </div>
  );
};

export default ModalFormClient;
