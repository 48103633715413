/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import companyIcon from "../images/svg/companyIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";

import dashIconOn from "../images/svg/dashboardIconSidebarOn.svg";
import dashIconOff from "../images/svg/dashboardIconSidebarOff.svg";

import companyIconOn from "../images/svg/sidebar/companyOn.svg";
import companyIconOff from "../images/svg/sidebar/companyOff.svg";

import shedulesIconOn from "../images/svg/sidebar/shedulesOn.svg";
import shedulesIconOff from "../images/svg/sidebar/shedulesOff.svg";

import qualityIconOn from "../images/svg/sidebar/qualityOn.svg";
import qualityIconOff from "../images/svg/sidebar/qualityOff.svg";

import qualityOutletIconOn from "../images/svg/sidebar/outletOn.svg";
import qualityOutletIconOff from "../images/svg/sidebar/outletOff.svg";

import qualityArrowOn from "../images/svg/sidebar/arrowOn.svg";
import qualityArrowOff from "../images/svg/sidebar/arrowOff.svg";
import qualityArrowClosedOn from "../images/svg/sidebar/arrowClosedOn.svg";
import qualityArrowOpenOff from "../images/svg/sidebar/arrowOpenOff.svg";

import managementIconOn from "../images/svg/sidebar/managementOn.svg";
import managementIconOff from "../images/svg/sidebar/managementOff.svg";

import settingIconOn from "../images/svg/sidebar/settingsOn.svg";
import settingIconOff from "../images/svg/sidebar/settingsOff.svg";

import productivityIconOn from "../images/svg/sidebar/productivityOn.svg";
import productivityIconOff from "../images/svg/sidebar/productivityOff.svg";
import jwtDecode from "jwt-decode";

const Sidebar = ({ page, access }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("@XPM_ADM_TOKEN:");

  const [showQualityTabs, setShowQualityTabs] = useState(
    location.pathname.startsWith("/dashboard/quality")
  );

  const getPermition = () => {
    const decoded = jwtDecode(token);

    return decoded.permission;
  };

  useEffect(() => {
    setShowQualityTabs(location.pathname.startsWith("/dashboard/quality"));
  }, [location.pathname]);

  const container = {
    width: "229px",
    position: "fixed",
    top: "0",
    zIndex: "2",
    height: "100vh",
    background: "white",
    border: "1px solid #D7D7D7",
    borderTopRightRadius: 17,
    borderBottomRightRadius: 17,
  };
  const containerImage = {
    display: "flex",
    justifyContent: "center",
    marginTop: "2.625rem",
  };
  const containerItem = {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    width: "205px",
    borderRadius: "8px",
    paddingTop: "2px",
    paddingBottom: "3px",
    height: "39px",
    background: "#F9F3FF",
    marginBottom: "21px",
    cursor: "pointer",
  };

  const containerItemNotChecked = {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    width: "205px",
    borderRadius: "8px",
    paddingTop: "2px",
    paddingBottom: "3px",
    height: "39px",
    background: "white",
    marginBottom: "21px",
    cursor: "pointer",
  };
  const image = {
    position: "relative",
    top: "1px",
    marginLeft: "11px",
  };

  const arrow = {
    marginLeft: "40px",
  };

  const redirectToInitialPage = () => {
    navigate("/dashboard");
  };

  const redirectToDashboardPage = () => {
    const token = localStorage.getItem("@XPM_ADM_TOKEN:");
    const decoded = jwtDecode(token);
    if (decoded.permission === "BPO") {
      navigate("/dashboard/company/BPO");
    }
    if (decoded.permission !== "BPO") {
      navigate("/dashboard/company");
    }
  };

  const redirectToShedulesPage = () => {
    navigate("/dashboard/scheduling");
  };

  const qualityTabsToggle = () => {
    setShowQualityTabs(!showQualityTabs);
  };

  const redirectToNpsResearchPage = () => {
    navigate("/dashboard/quality/nps-research");
  };

  const redirectToInternalDocumentsPage = () => {
    navigate("/dashboard/quality/internal-documents");
  };

  const redirectToExternalDocumentsPage = () => {
    navigate("/dashboard/quality/external-documents");
  };

  const redirectToManagementPage = () => {
    navigate("/dashboard/management");
  };

  const redirectToSettingsPage = () => {
    navigate("/dashboard/settings");
  };

  const redirectToProductivitysPage = () => {
    navigate("/dashboard/productivity");
  };

  const redirectToExitPage = () => {
    if (access === "adm") {
      return navigate("/login/admin");
    }
    return navigate("/");
  };

  return (
    <div style={container}>
      <div style={containerImage}>
        <img src={companyIcon} />
      </div>

      {
        <div
          style={{
            paddingLeft: "12px",
            paddingRight: "12px",
            marginTop: "52px",
          }}
        >
          <div
            onClick={() => redirectToInitialPage()}
            style={page === "Initial" ? containerItem : containerItemNotChecked}
          >
            {/* ICON */}
            <div style={image}>
              <img src={page === "Initial" ? dashIconOn : dashIconOff} />
            </div>

            {page === "Initial" ? (
              <p className="textCheckedSidebar">Dashboard</p>
            ) : (
              <p className="textNotCheckedSidebar">Dashboard</p>
            )}
          </div>

          <div
            onClick={() => redirectToDashboardPage()}
            style={page === "Company" ? containerItem : containerItemNotChecked}
          >
            <div style={image}>
              <img src={page === "Company" ? companyIconOn : companyIconOff} />
            </div>

            {page === "Company" ? (
              <p style={{ marginLeft: "10px" }} className="textCheckedSidebar">
                Faturamento
              </p>
            ) : (
              <p
                style={{ marginLeft: "10px" }}
                className="textNotCheckedSidebar"
              >
                Faturamento
              </p>
            )}
          </div>

          <div
            onClick={() => redirectToShedulesPage()}
            style={
              page === "Shedules" ? containerItem : containerItemNotChecked
            }
          >
            <div style={image}>
              <div style={{ transform: "translateX(-5px)" }}>
                <img
                  src={page === "Shedules" ? shedulesIconOn : shedulesIconOff}
                />
              </div>
            </div>

            {page === "Shedules" ? (
              <p className="textCheckedSidebar">Agendamento</p>
            ) : (
              <p className="textNotCheckedSidebar">Agendamento</p>
            )}
          </div>

          {/* <div
            onClick={qualityTabsToggle}
            style={page === "NpsResearch" || page === "InternalDocuments" || page === "ExternalDocuments" ? containerItem : containerItemNotChecked}
          >
            <div style={image}>
              <div style={{ transform: "translateX(-5px)" }}>
                <img src={page === "NpsResearch" || page === "InternalDocuments" || page === "ExternalDocuments" ? qualityIconOn : qualityIconOff} />
              </div>
            </div>
            {page === "NpsResearch" || page === "InternalDocuments" || page === "ExternalDocuments" ? (
              <p className="textCheckedSidebar">Qualidade</p>
            ) : (
              <p className="textNotCheckedSidebar">Qualidade</p>
            )}
            <div style={{...image, ...arrow}}>
              <div style={{ transform: "translateX(-5px)" }}>
                <img src={page === "NpsResearch" || page === "InternalDocuments" || page === "ExternalDocuments" ?
                  !showQualityTabs ? qualityArrowClosedOn : qualityArrowOn :
                  showQualityTabs ? qualityArrowOpenOff : qualityArrowOff} />
              </div>
            </div>
          </div> */}

          {/* {showQualityTabs && (
            <>
              <div
                onClick={() => redirectToNpsResearchPage()}
                style={
                  page === "NpsResearch" ? containerItem : containerItemNotChecked
                }
              >
                <div style={image}>
                  <div style={{ transform: "translateX(-5px)" }}>
                    <img
                      src={page === "NpsResearch" ? qualityOutletIconOn : qualityOutletIconOff}
                    />
                  </div>
                </div>
                {page === "NpsResearch" ? (
                  <p className="textCheckedSidebar">Pesquisa NPS</p>
                ) : (
                  <p className="textNotCheckedSidebar">Pesquisa NPS</p>
                )}
              </div>

              <div
                onClick={() => redirectToInternalDocumentsPage()}
                style={
                  page === "InternalDocuments"
                    ? containerItem
                    : containerItemNotChecked
                }
              >
                <div style={image}>
                  <div style={{ transform: "translateX(-5px)" }}>
                    <img
                      src={page === "InternalDocuments" ? qualityOutletIconOn : qualityOutletIconOff}
                    />
                  </div>
                </div>
                {page === "InternalDocuments" ? (
                  <p className="textCheckedSidebar">Documentos internos</p>
                ) : (
                  <p className="textNotCheckedSidebar">Documentos internos</p>
                )}
              </div>

              <div
                onClick={() => redirectToExternalDocumentsPage()}
                style={
                  page === "ExternalDocuments"
                    ? containerItem
                    : containerItemNotChecked
                }
              >
                <div style={image}>
                  <div style={{ transform: "translateX(-5px)" }}>
                    <img
                      src={page === "ExternalDocuments" ? qualityOutletIconOn : qualityOutletIconOff}
                    />
                  </div>
                </div>
                {page === "ExternalDocuments" ? (
                  <p className="textCheckedSidebar">Documentos externos</p>
                ) : (
                  <p className="textNotCheckedSidebar">Documentos externos</p>
                )}
              </div>
            </>
          )} */}

          {/* <div
            onClick={() => redirectToProductivitysPage()}
            style={
              page === "Productivity" ? containerItem : containerItemNotChecked
            }
          >
            <div style={image}>
              <div style={{ transform: "translateX(-5px)" }}>
                <img
                  src={
                    page === "Productivity"
                      ? productivityIconOn
                      : productivityIconOff
                  }
                />
              </div>
            </div>

            {page === "Productivity" ? (
              <p className="textCheckedSidebar">Produtividade</p>
            ) : (
              <p className="textNotCheckedSidebar">Produtividade</p>
            )}
          </div> */}

          {getPermition() === "Administrativo" ? (
            <div
              onClick={() => redirectToManagementPage()}
              style={
                page === "Management" ? containerItem : containerItemNotChecked
              }
            >
              <div style={image}>
                <div style={{ transform: "translateX(-7px)" }}>
                  <img
                    src={
                      page === "Management"
                        ? managementIconOn
                        : managementIconOff
                    }
                  />
                </div>
              </div>

              {page === "Management" ? (
                <p
                  style={{ transform: "translateX(-4px)" }}
                  className="textCheckedSidebar"
                >
                  Gerenciar acessos
                </p>
              ) : (
                <p
                  style={{ transform: "translateX(-4px)" }}
                  className="textNotCheckedSidebar"
                >
                  Gerenciar acessos
                </p>
              )}
            </div>
          ) : null}

          <div
            onClick={() => redirectToSettingsPage()}
            style={page === "Setting" ? containerItem : containerItemNotChecked}
          >
            <div style={image}>
              <div style={{ transform: "translateX(-7px)" }}>
                <img
                  src={page === "Setting" ? settingIconOn : settingIconOff}
                />
              </div>
            </div>

            {page === "Setting" ? (
              <p className="textCheckedSidebar">Configurações</p>
            ) : (
              <p className="textNotCheckedSidebar">Configurações</p>
            )}
          </div>

          <div
            onClick={() => redirectToExitPage()}
            style={{
              ...containerItemNotChecked,
              marginTop: "100px",
            }}
          >
            <div style={{ ...image, transform: "translateY(-9px)" }}>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.10243 16.5725H3.94576C3.52716 16.5725 3.12571 16.4063 2.82971 16.1103C2.53372 15.8143 2.36743 15.4128 2.36743 14.9942V3.94589C2.36743 3.52729 2.53372 3.12583 2.82971 2.82984C3.12571 2.53384 3.52716 2.36755 3.94576 2.36755H7.10243"
                  stroke="#787486"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.6267 13.4158L16.5725 9.47L12.6267 5.52417"
                  stroke="#787486"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5725 9.46997H7.10254"
                  stroke="#787486"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>

            <p
              style={{ transform: "translateY(-7px)" }}
              className="textNotCheckedSidebar"
            >
              Sair
            </p>
          </div>
        </div>
      }
    </div>
  );
};

export default Sidebar;
