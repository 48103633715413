import React from "react";
import PropTypes from "prop-types";

const FormInputText = ({
    background,
    border,
    borderRadius,
    color,
    height,
    marginTop,
    marginBottom,
    marginRight,
    marginLeft,
    placeholder,
    width,
    minWidth,
    maxWidth,
    name,
    onChange,
    type,
    value,
    label,
    paddingInput,
    options,
    inputOrSelect,
    onBlur,
}) => {
    const stylesInput = {
        background,
        border,
        borderRadius: 10,
        color,
        height,
        minWidth,
        maxWidth,
        width: "100%",
        padding: 10,
        boxSizing: "border-box",
        marginTop: 10,
        marginBottom: 20,
        outline: "none",
    };

    const stylesContainer = {
        height: "fit-content",
        marginLeft,
        marginRight,
        width,
        minWidth,
        maxWidth,
    };

    return (
        <div style={stylesContainer}>
            <label style={{ width: "100%" }}>
                <p
                    className="textOptionLicalModalConfig"
                    style={{ margin: "0px" }}
                >
                    {label}
                </p>
                {inputOrSelect === "select" ? (
                    <select
                        name={name}
                        style={stylesInput}
                        onChange={onChange}
                        value={value}
                        placeholder={placeholder}
                        onBlur={onBlur}
                    >
                        {options.map((item) => {
                            return (
                                <option value={item} key={item}>
                                    {item}
                                </option>
                            );
                        })}
                    </select>
                ) : (
                    <input
                        type={type || "text"}
                        name={name}
                        style={stylesInput}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        placeholder={placeholder}
                    />
                )}
            </label>
        </div>
    );
};

FormInputText.propTypes = {
    background: PropTypes.string,
    border: PropTypes.string,
    borderRadius: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.string,
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
    marginRight: PropTypes.string,
    marginLeft: PropTypes.string,
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string,
    value: PropTypes.string,
};

export default FormInputText;
