import vector from "../../../images/svg/vector.svg";
import CardTeamsDetails from "./CardTeamsDetails";

const CardMonthsDetailsTeams = ({ elem }) => {
  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    border: "1px solid #d7d7d7",
    padding: "20px 30px",
    borderRadius: "10px",
  };

  const lineOptions = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer",
    justifyContent: "center",
  };

  return (
    <div style={{ marginTop: "40px" }}>
      {/* CABEÇALHO DA PAGINA */}
      <div style={containerStyle}>
        <p className="headerTeams" style={{ width: "130px" }}>
          Equipe
        </p>

        <div
          className="hovertranslateY1"
          style={{ ...lineOptions, width: "160px" }}
        >
          <p className="headerTeams">Absenteísmo</p>
          <img src={vector} />
        </div>

        <div style={{ width: "160px" }}>
          <div className="hovertranslateY1" style={{ ...lineOptions }}>
            <p className="headerTeams">Capacidade</p>
            <img src={vector} />
          </div>
        </div>

        <div style={{ width: "160px" }}>
          <div className="hovertranslateY1" style={lineOptions}>
            <p className="headerTeams">IQS</p>
            <img src={vector} />
          </div>
        </div>

        <div style={{ width: "160px" }}>
          <div className="hovertranslateY1" style={lineOptions}>
            <p className="headerTeams">Torniquete</p>
            <img src={vector} />
          </div>
        </div>

        <div style={{ width: "160px" }}>
          <div className="hovertranslateY1" style={lineOptions}>
            <p className="headerTeams">Resultado</p>
            <img src={vector} />
          </div>
        </div>
      </div>

      {elem.teams?.length > 0 ? (
        elem.teams.map((team) => {
          return <CardTeamsDetails team={team} />;
        })
      ) : (
        <></>
      )}
    </div>
  );
};

export default CardMonthsDetailsTeams;
