/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import jwtDecode from "jwt-decode";
import optionsIcon from "../../../images/svg/treePoints.svg";
import moment from "moment-timezone";
import ModalCardEditOrDelete from "./ModalCardEditOrDelete";
import fileIcon from "../../../images/png/files (1).png";
import wordIcon from "../../../images/png/word.jpg";
import excelIcon from "../../../images/png/excel.jpg";
import pdfIcon from "../../../images/png/pdf.png";

const CardCommentsMessage = ({ elem, i, messages }) => {
  moment.tz.setDefault("America/Sao_Paulo");

  const [isArrowUp, setIsArrowUp] = useState(false);
  const [editOrDeleteOptions, setEditOrDeleteOptions] = useState(false);
  const filesPermitted = ["image/png", "image/jpeg"];

  const myMenssages = (id) => {
    const token = localStorage.getItem("@XPM_ADM_TOKEN:");
    const decoded = jwtDecode(token);

    return decoded.id === id;
  };

  const verifyRepetName = (name, i) => {
    if (i - 1 >= 0) {
      if (messages[i - 1]?.name === name) {
        return "";
      } else {
        return name;
      }
    } else {
      return name;
    }
  };

  const handleMouseEnter = () => {
    const timestamp = elem.timestamp;
    const momentTimestamp = moment(timestamp, "DD/MM/YYYY - HH:mm");
    // Verifique se já passaram 5 minutos desde o momento atual
    const diffMinutes = moment().diff(momentTimestamp, "minutes");
    if (diffMinutes <= 4) {
      if (myMenssages(elem.owner)) {
        setIsArrowUp(true);
      }
    }
  };

  const handleMouseLeave = () => {
    setIsArrowUp(false);
  };

  const formactType = (type) => {
    if (
      type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      // return "Word";
      return wordIcon;
    } else if (type === "text/plain") {
      // return "Text";
      return wordIcon;
    } else if (type === "application/vnd.ms-excel") {
      // return "Excel";
      return excelIcon;
    } else if (type === "application/pdf") {
      // return "PDF";
      return pdfIcon;
    }
  };

  return elem.message.includes("--Linkfile--") ? (
    filesPermitted.includes(elem.message.split("--Linkfile--")[0]) ? (
      // IMAGEM
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          border: `3px solid ${
            myMenssages(elem.owner) ? "#9966CC90" : "#00000060"
          }`,
          borderBottom: `30px solid ${
            myMenssages(elem.owner) ? "#9966CC90" : "#00000060"
          }`,
          minWidth: "40%",
          maxWidth: "40%",
          marginLeft: myMenssages(elem.owner) ? "60%" : "0",
          padding: "10px",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <a
          href={elem.message.split("--Linkfile--")[2]}
          target="blank"
          style={{ cursor: "zoom-in" }}
        >
          <img
            src={elem.message.split("--Linkfile--")[2]}
            style={{ width: "100%", objectFit: "contain" }}
          />
        </a>
        <p
          style={{
            fontSize: "10px",
            fontWeight: "600",
            position: "absolute",
            bottom: -21,
            right: 8,
          }}
        >
          {elem.edit ? "Editado: " : ""}
          {elem.timestamp}
        </p>
        {isArrowUp && (
          <div
            onClick={() => setEditOrDeleteOptions(true)}
            style={{
              width: "35px",
              height: "20px",
              position: "absolute",
              right: 13,
              top: -1,
              cursor: "pointer",
            }}
          >
            <img
              src={optionsIcon}
              style={{
                transform: "rotate(90deg) translateY(-15px)",
              }}
            />
          </div>
        )}
        {editOrDeleteOptions && (
          <ModalCardEditOrDelete
            id={elem.id}
            edit={false}
            onClose={() => setEditOrDeleteOptions(false)}
            message={elem.message}
          />
        )}
      </div>
    ) : (
      // ARQUIVOS
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          border: `3px solid ${
            myMenssages(elem.owner) ? "#9966CC90" : "#00000060"
          }`,
          borderBottom: `30px solid ${
            myMenssages(elem.owner) ? "#9966CC90" : "#00000060"
          }`,
          minWidth: "40%",
          maxWidth: "40%",
          marginLeft: myMenssages(elem.owner) ? "60%" : "0",
          borderRadius: "8px",
          position: "relative",
        }}
      >
        <div
          style={{
            borderRadius: "6px 6px 0 0",
            overflow: "hidden",
          }}
        >
          <a href={elem.message.split("--Linkfile--")[2]} target="blank">
            <img
              src={formactType(elem.message.split("--Linkfile--")[0])}
              style={{ width: "100%", objectFit: "contain" }}
            />
          </a>
        </div>
        <p
          style={{
            fontSize: 13,
            backgroundColor: myMenssages(elem.owner)
              ? "#9966CC90"
              : "#00000060",
            padding: "6px 6px 0 ",
          }}
        >
          {elem.message.split("--Linkfile--")[1]}
        </p>
        <p
          style={{
            fontSize: "10px",
            fontWeight: "600",
            position: "absolute",
            bottom: -21,
            right: 8,
          }}
        >
          {elem.edit ? "Editado: " : ""}
          {elem.timestamp}
        </p>

        {isArrowUp && (
          <div
            onClick={() => setEditOrDeleteOptions(true)}
            style={{
              width: "35px",
              height: "20px",
              position: "absolute",
              right: 13,
              top: -1,
              cursor: "pointer",
            }}
          >
            <img
              src={optionsIcon}
              style={{
                transform: "rotate(90deg) translateY(-15px)",
              }}
            />
          </div>
        )}
        {editOrDeleteOptions && (
          <ModalCardEditOrDelete
            id={elem.id}
            edit={false}
            onClose={() => setEditOrDeleteOptions(false)}
            message={elem.message}
          />
        )}
      </div>
    )
  ) : (
    // TEXTOS
    <div style={{ position: "relative" }}>
      <div
        style={{
          marginLeft: myMenssages(elem.owner) ? "60%" : "0",
          minWidth: "40%",
          maxWidth: "40%",
        }}
      >
        <div>
          <p>{verifyRepetName(elem.name, i)}</p>
        </div>
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            backgroundColor: myMenssages(elem.owner)
              ? "#9966CC90"
              : "#00000060",
            padding: "8px 10px",
            borderRadius: "8px",
            border: "1px solid transparent",
            position: "relative",
          }}
        >
          {isArrowUp && (
            <img
              src={optionsIcon}
              onClick={() => setEditOrDeleteOptions(true)}
              style={{
                transform: "rotate(90deg)",
                position: "absolute",
                right: 13,
                top: -1,
                cursor: "pointer",
              }}
            />
          )}

          <p
            style={{
              textAlign: "start",
              fontSize: "15px",
              wordWrap: "break-word",
            }}
          >
            {elem.message}
          </p>
          <p
            style={{
              textAlign: "end",
              fontSize: "10px",
              fontWeight: "600",
              marginTop: "8px",
            }}
          >
            {elem.edit ? "Editado: " : ""}
            {elem.timestamp}
          </p>
        </div>
      </div>

      {editOrDeleteOptions && (
        <ModalCardEditOrDelete
          id={elem.id}
          onClose={() => setEditOrDeleteOptions(false)}
          message={elem.message}
        />
      )}
    </div>
  );
};

export default CardCommentsMessage;
