import { Routes, Route } from "react-router-dom";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import Landing from "../pages/Landing/initial";
import DashboardAdm from "../pages/Dashboard/Company";
import DetailsInvoice from "../pages/Dashboard/DetailsReceptor";
import DetailsDevolution from "../pages/Dashboard/DetailsDevolution";
import DetailsStorage from "../pages/Dashboard/DetailsStorage";
import DetailsShipping from "../pages/Dashboard/DetailsShipping";
import DetailsInsurance from "../pages/Dashboard/DetailsInsurance";
import InitialDashboard from "../pages/Dashboard/Initial";
import Development from "../pages/Development/Initial";
import AccessManager from "../pages/AccessManager/Initial";
import Settings from "../pages/Settings/Initial";
import Productivity from "../pages/Productivity/initial";
import Scheduling from "../pages/Scheduling/Initial";
import SchedulingByDate from "../pages/SchedulingByDate/initial";
import DetailsGeneralities from "../pages/Dashboard/DetailsGeneralities";
import NpsResearch from "../pages/QualityNpsResearch/NpsResearch";
import SurveyDetails from "../pages/QualityNpsResearch/SurveyDetails";
import SearchHappy from "../pages/QualityNpsResearch/SearchHappy";
import SearchHappyFeedback from "../pages/QualityNpsResearch/SearchHappyFeedback";
import InternalDocuments from "../pages/QualityInternalDocuments/InternalDocuments";
import ExternalDocuments from "../pages/QualityExternalDocuments/ExternalDocuments";
import BPO from "../pages/Dashboard/Company/BPO";


export const RoutesUrl = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/dashboard" element={<InitialDashboard />} />
      <Route path="/dashboard/company" element={<DashboardAdm />} />
      <Route path="/dashboard/company/BPO" element={<BPO />} />
      <Route
        path="/dashboard/company/details/invoice"
        element={<DetailsInvoice />}
      />
      <Route
        path="/dashboard/company/details/storage"
        element={<DetailsStorage />}
      />
      <Route
        path="/dashboard/company/details/shipping"
        element={<DetailsShipping />}
      />
      <Route
        path="/dashboard/company/details/insurance"
        element={<DetailsInsurance />}
      />
      <Route
        path="/dashboard/company/details/generalities"
        element={<DetailsGeneralities />}
      />
      {/* <Route path="/dashboard/scheduling" element={<Scheduling />} /> */}
      <Route
        path="/dashboard/scheduling/details"
        element={<SchedulingByDate />}
      />
      {/* <Route path="/dashboard/productivity" element={<Productivity />} /> */}
      <Route path="/dashboard/settings" element={<Settings />} />
      <Route path="/dashboard/shedules" element={<Development />} />
      <Route path="/dashboard/management" element={<AccessManager />} />
      {/* <Route path="/dashboard/quality/nps-research" element={<NpsResearch />} />
      <Route path="/dashboard/quality/nps-research/survey/:cardId" element={<SurveyDetails />} />
      <Route path="/dashboard/quality/searchhappy/:cardId" element={<SearchHappy />} />
      <Route path="/dashboard/quality/searchhappy/feedback" element={<SearchHappyFeedback />} />
      <Route path="/dashboard/quality/internal-documents" element={<InternalDocuments />} />
      <Route path="/dashboard/quality/external-documents" element={<ExternalDocuments />} /> */}
    </Routes>
  );
};
