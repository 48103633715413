import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import CardUser from "./CardUser";
import ModalContainer from "../components/ModalContainer";
import ModalOptions from "./ModalOptions";
import ModalFormClient from "./ModalFormClient";
import ModalFormAdmin from "./ModalFormAdmin";
import { useContext } from "react";
import { DashContext } from "../../../context/Dashboard/DashContext";

const TableList = ({
  filterOption,
  searchingUser,
  setNameButtom,
  setColorButtom,
  listCardSelected,
  setListCardSelected,
  modalFormAdmin,
  setModalFormAdmin,
  modalFormClient,
  setModalFormClient,
  optionModalFormAdmin,
  setOptionModalFormAdmin,
  optionModalFormClient,
  setOptionModalFormClient,
}) => {
  const { onGetClient, listClients, listAdmins, onGetAdmin } =
    useContext(DashContext);

  const [globalCheked, setGlobalCheked] = useState(false);
  const [cardSelected, setCardSelected] = useState();

  const [modalOptions, setModalOpstions] = useState(false);

  useEffect(() => {
    if (filterOption === "Acessos admin") {
      onGetAdmin();
    } else if (filterOption === "Acessos clientes") {
      onGetClient();
    }
  }, [filterOption]);

  useEffect(() => {
    if (filterOption == "Acessos admin") {
      setNameButtom("Novo usuário");
    } else if (filterOption == "Acessos clientes") {
      setNameButtom("Novo cliente");
    }
    if (globalCheked) {
      setNameButtom("Deletar todos");
      setColorButtom("red");
    } else {
      setColorButtom("var(--color-primary)");
    }
  }, [globalCheked, filterOption]);

  return (
    <>
      {filterOption == "Acessos admin" ? (
        <div>
          <table
            className="border-table"
            style={{ textAlign: "left", width: "100%", marginTop: "2.68rem" }}
          >
            <thead>
              <tr style={{ position: "relative", top: "20px" }}>
                <td style={{ borderRight: "none" }}>
                  <input
                    type="checkbox"
                    checked={globalCheked}
                    onChange={() => setGlobalCheked(!globalCheked)}
                    style={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "3px",
                    }}
                  />
                </td>
                <td
                  className="textTitleDeteilsUserTable"
                  style={{ borderRight: "none", paddingLeft: "63px" }}
                >
                  Nome do usuário
                </td>
                <td
                  className="textTitleDeteilsUserTable"
                  style={{ borderRight: "none" }}
                >
                  E-mail de acesso
                </td>
                <td
                  className="textTitleDeteilsUserTable"
                  style={{ borderRight: "none" }}
                >
                  Permissão
                </td>
                <td></td>
              </tr>
            </thead>
            {listAdmins
              // FILTRO DE NOMES
              .filter((user) =>
                user.name.toLowerCase().includes(searchingUser.toLowerCase())
              )
              .map((item) => {
                return (
                  <>
                    <CardUser
                      user={item}
                      filterOption={filterOption}
                      globalCheked={globalCheked}
                      setCardSelected={setCardSelected}
                      setModalOpstions={() => setModalOpstions(true)}
                      setOptionModalFormAdmin={() =>
                        setOptionModalFormAdmin("edit")
                      }
                      setOptionModalFormClient={() =>
                        setOptionModalFormClient("edit")
                      }
                      setNameButtom={setNameButtom}
                      setColorButtom={setColorButtom}
                      listCardSelected={listCardSelected}
                      setListCardSelected={setListCardSelected}
                    />
                    <div style={{ marginTop: "13px" }} />
                  </>
                );
              })}
            <div style={{ paddingBottom: 100 }} />
          </table>
        </div>
      ) : (
        <div>
          <table
            className="border-table"
            style={{ textAlign: "left", width: "100%", marginTop: "2.68rem" }}
          >
            <thead>
              <tr style={{ position: "relative", top: "20px" }}>
                <td style={{ borderRight: "none" }}>
                  <input
                    type="checkbox"
                    checked={globalCheked}
                    onChange={() => setGlobalCheked(!globalCheked)}
                    style={{
                      width: "15px",
                      height: "15px",
                      borderRadius: "3px",
                    }}
                  />
                </td>
                <td
                  className="textTitleDeteilsUserTable"
                  style={{ borderRight: "none", paddingLeft: "63px" }}
                >
                  Nome
                </td>
                <td
                  className="textTitleDeteilsUserTable"
                  style={{ borderRight: "none" }}
                >
                  Cliente
                </td>
                <td
                  className="textTitleDeteilsUserTable"
                  style={{ borderRight: "none" }}
                >
                  E-mail de acesso
                </td>
                <td
                  className="textTitleDeteilsUserTable"
                  style={{ borderRight: "none" }}
                >
                  Moeda
                </td>
                <td
                  className="textTitleDeteilsUserTable"
                  style={{ borderRight: "none" }}
                >
                  Inicio de faturamento
                </td>
                <td></td>
              </tr>
            </thead>
            {listClients.length > 0 ? (
              listClients
                // FILTRO DE NOMES
                ?.filter((user) =>
                  user.name.toLowerCase().includes(searchingUser.toLowerCase())
                )
                .map((item) => {
                  return (
                    <>
                      <CardUser
                        user={item}
                        billingDate={item.billingDate}
                        filterOption={filterOption}
                        globalCheked={globalCheked}
                        setCardSelected={setCardSelected}
                        setModalOpstions={() => setModalOpstions(true)}
                        setOptionModalFormAdmin={() =>
                          setOptionModalFormAdmin("edit")
                        }
                        setOptionModalFormClient={() =>
                          setOptionModalFormClient("edit")
                        }
                        setNameButtom={setNameButtom}
                        setColorButtom={setColorButtom}
                        listCardSelected={listCardSelected}
                        setListCardSelected={setListCardSelected}
                      />
                      <div style={{ marginTop: "13px" }} />
                    </>
                  );
                })
            ) : (
              <></>
            )}
            <div style={{ paddingBottom: 100 }} />
          </table>
        </div>
      )}
      <ModalContainer
        isActive={modalOptions}
        closeModal={() => setModalOpstions(!modalOptions)}
      >
        <ModalOptions
          setModalFormAdmin={() => setModalFormAdmin(!modalFormAdmin)}
          setModalFormClient={() => setModalFormClient(!modalFormClient)}
          isActive={modalOptions}
          edit={true}
          type="delete"
          card={cardSelected}
          closeModal={() => setModalOpstions(!modalOptions)}
          filterOption={filterOption}
        />
      </ModalContainer>

      <ModalContainer
        isActive={modalFormAdmin}
        closeModal={() => setModalFormAdmin(!modalFormAdmin)}
      >
        <ModalFormAdmin
          cardSelected={cardSelected}
          closeModal={() => setModalFormAdmin(!modalFormAdmin)}
          optionModalFormAdmin={optionModalFormAdmin}
        />
      </ModalContainer>

      <ModalContainer
        isActive={modalFormClient}
        closeModal={() => setModalFormClient(!modalFormClient)}
      >
        <ModalFormClient
          cardSelected={cardSelected}
          closeModal={() => setModalFormClient(!modalFormClient)}
          optionModalFormClient={optionModalFormClient}
        />
      </ModalContainer>
    </>
  );
};

export default TableList;
