import React, { useState } from 'react';
import deleteIcon from '../../../images/svg/deleteIcon.svg';

const TaxCard = ({item, deleteItem, index, onChange}) => {

  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 20, widht: "100%"}}>
      <label
        style={{display: "flex", flexDirection: "column", justifyContent: "space-between", maxWidth: "45%"}}
      >
        <p>Descrição do imposto</p>
        <input
          type="text"
          name="name"
          value={item.name}
          onChange={(e) => onChange(e, item.id, index)}
          style={{height: 45, border: "1px solid #d7d7d7", borderRadius: 7, padding: 10}}
        />
      </label>
      <label
        style={{display: "flex", flexDirection: "column", maxWidth: "45%"}}
      >
        <p>Valor do imposto</p>
        <input
          type="number"
          name="porcentage"
          value={item.porcentage}
          onChange={(e) => onChange(e, item.id, index)}
          style={{height: 45, border: "1px solid #d7d7d7", borderRadius: 7, padding: 10}}
        />
      </label>
      <button
        type="button"
        style={{cursor: "pointer", background: 'none', border: 'none'}}
        onClick={() => deleteItem(item.id, index)}
      >
        <img
          src={deleteIcon}
          alt={`deletar ${item.name}`}
        />
      </button>
    </div>
  )
}

export default TaxCard