import React, { useEffect, useState } from "react";
import Textarea from "./Textarea";
import closeEyeIcon from "../../../images/svg/closeEyeIcon.svg";
import openEyeIcon from "../../../images/svg/openEyeIcon.svg";
import moment from "moment";

const arrowSvg = `
  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.274428 1.61166C0.109402 1.37389 0.109402 1.05863 0.274428 0.820855C0.550264 0.423421 1.13801 0.423421 1.41385 0.820855L2.57301 2.49102C3.41008 3.69709 5.19369 3.69709 6.03076 2.49102L7.18993 0.820855C7.46576 0.423421 8.05351 0.423421 8.32934 0.820855C8.49437 1.05863 8.49437 1.37389 8.32934 1.61166L7.20524 3.23131C5.79952 5.25671 2.80425 5.25671 1.39854 3.23131L0.274428 1.61166Z" fill="black"/>
  </svg>
`;

const arrowSvgUri = encodeURIComponent(arrowSvg);

const BoxParameterSelect = ({
  number,
  type,
  width,
  onChange,
  value,
  valueInput,
  onBlur,
  height,
  options,
  listValues,
  update,
  percentage,
  coin,
  gap,
}) => {
  const [valueInputState, setValueInputState] = useState("");
  const [optionSelect, setOptionSelect] = useState("");
  const [typeStructureAll, setTypeStructureAll] = useState([]);

  const [typeCategoryAll, setTypeCategoryAll] = useState([]);
  const [typeNumber, setTypeNumber] = useState(true);
  const [passwordType, setPasswordType] = useState(false);

  /* STYLES */
  const boxCard = {
    display: "flex",
    gap: "18px",
    alignItems: "end",
  };

  const numberBox = {
    backgroundColor: "#96C",
    color: "#fff",
    fontSize: "19px",
    padding: "6px 16px",
    borderRadius: "60px",
    marginBottom: "5px",
  };

  const selectBox = {
    outline: "none",
    width: width || "100%",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    padding: "12px 13px",
    appearance: "none",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 10px center",
    paddingRight: "30px",
    color: "#67727E",
  };

  const inputBox = {
    outline: "none",
    width: width || "100%",
    border: "1px solid #d7d7d7",
    borderRadius: "10px",
    color: "#67727E",
    padding: "12px 13px",
    color: "#67727E",
  };

  const containerInputPassowrd = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: width != undefined ? width : "418px",
  };

  const image = {
    position: "absolute",
    right: 0,
    top: "15px",
    right: "10px",
    cursor: "pointer",
  };

  const verifyNameType = () => {
    if (type === "process") {
      return "Processo";
    } else if (type === "description") {
      return "Descrição da atividade";
    } else if (type === "unit") {
      return "Unidade de medida";
    } else if (type === "price") {
      return `Preço ${value || ""}`;
    } else if (type === "titleGenerality") {
      return "Tipo de generalidade";
    } else if (type === "dateGenerality") {
      return "Data";
    } else if (type === "beginningBilling") {
      return "Inicio de faturamento";
    } else if (type === "qtdGenerality") {
      return "Quantidade";
    } else if (type === "nameClient") {
      return "Nome do cliente";
    } else if (type === "nameUser") {
      return "Nome do usuário";
    } else if (type === "cnpj") {
      return "CNPJ";
    } else if (type === "email") {
      return "E-mail de acesso";
    } else if (type === "password") {
      return "Senha de acesso";
    } else if (type === "typeStructure") {
      return "Tipo de estrutura";
    } else if (type === "typeCategory") {
      return "Tipo de categoria";
    } else if (type === "justification") {
      return "Justificativa";
    } else if (type === "typeCalculation") {
      return "Tipo de calculo";
    } else if (type === "conversion") {
      return "Conversão (Em desenvolvimento)";
    } else if (type === "coin") {
      return "Moeda";
    } else if (type === "permission") {
      return "Permissão";
    } else if (type === "billingType") {
      return "Tipo de faturamento";
    } else if (type === "monthlyBilling") {
      return "Faturamento mínimo mensal";
    } else if (type === "dayBilling") {
      return "Dia de faturamento";
    } else if (type === "branch") {
      return "Filial"
    }
  };

  const formatCoin = (typeCoin, valueCoin, setValueCoin) => {
    if (percentage) {
      const number = Number(valueCoin);
      setValueCoin(`${number}%`);
    } else if (coin === "real" || typeCoin === "real") {
      const number = Number(valueCoin);
      setValueCoin(
        number.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      );
    }
  };

  useEffect(() => {
    setTypeCategoryAll(listValues);
    setTypeStructureAll(listValues);
  }, [listValues]);

  // useEffect(() => {
  //   getStructure();
  // }, []);

  // useEffect(() => {
  //   getStructure();
  // }, []);

  // useEffect(() => {
  //   getCategory();
  // }, [update]);

  useEffect(() => {
    verifyNameType(value);
  }, [value]);

  useEffect(() => {
    setOptionSelect(options);
  }, [options]);

  useEffect(() => {
    setValueInputState(valueInput);
  }, [valueInput]);

  return (
    <div style={boxCard}>
      {number && <div style={numberBox}>{number}</div>}
      {gap && <div style={{ marginLeft: "39px ", backgroundColor: "" }}></div>}
      <div style={{ display: "flex", gap: "13px", flexDirection: "column" }}>
        {<p className="textTitleParms">{verifyNameType()}</p>}

        {type !== "description" &&
          type !== "price" &&
          type !== "price" &&
          type !== "dateGenerality" &&
          type !== "qtdGenerality" &&
          type !== "nameClient" &&
          type !== "nameUser" &&
          type !== "cnpj" &&
          type !== "email" &&
          type !== "password" &&
          type !== "conversion" &&
          type !== "monthlyBilling" &&
          type !== "beginningBilling" &&
          valueInputState && (
            <select
              defaultValue={valueInput}
              onBlur={onBlur}
              onChange={onChange}
              style={{
                ...selectBox,
                backgroundImage: `url('data:image/svg+xml;charset=utf-8,${arrowSvgUri}')`,
              }}
            >
              {type === "process" && (
                <>
                  <option value="" style={{ color: "#67727E" }}>
                    Selecione
                  </option>
                  <option value="Recebimento">Recebimento</option>
                  <option value="Expedição">Expedição</option>
                  <option value="Armazenagem">Armazenagem</option>
                  <option value="Inventário">Inventário</option>
                  <option value="Seguro de Armazenagem">
                    Seguro de Armazenagem
                  </option>
                </>
              )}
              {type === "unit" && optionSelect === "Armazenagem" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="volume_m3 - sum: Volume m³">Volume m³</option>
                  <option value="peso_bruto - sum: Peso bruto">
                    Peso bruto
                  </option>
                  <option value="peso_liquido - sum: Peso liquido">
                    Peso liquido
                  </option>
                  <option value="qtd_palete_fisico - sum: Palete fisico">
                    Palete fisico
                  </option>
                  <option value="qtd_enderecos - sum linha: Endereços">
                    Endereços
                  </option>
                  <option value="qtd_saldo - sum: Saldo">Saldo</option>
                </>
              )}
              {type === "unit" && optionSelect === "Recebimento" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="qtd_item - sum: Quantidade de item">
                    Quantidade de item
                  </option>
                  <option value="qtd_palete_fisico - sum: Pallet">
                    Pallet
                  </option>
                  <option value="seq_aviso_recebto - sum: Linhas">
                    Linhas
                  </option>
                  <option value="peso_bruto - sum: Peso bruto">
                    Peso bruto
                  </option>
                  <option value="peso_liquido - sum: Peso liquido">
                    Peso liquido
                  </option>
                  <option value="volume_m3 - sum: Volume m³">Volume m³</option>
                </>
              )}
              {type === "unit" && optionSelect === "Expedição" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="qtd_itens - sum: Quantidade de item">
                    Quantidade de item
                  </option>
                  <option value="trans_solicitacao - sum linhas: Notas Fiscais Expedidas">
                    Notas Fiscais Expedidas
                  </option>
                  <option value="qtd_palete_fisico - sum: Pallet">
                    Pallet
                  </option>
                  <option value="qtd_sequencias - sum: Linhas">Linhas</option>
                  <option value="peso_bruto - sum: Peso bruto">
                    Peso bruto
                  </option>
                  <option value="peso_liquido - sum: Peso liquido">
                    Peso liquido
                  </option>
                  <option value="volume_m3 - sum: Volume m³">Volume m³</option>
                </>
              )}
              {type === "unit" && optionSelect === "Seguro de Armazenagem" && (
                <>
                  <option value={valueInput}>{valueInput.split(":")[1]}</option>
                  <option value="">Selecione</option>
                  <option value="qtd_itens - sum: Quantidade de item">
                    Quantidade de item
                  </option>
                </>
              )}
              {type === "titleGenerality" && (
                <>
                  <option value="">Selecione</option>
                  <option value="Recebimento">
                    Hora extra/homem - Dias úteis
                  </option>
                </>
              )}

              {type === "coin" && (
                <>
                  <option value="">Selecione</option>
                  <option value="Real BRL">Real BRL</option>
                  <option value="Euro EUR">Euro EUR</option>
                  <option value="Dolar US">Dolar USD</option>
                </>
              )}

              {type === "billingType" && (
                <>
                  <option value=",">Selecione</option>
                  <option value="atividade">Faturamento por atividade</option>
                  <option value="porcentual">Faturamento por porcentual</option>
                  <option value="atividade sem imposto">
                    Faturamento por atividade S/Imposto
                  </option>
                  <option value="mao de obra">
                    Faturamento por mão de obra
                  </option>
                </>
              )}

              {
                type === "branch" && (
                  <>
                    <option value=".">
                     Selecione
                    </option>
                    <option value="Unidade Matriz Joinville - SC">
                      Unidade Matriz Joinville - SC
                    </option>
                    <option value="Unidade IN HOUSE Joinville - SC">
                      Unidade IN HOUSE Joinville - SC
                    </option>
                    <option value="Unidade Filial Barueri - SP">
                      Unidade Filial Barueri - SP
                    </option>
                  </>
                )
              }

              {
                type === "dayBilling" && (
                  <>
                    {
                      Array.from({ length: 30 }, (_, index) => (
                        <option value={index + 1} key={`day-${index}`}>{index + 1}</option>
                      ))
                    }
                  </>
                )
              }

              {type === "permission" && (
                <>
                  <option value="">Selecione</option>
                  <option value="Administrativo">Administrativo</option>
                  <option value="Coordenador">Coordenador</option>
                  <option value="Financeiro">Financeiro</option>
                  <option value="Qualidade">Qualidade</option>
                  <option value="Operacional">Operacional</option>
                  <option value="BPO">BPO</option>
                </>
              )}
              {type === "typeCalculation" && (
                <>
                  <option value={valueInput}>{valueInput}</option>
                  <option value="">Selecione</option>
                  <option value="Soma">Soma</option>
                  {options !== "Recebimento" && (
                    <option value="Pico">Pico</option>
                  )}
                </>
              )}
              {type === "typeStructure" &&
                typeStructureAll.map((item, i) => {
                  if (
                    i == 0 &&
                    options !== "Recebimento" &&
                    i == 0 &&
                    options !== "Expedição"
                  ) {
                    return (
                      <>
                        <option value={valueInput}>{valueInput}</option>
                        <option value="default">Selecione</option>;
                        <option value={item.des_tip_estrutura}>
                          {item.des_tip_estrutura}
                        </option>
                        ;
                      </>
                    );
                  } else if (
                    options !== "Recebimento" &&
                    options !== "Expedição"
                  ) {
                    return (
                      <>
                        <option value={item.des_tip_estrutura}>
                          {item.des_tip_estrutura}
                        </option>
                      </>
                    );
                  } else if (
                    (i == 0 && options === "Recebimento") ||
                    (i == 0 && options === "Expedição")
                  ) {
                    return <option value="default">Não se aplica</option>;
                  }
                })}
              {type === "typeCategory" && typeCategoryAll?.length ? (
                typeCategoryAll.map((item, i) => {
                  if (i == 0) {
                    return (
                      <>
                        <option value={valueInput}>{valueInput}</option>
                        <option value="default">Selecione</option>;
                        <option value={item.des_categoria}>
                          {item.des_categoria}
                        </option>
                        ;
                      </>
                    );
                  }
                  return (
                    <option value={item.des_categoria}>
                      {item.des_categoria}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </select>
          )}
        {type === "description" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Descrição"
            style={inputBox}
          />
        )}
        {type === "conversion" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira a conversão"
            style={inputBox}
          />
        )}
        {type === "price" && (
          <input
            onBlur={(e) => {
              setTypeNumber(false);
              onBlur(e);
              formatCoin("real", valueInput, setValueInputState);
            }}
            onChange={onChange}
            onClick={() => {
              setTypeNumber(true);
              setValueInputState(valueInput);
            }}
            value={valueInputState}
            placeholder="Preço"
            type={typeNumber ? "number" : "string"}
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "dateGenerality" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Preço"
            type="date"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "beginningBilling" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Preço"
            type="date"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "qtdGenerality" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Quantidade"
            type="number"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}

        {type === "nameClient" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira o nome"
            type="text"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "nameUser" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira o nome"
            type="text"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "cnpj" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Insira o CNPJ"
            type="number"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
        {type === "email" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="E-mail de acesso"
            type="email"
            style={{ ...inputBox, appearance: "textarea" }}
            autoComplete="off"
            key="email"
          />
        )}
        {type === "password" && (
          <div style={containerInputPassowrd}>
            <input
              onBlur={onBlur}
              className="textPrimaryInput"
              onChange={onChange}
              value={valueInputState}
              placeholder={"Insira a senha"}
              type={passwordType == true ? "text" : type}
              style={{ ...inputBox, appearance: "textarea" }}
              autoComplete="off"
              key="password"
            />

            {passwordType == false ? (
              <img
                src={closeEyeIcon}
                onClick={() => setPasswordType(!passwordType)}
                style={image}
              />
            ) : (
              <img
                src={openEyeIcon}
                onClick={() => setPasswordType(!passwordType)}
                style={image}
              />
            )}
          </div>
        )}

        {type === "justification" && (
          <Textarea
            width={width}
            height={height}
            value={valueInputState}
            placeholder="Descrição da justificativa"
            type="number"
            style={{ inputBox }}
          />
        )}

        {type === "monthlyBilling" && (
          <input
            onBlur={onBlur}
            onChange={onChange}
            value={valueInputState}
            placeholder="Faturamento"
            type="number"
            style={{ ...inputBox, appearance: "textarea" }}
          />
        )}
      </div>
    </div>
  );
};

export default BoxParameterSelect;
