import React, { useContext, useState } from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../../../components/Sidebar";
import HeaderDashboard from "../components/HeaderDashboard";
import CardParameters from "../components/CardParameters";
import CardDetailsInvoicing from "../components/CardDetailsInvoicing";
import { useNavigate } from "react-router-dom";
import ClientsSelect from "../components/ClientsSelect";
import SelectSessionButtons from "../components/SelectSessionButtons";
import ContainerContractualParameter from "../components/ContainerContractualParameter";
import ContentDashboard from "../components/ContentDashboard";
import { GraphsAndParametersContext } from "../../../context/Dashboard/GraphsAndParameters";
import ContactedVSAccomplished from "../components/ContactedVSAccomplished";
import configIcon from "../../../images/png/config.png";
import ModalContainer from "../components/ModalContainer";
import ModalLimityView from "../components/ModalLimityView";

const InitialDashboard = () => {
  const { update, setUpdate } = useContext(GraphsAndParametersContext);

  const navigate = useNavigate();
  const [btnSelect, setBtnSelect] = useState("KPIS");
  const [isModal, setIsModal] = useState(false);

  const container = {
    backgroundColor: "#F9F9F9",
    minHeight: "100vh",
    paddingBottom: "50px",
  };

  const boxCardConfig = {
    width: "35px",
    height: "35px",
    cursor: "pointer",
    backgroundColor: "#fff",
    padding: "7px",
    border: "2px solid #d7d7d7",
    borderRadius: "8px",
  };

  const buttons = [
    "KPIS",
    "Entrada",
    "Saída",
    "Urgente",
    "Contratada vs Realizada",
  ];

  return (
    <ThowColumLayout
      page={"Company"}
      colum1={<Sidebar page="Initial" />}
      colum2={
        <div style={container}>
          <HeaderDashboard name="Dashboard" adm={true} />

          <div style={{ margin: "0 auto" }} className="containerBox">
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "20px" }}
              >
                <ClientsSelect
                  update={() => setUpdate(!update)}
                  borderRadius={"8px"}
                />
                <img
                  src={configIcon}
                  style={boxCardConfig}
                  onClick={() => setIsModal(true)}
                />
              </div>
            </div>
            <SelectSessionButtons
              setBtnSelect={setBtnSelect}
              btnSelect={btnSelect}
              items={buttons}
              marginTop={24}
            />
            <ContentDashboard btnState={btnSelect} />
            {btnSelect === "Contratada vs Realizada" ? (
              <ContactedVSAccomplished type={btnSelect} />
            ) : (
              <></>
            )}

            <ModalContainer
              isActive={isModal}
              closeModal={() => setIsModal(false)}
            >
              <ModalLimityView closeModal={() => setIsModal(false)} />
            </ModalContainer>
          </div>
        </div>
      }
    ></ThowColumLayout>
  );
};

export default InitialDashboard;
