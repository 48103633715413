/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import waitingConferenceIcon from "../../../images/svg/waitingConferenceIcon.svg";
import api from "../../../services/api";
import { DashContext } from "../../../context/Dashboard/DashContext";

const CardCompanyDetails = ({ permission, value, clientsSelect, setClientsSelect }) => {
  const { updateFunction } = useContext(DashContext);
  const [clientsAll, setClientsAll] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState("");
  const [status, setStatus] = useState('');

  const CLIENT_BRANCHS = [
    "Unidade Matriz Joinville - SC",
    "Unidade IN HOUSE Joinville - SC",
    "Unidade Filial Barueri - SP",
  ];

  const getClients = async () => {
    try {
      const response = await api.get("/client/account");
      // console.log('response', response.data[0]);
      setClientsAll(response.data);

      const storedCNPJ = localStorage.getItem("@XPM_CLIENT_COMPANY:")

      if (storedCNPJ && response.data.some((e) => e.cnpj === storedCNPJ)) {
        console.log(response.data.find((e) => e.cnpj === storedCNPJ))
        const filter = response.data.filter(
          (item) => item.cnpj === localStorage.getItem("@XPM_CLIENT_COMPANY:")
        );
        setClientsSelect(filter[0]);
      } else {
        setClientsSelect(response.data[0]);

        localStorage.setItem("@XPM_CLIENT_COMPANY:", response.data[0].cnpj);
      }
    } catch (error) {
      console.log('error', error)
    }
  };

  const getClientBillingMonth = async () => {
    try {
      const year = localStorage.getItem('@XPM_CLIENT_YEAR:');
      const month = localStorage.getItem('@XPM_CLIENT_MONTH:');
      const date = `${year}-${month}`;
      const cnpj = localStorage.getItem('@XPM_CLIENT_COMPANY:');
      const response = await api.get(`/billing/client/${cnpj}/${date}`);
      if(response.data.payment_made) {
        setStatus("Fatura paga");
      } else if(response.data.checked_by_client) {
        setStatus("Fatura aprovada");
      } else if(response.data.checked_by_admin) {
        setStatus("Fatura conferida");
      } else if(!response.data.checked_by_admin) {
        setStatus("Aguardando conferência");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClients();
    getClientBillingMonth();
  }, []);

  const userInfo = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px",
    width: "280px",
    background: "white",
    border: "1px solid #d8d8d8",
    borderRadius: "60px",
    padding: "6px",
    paddingLeft: "20px",
    height: "40px",
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        border: "1px solid #d7d7d7",
        width: "319px",
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "17px",
        }}
      >
        <div class="dropdown">
          <button
            style={userInfo}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div style={{ display: "flex", gap: "20px" }}>
              <p className="textClientInfoHeader">
                {
                  filteredBranch ? filteredBranch : "Todas as unidades"
                }
                </p>
            </div>

            <div style={{ transform: "translateX(-10px)" }}>
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.182668 1.78582C0.0688405 1.57763 0.0688405 1.32582 0.182668 1.11763C0.446809 0.634515 1.14065 0.634515 1.40479 1.11763L2.41937 2.97328C3.12207 4.25852 4.96793 4.25852 5.67064 2.97327L6.68521 1.11763C6.94935 0.634515 7.64319 0.634515 7.90733 1.11763C8.02116 1.32582 8.02116 1.57763 7.90733 1.78582L6.67725 4.03563C5.53942 6.11672 2.55058 6.11672 1.41275 4.03563L0.182668 1.78582Z"
                  fill="black"
                />
              </svg>
            </div>
          </button>

          <div
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            style={{
              width: "280px",
              height: "250px",
              overflow: "hidden",
              overflowY: "auto",
            }}
          >
            <div
              key={"Todas as unidades"}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <a
                className="dropdown-item textSelectOptions"
                onClick={() => {
                  setFilteredBranch("");
                }}
              >
                Todas as unidades
              </a>
            </div>
            {
              CLIENT_BRANCHS.map((item) => {
                return (
                  <div
                    key={item}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <a
                      className="dropdown-item textSelectOptions"
                      onClick={() => {
                        setFilteredBranch(item);
                      }}
                    >
                      {item?.trim("")}
                    </a>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #d7d7d7",
          paddingBottom: "17px",
        }}
      >
        <div class="dropdown">
          <button
            style={userInfo}
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div style={{ display: "flex", gap: "20px" }}>
              <p className="textClientInfoHeader">{clientsSelect.client}</p>
              {/* <img
                style={{
                  width: "15px",
                  height: "15px",
                  objectFit: "contain",
                }}
                src={waitingConferenceIcon}
              /> */}
            </div>

            <div style={{ transform: "translateX(-10px)" }}>
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.182668 1.78582C0.0688405 1.57763 0.0688405 1.32582 0.182668 1.11763C0.446809 0.634515 1.14065 0.634515 1.40479 1.11763L2.41937 2.97328C3.12207 4.25852 4.96793 4.25852 5.67064 2.97327L6.68521 1.11763C6.94935 0.634515 7.64319 0.634515 7.90733 1.11763C8.02116 1.32582 8.02116 1.57763 7.90733 1.78582L6.67725 4.03563C5.53942 6.11672 2.55058 6.11672 1.41275 4.03563L0.182668 1.78582Z"
                  fill="black"
                />
              </svg>
            </div>
          </button>

          <div
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            style={{
              width: "280px",
              height: "250px",
              overflow: "hidden",
              overflowY: "auto",
            }}
          >
            {clientsAll.length > 0 &&
              clientsAll.filter((e) => {
                return filteredBranch ? (e.branch === filteredBranch) : (e.branch !== null)
              }).map((item) => {
                return (
                  <div
                    key={item.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <a
                      className="dropdown-item textSelectOptions"
                      onClick={() => {
                        localStorage.setItem("@XPM_CLIENT_COMPANY:", item.cnpj);
                        setClientsSelect(item);
                        updateFunction();
                      }}
                    >
                      {item?.client?.trim("")}
                    </a>
                    <img
                      style={{
                        width: "15px",
                        height: "15px",
                        objectFit: "contain",
                      }}
                      src={waitingConferenceIcon}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: "17px",
          display: "flex",
          flexDirection: "column",
          gap: "17px",
        }}
      >
        {
          permission !== 'Operacional' && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="textTitleValue">Valor a pagar</p>
              <p className="textContentValue">{value}</p>
            </div>
          )
        }

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="textTitleValue">Status</p>
          <p className="textStatusValue">{status}</p>
        </div>
      </div>
    </div>
  );
};

export default CardCompanyDetails;
