import moment from "moment";
import "moment/locale/pt-br";

const StorageLineDetailsCardTable = ({
  date,
  qtd,
  rate,
  price,
  border,
  typeCount,
  factor,
}) => {
  /* STYLES */
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: border ? "1px solid #d7d7d7" : "none",
    paddingBottom: border ? "13px" : "0",
  };

  const columnStyle = {
    textAlign: "center",
  };
  
  /* RENDER */
  return (
    <div style={containerStyle}>
      <div style={columnStyle}>
        <p className="titleNameCompanyCard">
          {moment.utc(date).format("DD/MM/YYYY")}
        </p>
        <p className="textTitleValueCard">Data</p>
      </div>

      <div style={columnStyle}>
        <p className="titleNameCompanyCard">{qtd.toFixed(2).replace('.', ',')}</p>
        <p className="textTitleValueCard">{typeCount}</p>
      </div>

      <div style={columnStyle}>
        <p className="titleNameCompanyCard">{Number(factor).toFixed(4)}</p>
        <p className="textTitleValueCard">Fator</p>
      </div>

      <div style={columnStyle}>
        <p className="titleNameCompanyCard">{rate}</p>
        <p className="textTitleValueCard">Valor</p>
      </div>

      <div style={columnStyle}>
        <p className="titleNameCompanyCard">
          {price.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </p>
        <p className="textTitleValueCard">Valot total</p>
      </div>
    </div>
  );
};

export default StorageLineDetailsCardTable;
